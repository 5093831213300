import React from 'react'

const Magic360Spinner = ({ altText, magic360 }) => {
    return (
        <a
            id={`magic360_${magic360?.position}`}
            className="Magic360"
            onClick={(e) => e.preventDefault()}
            onDrag={(e) => e.preventDefault()}
            href={magic360?.firstfileurl}
            data-options={`columns:${magic360?.columns}; filename:${
                magic360?.filename || ''
            }; large-filename:${
                magic360
                    ? magic360['large-filename']
                        ? magic360['large-filename']
                        : magic360?.filename
                    : ''
            }; hint:false; autospin-start:load;`}
        >
            <img loading="lazy" src={magic360?.firstfileurl} alt={altText} />
        </a>
    )
}

export default Magic360Spinner
