/**
 * Class to get the page constants that will be used through all the server
 * project.
 */
class PageEnum {
    constructor() {
        this.CATALOG = 'pcp'
        this.COMPARE = 'compare'
        this.SERP = 'search'
        this.WEBCONTENT = 'webcontent'
        this.REGULATORY = 'regulatory'
        this.RESOURCES = 'media-library'
        this.PRODUCT_DETAILS = 'd'
        this.PRODUCT_DETAILS_CHILDREN = 'dc'
        this.PDP_API = 'pdp'
        this.TRANSLATIONS = 'translation'
        this.FIND_PRODUCT_PART = 'findProductPart'
        this.WTB_MINI_SNAPS = 'wtbMiniSnaps'
        this.PRODUCT_GROUP = 'product_group'
        Object.freeze(this)
    }

    getPageEnumValue(value) {
        switch (value) {
            case this.SERP:
                return this.SERP
            case 'web-content':
                return this.WEBCONTENT
            case this.REGULATORY:
                return this.REGULATORY
            case 'resources':
                return this.RESOURCES
            default:
                console.log('Default value used')
                return this.CATALOG
        }
    }

    /**
     * Method to get a symbolic value acoording to the
     * page type.
     * @param {string} value the encoded value of the
     * page type.
     * @returns returns a symbolic string of the page
     * type.
     */
    getPageEnumName(value) {
        switch (value) {
            case this.PRODUCT_GROUP:
                return 'Product group'
            case this.CATALOG:
                return 'PCP'
            case this.PDP_API:
                return 'PDP-API'
            case this.PRODUCT_DETAILS:
            case this.PRODUCT_DETAILS_CHILDREN:
                return 'PDP'
            case this.COMPARE:
                return 'Compare page'
            case this.SERP:
                return 'SERP-Search'
            case this.WEBCONTENT:
                return 'SERP-Web content'
            case this.REGULATORY:
                return 'SERP-Regulatory'
            case this.RESOURCES:
                return 'SERP-Resources'
            case this.TRANSLATIONS:
                return 'Translations'
            case this.FIND_PRODUCT_PART:
                return 'Find a Part'
            default:
                return 'Unkown service!!'
        }
    }

    /**
     * Method to get the corresponding name of the page type for the searchAPI,
     * regarding the type name inserted.
     * @param {string} type the type of page from the page request helper.
     * @returns returns the name of the page that can understand the searchAPI.
     */
    getTranslationsPageType(type) {
        switch (type) {
            case this.PRODUCT_GROUP:
            case this.CATALOG:
                return 'pcp'
            case this.SERP:
                return 'search'
            case this.PRODUCT_DETAILS:
            case this.PRODUCT_DETAILS_CHILDREN:
                return 'pdp'
            default:
                return 'error'
        }
    }
}

export default new PageEnum()
