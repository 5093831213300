import React, { useEffect } from 'react'

const MagicZoom = ({
    children,
    magicZoomId = '',
    largeImageUrl,
    smallImageUrl,
    zoomOnClick,
    className
}) => {
    useEffect(() => {
        window?.MagicZoom?.update(magicZoomId, largeImageUrl, smallImageUrl)
    }, [largeImageUrl])

    return largeImageUrl && smallImageUrl ? (
        <a
            role="button"
            id={magicZoomId}
            href={largeImageUrl}
            onClick={(e) => e.preventDefault()}
            className={`MagicZoom ${className || ''}`}
            data-options={`autostart: false; zoomPosition: inner; expand: off; hint: off; ${
                zoomOnClick ? 'zoomOn: click' : ''
            }`}
        >
            {children}
        </a>
    ) : (
        { children }
    )
}

export default MagicZoom
