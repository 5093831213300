/**
 * getAltId is a function that searches for an alternative ID in a prioritized order
 * @param {string} productNumber Products productNumber if it exists
 * @param {string} partNumber Products partNumber if it exists
 * @param {string} national_stock_number Products national_stock_number if it exists
 * @param {string} catalog_number Products catalog_number if it exists
 * @param {string} repId Products repId if it exists
 * @returns the first existing value or undefined if no values exist
 */
 const getAltID = ({
    productNumber,
    partNumber,
    national_stock_number,
    catalog_number,
    repId,
}) => {
    return (
        productNumber ??
        partNumber ??
        national_stock_number ??
        catalog_number ??
        repId
    )
}

export { getAltID }