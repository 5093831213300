import React from 'react'
import { useIsDesktop } from '../services/UtilityService.jsx'

const DesktopOverlay = ({ loading }) => {
    const isDesktop = useIsDesktop()
    if (!(loading && isDesktop)) return null

    return (
        <div
            className="sps2-gallery--overlay"
            aria-label="Loading"
            role="status"
            aria-live="polite"
            aria-busy="true"
        />
    )
}

export default DesktopOverlay
