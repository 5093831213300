import React, { useEffect, useState, useContext } from 'react'
import { checkLegal } from '../services/BrowserService'
import { Translations } from '../services/TranslationService'
import '../css/LegalDisclaimer.scss'

const LegalDisclaimer = () => {
    const translations = useContext(Translations)
    const [legal, setLegal] = useState('')
    useEffect(() => {
        setLegal(checkLegal())
    }, [])

    const disclaimer = translations[legal] // Getting the right legal diclaimer (if present)

    if (!disclaimer) return null

    return (
        <div
            className="sps2-pcp_legal"
            dangerouslySetInnerHTML={{ __html: disclaimer }}
        />
    )
}

export default LegalDisclaimer
