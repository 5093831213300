import React from 'react'
import { translations as defaultTranslations } from '../constants/constants'

/* Translations Context for SNAPS2 */
const Translations = React.createContext({})
Translations.displayName = 'Translations Context'

/**
 * TranslationsProvider is the provider and context for a SNAPS2 application
 * @param {array} translations
 * @param {array} children
 * @returns {Context} React context that returns the translations needed for a SNAPS2 application
 */
const TranslationsProvider = ({ translations = {}, children }) => {
    return (
        <Translations.Provider value={{ ...defaultTranslations, ...translations }}>
            {children}
        </Translations.Provider>
    )
}

export { Translations, TranslationsProvider }
