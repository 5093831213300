import React, { useEffect, useState } from 'react'
import BreadBoxList from '../BreadBoxList.jsx'
import Gallery from '../Gallery.jsx'
import Navigation from '../Navigation/Navigation.jsx'
import ResourceBlock from '../../components/ResourceBlock.jsx'
import FilterBar from '../FilterBar.jsx'
import { FilterToggle, FooterCTAs } from '../Navigation/MobileNavComponents.jsx'
import { getLoadMoreURL, getClearBreadboxLink } from '../../services/URLService.jsx'
import { getRequest, postSignalData } from '../../services/AxiosService.jsx'
import { updateWindowState } from '../../services/BrowserService.jsx'
import { MEDIALIBRARY_SELECT } from '../../constants/SignalConstants.js'
import DesktopOverlay from '../../components/DesktopOverlay.jsx'

/**
 * getResourcesData function fetches the resources data for the resources tab
 * @param {object} endpoints The endpoints object containing the resourcesApi
 * @param {function} dispatch The dispatch function for the state reducer
 */
const getResourcesData = (endpoint, dispatch, callback) => {
    getRequest(endpoint)
        .then((result) => {
            dispatch({
                type: 'update_resources_data',
                payload: result.data
            })

            // using setTimeout allows the callback to be called after the dispatch (otherwise is called before dispatch is reflected)
            if (callback) {
                setTimeout(callback, 0)
            }

            // Update local window state with number of items loaded
            updateWindowState({
                itemsLoaded: result.data.mediaLinks.length
            })
        })
        .catch((error) => {
            dispatch({
                type: 'show_error_message',
                payload: `Error on loading resources: ${error}`
            })
        })
}

/**
 * Displays the resources view when the tab is selected
 * @param {string} aggregations content to be displayed on the left hand navigation
 * @param {string} breadBox content to be displayed
 * @param {string} canonicalUrl content to be displayed
 * @param {string} canonicalUrl  content to be displayed
 * @param {object} dispatch content to be displayed
 * @param {string} endpoints content to be displayed
 * @param {string} items content to be displayed
 * @param {string} total content to be displayed
 * @returns {object} this will be the react component already set up.
 */
const ResourcesView = ({
    aggregations,
    breadBox,
    canonicalUrl,
    dispatch,
    endpoints,
    items,
    loading,
    total,
    allEndpoints,
    currentTab,
    queryId
}) => {
    // Create mobile left hand navigation state
    const [showMobileLHN, setShowMobileLHN] = useState(false)
    const [resourcesBreadCrumb, setResourcesBreadCrumb] = useState([])
    const clearLink = getClearBreadboxLink(canonicalUrl)
    const { resourcesApi, analyticsUrl } = endpoints

    // Create resourcesBreadCrumb from breadBox array
    useEffect(() => {
        const resourcesBreadboxes = breadBox?.filter(
            (breadBox) => breadBox.type === 'resourcesCategories'
        )

        setResourcesBreadCrumb([
            {
                value: 'Search', // Note - this initial value will be the translated '< All' link
                removeUrl: resourcesBreadboxes[0]?.removeUrl
            },
            ...resourcesBreadboxes.map((breadbox, index) => {
                if (index + 1 === resourcesBreadboxes.length) {
                    return { value: breadbox.value[breadbox.value.length - 1] }
                } else {
                    return {
                        value: breadbox.value[breadbox.value.length - 1],
                        removeUrl: resourcesBreadboxes[index + 1].removeUrl
                    }
                }
            })
        ])
    }, [breadBox])

    // Function to load more resources using axios
    const loadMore = () => {
        const endpoint = getLoadMoreURL(resourcesApi, items.length)
        getRequest(endpoint)
            .then((result) => {
                // Set attribute to focus on the first returned result
                if (result.data.mediaLinks?.length > 0) {
                    result.data.mediaLinks[0].focusOn = true
                }

                // Update the state with the new items
                dispatch({
                    type: 'load_more_resources',
                    payload: result.data.mediaLinks
                })

                // Update local window state with number of items loaded
                updateWindowState({
                    itemsLoaded: items?.length + result.data.mediaLinks.length
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'show_error_message',
                    payload: `Error on loading more resources: ${error}`
                })
            })
    }

    return (
        <>
            <FilterBar
                total={total}
                filterToggle={
                    aggregations && (
                        <FilterToggle
                            showMobileLHN={showMobileLHN}
                            setShowMobileLHN={setShowMobileLHN}
                        />
                    )
                }
                breadboxList={
                    <BreadBoxList
                        clearLink={clearLink}
                        breadboxList={breadBox}
                        dynamicLoad={true}
                    />
                }
                resultsTitle={true}
            />
            <div className="sps2-lhngallery">
                <DesktopOverlay loading={loading} />
                <Navigation
                    aggregations={aggregations}
                    breadBox={breadBox}
                    breadCrumb={resourcesBreadCrumb}
                    loading={loading}
                    showMobileLHN={showMobileLHN}
                    setShowMobileLHN={setShowMobileLHN}
                    dynamicLoad={true}
                    currentTab={currentTab}
                    footerCTAs={
                        <FooterCTAs
                            breadBox={breadBox}
                            clearLink={clearLink}
                            setShowMobileLHN={setShowMobileLHN}
                            dynamicLoad={true}
                            dispatch={dispatch}
                            endpoints={allEndpoints}
                            currentTab={currentTab}
                        />
                    }
                />
                <Gallery gallerySize={items?.length} loadMore={loadMore} total={total}>
                    {items.map((item) => {
                        return (
                            <ResourceBlock
                                key={item.position}
                                item={item}
                                {...item}
                                onClickTracker={() => {
                                    postSignalData(
                                        analyticsUrl,
                                        item.position,
                                        MEDIALIBRARY_SELECT,
                                        undefined,
                                        queryId
                                    )
                                }}
                            />
                        )
                    })}
                </Gallery>
            </div>
        </>
    )
}

export { ResourcesView as default, getResourcesData }
