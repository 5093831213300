import React, { useContext, useRef, useEffect } from 'react'
import { StateContext } from '../services/StateService'
import { getSpecificationsLabel } from '../services/PDPService'
import { Translations } from '../services/TranslationService'
import { trackDataOnLoad } from '../services/TealiumService'
import { addChildrenStructuredData } from '../services/PDPSEOService.jsx'
import BreadCrumbList from '../fragments/BreadCrumbList.jsx'
import Header from '../fragments/DetailPage/Header'
import StickyNavigation from '../fragments/DetailPage/StickyNavigation'
import BuyingZone from '../fragments/DetailPage/BuyingZone'
import Specifications from '../fragments/DetailPage/Specifications'
import Details from '../fragments/DetailPage/Details'
import Resources from '../fragments/DetailPage/Resources'
import Support from '../fragments/DetailPage/Support'
import DisclaimerInformation from '../fragments/DetailPage/DisclaimerInformation'
import Reviews from '../fragments/DetailPage/Reviews'
import FrequentlyBoughtTogether from '../fragments/DetailPage/FrequentlyBoughtTogether'
import MediaGalleryScripts from '../components/MediaGalleryScripts'
import RichContent from '../components/RichContent'
import RelatedProducts from '../fragments/DetailPage/RelatedProducts'
import PostPurchaseZone from '../fragments/DetailPage/PostPurchaseForm'
import RequiredProducts from '../fragments/DetailPage/RequiredProducts.jsx'
import '../css/PDP.scss'

// TODO Add testing
/* istanbul ignore next */
const PDP = () => {
    const {
        breadCrumb,
        productDetails,
        richContent,
        classificationAttributes,
        ctaInfo,
        locale,
        displayController,
        childrenSeo
    } = useContext(StateContext)
    const translations = useContext(Translations)

    const buyingZoneRef = useRef(null)
    const specificationsRef = useRef(null)
    const detailsRef = useRef(null)
    const resourcesRef = useRef(null)
    const reviewsRef = useRef(null)
    const relatedProductsRef = useRef(null)
    const supportRef = useRef(null)
    const supportSecondaryRef = useRef(null)
    const supportTertiaryRef = useRef(null)
    const disclaimerInformationRef = useRef(null)
    const boughtTogetherRef = useRef(null)
    const specificationsLabel = getSpecificationsLabel(
        locale,
        productDetails?.divisionCode,
        translations?.specifications,
        translations?.specificationsAlternate
    )

    useEffect(() => {
        trackDataOnLoad('PDP page onload', 'Engagement Event', { product_views: 1 })
        childrenSeo && addChildrenStructuredData(productDetails, childrenSeo)
    }, [])

    return (
        <section className="sps2-pdp">
            <MediaGalleryScripts />
            {breadCrumb?.length > 0 && <BreadCrumbList breadcrumbsList={breadCrumb} />}
            <Header reviewsRef={reviewsRef} />
            <StickyNavigation
                buyingZoneRef={buyingZoneRef}
                specificationsRef={specificationsRef}
                specificationsLabel={specificationsLabel}
                detailsRef={detailsRef}
                resourcesRef={resourcesRef}
                reviewsRef={reviewsRef}
                relatedProductsRef={relatedProductsRef}
                supportRef={supportRef}
                boughtTogetherRef={boughtTogetherRef}
            />
            <BuyingZone
                buyingZoneRef={buyingZoneRef}
                supportRef={supportRef}
                supportSecondaryRef={supportSecondaryRef}
                supportTertiaryRef={supportTertiaryRef}
                disclaimerInformationRef={disclaimerInformationRef}
                resourcesRef={resourcesRef}
                detailsRef={detailsRef}
                showDetails={displayController?.showDetails}
            />
            {displayController?.showRequiredProducts && <RequiredProducts />}
            {richContent?.flexZone && (
                <RichContent richContentObj={richContent.flexZone}></RichContent>
            )}
            {richContent?.flexZone2 && (
                <RichContent richContentObj={richContent.flexZone2}></RichContent>
            )}
            {richContent?.flexZone3 && (
                <RichContent richContentObj={richContent.flexZone3}></RichContent>
            )}
            {displayController?.showDetails && <Details detailsRef={detailsRef} />}
            {displayController?.classificationAttributes && (
                <Specifications
                    specificationsRef={specificationsRef}
                    classificationAttributes={classificationAttributes}
                    specificationsLabel={specificationsLabel}
                />
            )}
            {displayController?.showResources && (
                <Resources resourcesRef={resourcesRef} />
            )}
            {displayController?.showReviews && (
                <Reviews reviewsRef={reviewsRef} productDetails={productDetails} />
            )}
            {richContent?.postPurchaseForm && <PostPurchaseZone />}
            {displayController?.showSupport && (
                <Support
                    supportRef={supportRef}
                    supportSecondaryRef={supportSecondaryRef}
                    supportTertiaryRef={supportTertiaryRef}
                    richContent={richContent}
                    ctaInfo={ctaInfo}
                />
            )}
            {displayController?.relatedProducts && (
                <RelatedProducts relatedProductsRef={relatedProductsRef} />
            )}
            {displayController?.frequentlyBoughtTogether && (
                <FrequentlyBoughtTogether boughtTogetherRef={boughtTogetherRef} />
            )}
            <div>
                {/* there is an issue with the rich content, the HTML is broken which endup getting the next element className,
                this div will avoid that, hoepfully we could delete soon */}
            </div>
            {displayController?.showDisclaimerInfo && (
                <DisclaimerInformation
                    disclaimerInformationRef={disclaimerInformationRef}
                />
            )}
            {richContent?.contextualSupportWidget && (
                <RichContent
                    richContentObj={richContent.contextualSupportWidget}
                ></RichContent>
            )}
        </section>
    )
}

export default PDP
