import React, { useContext } from 'react'

import { StateContext } from '../services/StateService'

const HtmlErrorObject = () => {
    return (
        <div>
            <h1>Something went wrong</h1>
        </div>
    )
}

/**
 * Display an error message according the enrironment and the locale.
 * @returns A wired error message, to let the user know something is wrong.
 */
const Error = () => {
    const { errorMessage, dispatch } = useContext(StateContext)
    // let's check that we have a message.
    if (errorMessage) {
        // let's print-out the message
        console.error(
            `${errorMessage.timeStampError}-Error found: ${errorMessage.errorData}`
        )
        // once the message has been printed out, let's remove it.
        setTimeout(() => {
            dispatch({
                type: 'show_error_message',
                payload: undefined
            })
        }, 2 * 1000)
        // let's show a quick message.
        // TODO: add the html error message to react.
        return null
    }
    return null
}

export default Error
