/* selectionService.js contains functions used for dynamic loading of thin-schema and single-select filters */
import { joinUrlBaseAndParams, createDynamicRemoveURL } from './URLService.jsx'
import { getRequest } from './AxiosService.jsx'
import { updateURL } from './BrowserService.jsx'

/**
 * filterDispatchController
 * @param {string} endpoints the state's endpoints to determine which dispatch type
 * @param {object} data filtered data to call dispatch with
 * @param {funtion} dispatch the dispatch for the reducer to update state
 */
const filterDispatchController = (endpoints, data, dispatch) => {
    const api = Object.keys(endpoints).find(
        (key) => endpoints[key] === endpoints.filterApi
    )

    switch (api) {
        case 'resourcesApi':
            dispatch({ type: 'update_resources_data', payload: data })
            break
        case 'regulatoryApi':
            dispatch({ type: 'update_regulatory_data', payload: data })
            break
        default:
            dispatch({
                type: 'filter_items',
                payload: { ...data, loading: false }
            })
    }
}

/**
 * filterSelection
 * @param {string} api the api to call to filter the data
 * @param {string} url the URL to update the window state with once the data is returned successfully
 * @param {object} endpoints the state's endpoints to determine which dispatch type
 * @param {funtion} dispatch the dispatch for the reducer to update state
 */
const filterSelection = (api, url, endpoints, dispatch) => {
    dispatch({ type: 'set_loading_status', payload: true })
    getRequest(api)
        .then(({ data }) => {
            filterDispatchController(endpoints, data, dispatch)
            updateURL(url)
            dispatch({ type: 'set_loading_status', payload: false })
        })
        .catch((error) => {
            dispatch({
                type: 'show_error_message',
                payload: `Error on filter selection: ${error}`
            })
            dispatch({ type: 'set_loading_status', payload: false })
        })
}

/**
 * createFilterEndpoint
 * @param {string} api api associated with the filter
 * @param {object} endpoints endpoints of the state - verifies filterApi is defined
 * @returns an api combining the filterApi and the api parameters
 */
const createFilterEndpoint = (api, endpoints) => {
    if (endpoints.filterApi) {
        return joinUrlBaseAndParams(endpoints.filterApi, api)
    } else {
        return api
    }
}

const createHierarchyEndpoint = (api, endpoints) => {
    if (!endpoints.filterApi) return
    const newURL = createDynamicRemoveURL(api, endpoints.filterApi)

    return newURL
}

export { createFilterEndpoint, filterSelection, createHierarchyEndpoint }
