import React, { useState, useEffect } from 'react'
import { useIsDesktop } from '../../services/UtilityService.jsx'
import { v4 as uuidv4 } from 'uuid'
import '../../css/LHNAccordion.scss'

/* Taken and modified from the hook Accordion */

/**
 * [LHNAccordion] Header is the toggle of the accordion that will toggle the body when clicked
 * @param {string} uniqueID Unique ID for the accordion component for accessibility
 * @param {function} toggle Function to toggle open the content of the accordion
 * @param {string} title Title of the accordion
 * @param {bool} show True | false value for if the body of the accordion should be visible
 * @param {function} onClick Optional function that is called when header is clicked
 */
const Header = ({ uniqueID, toggle, title, show, onClick }) => {
    const isDesktop = useIsDesktop()

    return (
        <button
            className="sps2-lhn_name"
            onClick={() => {
                toggle(title)
                onClick()
            }}
            id={'lhn-accordion_header--' + uniqueID}
            aria-expanded={show}
            aria-controls={'lhn-accordion_section--' + uniqueID}
        >
            {title && (
                <h3
                    className={
                        isDesktop
                            ? 'mds-font_header--6 sps2-link_header--secondary'
                            : 'mds-font_header--6'
                    }
                >
                    {title}
                </h3>
            )}
            <i
                aria-hidden={true}
                className={`MMM--icn ${show ? 'MMM--icn_gt2' : 'MMM--icn_lt2'}`}
            ></i>
        </button>
    )
}
Header.defaultProps = {
    uniqueID: null,
    toggle: () => {
        return null
    },
    title: '',
    show: false,
    onClick: () => {
        return null
    }
}

/**
 * [LHNAccordion] Body is the body of the accordion that appears when toggling the header
 * @param {string} uniqueID Unique ID for the accordion component for accessibility
 * @param {string} content Content as text to go in the accordion body
 * @param {bool} show True | false value for if the body of the accordion should be visible
 * @param {array} children Children of the body to render - can be any react or HTML component
 */
const Body = ({ uniqueID, content, show, children }) => {
    return (
        <div
            className={!show ? 'sps2-lhn_accordion--hide' : 'sps2-lhn_accordion'}
            id={'lhn-accordion_section--' + uniqueID}
            aria-labelledby={'lhn-accordion_header--' + uniqueID}
        >
            {content || children}
        </div>
    )
}
Body.defaultProps = {
    uniqueID: null,
    content: undefined,
    show: false,
    children: null
}

/**
 * LHNAccordion returns accordion component and functionality when used with the LHNAccordion.Header and LHNAccordion.Body
 * @param {boolean} show True | false value to have the accordion open or closed initially
 * @param {string} trackEventName Name for adoble tagging and analytics
 * @example
 * return (
 *  <LHNAccordion.LHNAccordion show={false}>
 *    <LHNAccordion.Header title="Title" />
 *    <LHNAccordion.Body>
 *      <button>Here is a button in the accordion body</button>
 *      <p>Here is additional content in body</p>
 *    </LHNAccordion.Body>
 *  </LHNAccordion.LHNAccordion>
 * )
 *
 */
const LHNAccordion = (props) => {
    const [show, setShow] = useState(props.show)
    const [containerStyle, setContainerStyle] = useState('mds-accordion')
    const isDesktop = useIsDesktop()
    const [uniqueID, setUniqueID] = useState('')

    // Collapse filter navigation on mobile and open it on desktop
    useEffect(() => {
        setShow(isDesktop)
    }, [isDesktop])

    // Generate uniqueID after window has loaded
    useEffect(() => {
        setUniqueID(uuidv4())
    }, [])

    const toggle = (title) => {
        setShow(!show)
    }

    return (
        <div className={containerStyle}>
            {props.children.map((child, index) => {
                return React.cloneElement(child, {
                    key: index,
                    uniqueID: uniqueID,
                    show: show,
                    toggle: toggle
                })
            })}
        </div>
    )
}

LHNAccordion.defaultProps = {
    show: false,
    tag: 'p',
    children: [<Header />, <Body />]
}

export { LHNAccordion, Header, Body }
