import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import BrightcoveVideo from './BrightcoveVideo'
import MagicZoom from './MagicZoom'
import Magic360Spinner from './Magic360Spinner'
import { useIsDesktop } from '../services/UtilityService.jsx'
import { Translations } from '../services/TranslationService'
import { StateContext } from '../services/StateService'
import ThreeSixtyIcon from '../../../partials/assets/360Icon.svg'
import { MAGIC360_CODE, VIDEO_CODE } from '../constants/PDPConstants'

const MainGalleryElement = ({
    onClick,
    className,
    isInnerGallery,
    magicZoomState,
    current360Data,
    modePlaylist,
    startMagicZoom
}) => {
    const isDesktop = useIsDesktop()
    const translations = useContext(Translations)
    const { clickTapZoom, hoverImageZoom, dragSpin } = translations
    const { activeMedia } = useContext(StateContext)

    return activeMedia?.usageCode === VIDEO_CODE ? (
        <button onClick={onClick} className={className}>
            <BrightcoveVideo
                videoPlayerListId={activeMedia?.videoPlayerListId}
                videoPlayerId={activeMedia?.videoPlayerId}
                videoPlayerType={activeMedia?.videoPlayerType}
                modePlaylist={modePlaylist}
                disablePlayButton={!isInnerGallery}
            />
        </button>
    ) : activeMedia?.usageCode === MAGIC360_CODE ? (
        <div
            role="button"
            onClick={onClick}
            className={`${className} sps2-pdp_gallery--magic360${
                isInnerGallery ? '-inner' : ''
            }`}
        >
            {isInnerGallery ? (
                <>
                    <Magic360Spinner
                        altText={activeMedia?.altText}
                        magic360={current360Data}
                    />
                    <div className="sps2-pdp_gallery--hoverText sps2-pdp_gallery--hoverText-inner">
                        {`${dragSpin} | ${clickTapZoom}`}
                    </div>
                </>
            ) : (
                <>
                    <div className="sps2-pdp_gallery--list-360icon">
                        <ThreeSixtyIcon style={{ width: '120px' }} />
                    </div>
                    <img
                        src={current360Data?.firstfileurl}
                        alt={activeMedia?.altText}
                        loading="lazy"
                    ></img>
                </>
            )}
        </div>
    ) : (
        <button
            onClick={onClick}
            className={`${className} ${
                isDesktop
                    ? `sps2-pdp_gallery--magiczoom-${isInnerGallery ? 'inner' : 'outer'}`
                    : ''
            }`}
            onMouseEnter={() => {
                if (startMagicZoom) {
                    startMagicZoom()
                }
            }}
        >
            {isDesktop && activeMedia && (
                <>
                    <MagicZoom
                        magicZoomId={isInnerGallery ? 'innerMagicZoom' : 'outerMagicZoom'}
                        largeImageUrl={`${activeMedia?.url}?width=1200`}
                        smallImageUrl={`${activeMedia?.url}?width=506`}
                        zoomOnClick={isInnerGallery}
                    >
                        <img
                            src={`${activeMedia?.url}?width=506`}
                            alt={activeMedia?.altText}
                            onClick={onClick}
                            style={{ cursor: 'zoom-in' }}
                            loading="lazy"
                        />
                    </MagicZoom>
                    <div
                        className={`sps2-pdp_gallery--hoverText ${
                            isInnerGallery
                                ? 'sps2-pdp_gallery--hoverText-inner'
                                : 'sps2-pdp_gallery--hoverText-outer'
                        }`}
                        style={
                            magicZoomState?.zoomState === 'in'
                                ? { visibility: 'hidden' }
                                : { visibility: 'visible' }
                        }
                    >
                        {isInnerGallery ? clickTapZoom : hoverImageZoom}
                    </div>
                </>
            )}
        </button>
    )
}

MainGalleryElement.propTypes = {
    usageCode: PropTypes.string,
    className: PropTypes.string
}

MainGalleryElement.defaultProps = {
    usageCode: '',
    className: ''
}

export default MainGalleryElement
