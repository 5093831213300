import { useEffect, useState } from 'react'
import { debounce } from './UtilityService.jsx'
import { toRelativeURL } from './URLService.jsx'
import {
    INDUSTRY_APPLICATIONS_TAB,
    PRODUCTS_TAB,
    LOAD_MORE_WEB_CONTENT,
    LOAD_MORE_DATA,
    LOAD_MORE_DATA_MOBILE,
    DESKTOP,
    USER_COOKIE,
    MAX_REQUEST_SIZE
} from '../constants/constants.js'

/**
 * showMoreSize constant returns how many additional items to load based on screensize
 */
const showMoreSize = () => {
    return global?.window?.innerWidth >= DESKTOP ? LOAD_MORE_DATA : LOAD_MORE_DATA_MOBILE
}

/**
 * getItemsLoaded returns the browser history's state itemsLoaded or a default value
 */
const getItemsLoaded = () => {
    let itemsLoaded = global.window?.history?.state?.itemsLoaded

    // Verify maximum request limit
    if (itemsLoaded > MAX_REQUEST_SIZE) {
        const serpTab = getSerpTab()

        // If it's a non-product SERP tab - request initial data only
        if (serpTab && serpTab !== PRODUCTS_TAB) {
            if (serpTab === INDUSTRY_APPLICATIONS_TAB) {
                itemsLoaded = LOAD_MORE_WEB_CONTENT
            } else {
                itemsLoaded = showMoreSize()
            }
        } else {
            // Return 0 so no request is made
            itemsLoaded = 0
        }
    }

    return itemsLoaded
}

/**
 * getScrollPos returns the browser history's state scrollPos or a default value
 */
const getScrollPos = () => {
    return global.window?.history?.state?.scrollPos ?? { x: 0, y: 0 }
}

/**
 * getSerpTab returns the browser history's state serpTab if defined
 */
const getSerpTab = () => {
    return global.window?.history?.state?.serpTab
}

/**
 * getUserCookie searches for the users cookie for tracking purposes
 * @returns cookie value
 */
const getUserCookie = () => {
    const cookieValue = document.cookie.match(
        '(^|;)\\s*' + USER_COOKIE + '\\s*=\\s*([^;]+)'
    )
    return cookieValue ? cookieValue.pop() : ''
}

/**
 * getRestorePreviousScroll returns function to return the previous scroll position
 */
const getRestorePreviousScroll = () => {
    let isValidRequest = true
    if (global.window?.history?.state?.itemsLoaded > MAX_REQUEST_SIZE) {
        isValidRequest = false
    }
    const [initialScroll] = useState(isValidRequest ? getScrollPos() : { x: 0, y: 0 })

    const restorePreviousScroll = () => {
        window.scrollTo(initialScroll.x, initialScroll.y)
    }

    return restorePreviousScroll
}

/**
 * preventBackgroundScroll is used for full screen modals & menus to keep scroll on popup only
 * taken and modified for React from: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 * @param {boolean} active it's set up to false
 */
const preventBackgroundScroll = (active = false) => {
    const [initialLoad, setInitialLoad] = useState(true)
    const [scrollY, setScrollY] = useState(0)

    useEffect(() => {
        if (!initialLoad) {
            if (active) stopBackgroundScroll()
            else {
                returnBackgroundScroll()
            }
        } else {
            setInitialLoad(false)
        }
    }, [active])

    const stopBackgroundScroll = () => {
        setScrollY(window.scrollY)
        document.body.style.position = 'fixed'
    }

    const returnBackgroundScroll = () => {
        document.body.style.position = ''
        window.scrollTo(0, parseInt(scrollY))
    }
}

/**
 * trackScroll function saves the user's current scroll state in window.history.state.scrollPos
 * Taken and modified for our purposes from here: https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
 */
const trackScroll = () => {
    const restorePreviousScroll = getRestorePreviousScroll()

    useEffect(() => {
        // Set scrollRestoration to 'manual' (browser's default is 'auto')
        window.history.scrollRestoration = 'manual'

        // handleScroll is the efficient handler of updating the window history state with scroll status
        const handleScroll = () => {
            // update state from existing state
            const updatedState = window.history.state ?? {}
            // add 'scrollPos' to state property with current window location
            updatedState.scrollPos = { x: window.scrollX, y: window.scrollY }
            // replace the state in the browser's history
            window.history.replaceState(updatedState, '', window.location.href)
        }

        // Add event listener for 'scroll' event and debounce handleScroll 400ms after user stops scrolling; 'passive' allows the UI to continue in default behavior
        window.addEventListener('scroll', debounce(handleScroll, 400), {
            passive: true
        })

        restorePreviousScroll()

        // When page unmounts, remove scroll event listener
        return () =>
            window.removeEventListener('scroll', debounce(handleScroll, 400), {
                passive: true
            })
    }, [])
}

/**
 * updateURL updates the browser's currently window location with the new browser state without redirecting the user
 * @param {string} url Url to update the current state with.  This updates the browser's history
 */
const updateURL = (url) => {
    const newURL = window.location.origin + toRelativeURL(url)
    window.history.replaceState({}, '', newURL)
    return null
}

/**
 * updateWindowState updates the local window state
 * @param {state} newState New variable or object to update the local state with
 */
const updateWindowState = (newState) => {
    const updatedState = { ...window.history.state, ...newState }
    window.history.replaceState(updatedState, '', window.location.href)
}

/**
 * checkLegal Checks if the current page matches criteria for a legal disclaimer
 * Returns the name of the property that has the appropiate message.
 */
const checkLegal = () => {
    const HCBGpatternForCsCZ =
        /\/3M\/cs_CZ\/p\/c\/(zdravotnicke-vyrobky|dentalni-a-ortodonticke-produkty|osobni-ochranne-prostredky\/masky-a-respiratory-pro-zdravotnictvi)(\/{1}[a-z]+)*(\/)?/
    const HCBGpatternForPlPL =
        /\/3M\/pl_PL\/p\/c\/(medyczne|stomatologia-i-ortodoncja|srodki-ochrony-indywidualnej\/maski-chirurgiczne-i-aparaty-oddechowe)(\/{1}[a-z]+)*(\/)?/
    const SIBGpattern = /test/ // TBD: This must be changed for the actual pattern
    const currentURL = window?.location.href

    if (HCBGpatternForCsCZ.test(currentURL)) return 'healthCareStatement'
    if (HCBGpatternForPlPL.test(currentURL)) return 'healthCareStatement'

    return '' // Not match
}

export {
    getUserCookie,
    getRestorePreviousScroll,
    getItemsLoaded,
    getSerpTab,
    preventBackgroundScroll,
    showMoreSize,
    trackScroll,
    updateWindowState,
    updateURL,
    checkLegal
}
