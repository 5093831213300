import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ grayBack, children }) => {
    return (
        <div
            className={
                grayBack === true
                    ? 'mds-contentCTA mds-contentCTA_gray'
                    : 'mds-contentCTA'
            }
        >
            {children}
        </div>
    )
}

Container.propTypes = {
    /** if true background will be gray */
    grayBack: PropTypes.bool
}

Container.defaultProps = {
    grayBack: false
}

const Header = ({ title, content }) => {
    return (
        <>
            <h3 className="mds-contentCTA_heading mds-font_header--3" tabIndex="-1">
                {title}
            </h3>
            <div
                className="mds-contentCTA_content--body mds-font_intro--1"
                dangerouslySetInnerHTML={{ __html: content }}
            ></div>
        </>
    )
}

Container.propTypes = {
    /** Main title */
    title: PropTypes.string,
    /** Text of the content */
    content: PropTypes.string
}

Container.defaultProps = {
    title: undefined,
    content: undefined
}

const Footer = ({ children }) => {
    return <div className="mds-contentCTA_footer">{children}</div>
}

const ContentCTA = { Container, Header, Footer }

export default ContentCTA
