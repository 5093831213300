import React, { useContext } from 'react'
import { Translations } from '../services/TranslationService'
import { StateContext } from '../services/StateService'
import { trackPDPData } from '../services/TealiumService'
import Select from '@3mcom/mds-library/dist/node/Select'

const Selectors = () => {
    const translations = useContext(Translations)
    const { selectedOptions, moreOptions, dispatch, highlightSelectors } =
        useContext(StateContext)
    const selectionMade = selectedOptions?.totalSelected === moreOptions?.totalSelected

    if (!selectedOptions?.options) return null
    return Object.keys(selectedOptions?.options)?.map((key, index) => {
        const selector = selectedOptions.options[key]
        if (!selector?.values || selector?.values.length === 1) return null

        let className = ''
        if (!selector.selectedValue && !highlightSelectors) {
            className = 'sps2-pdp_pSelector--selectors_regular-gray-ring'
        } else if (selector.selectedValue) {
            className = 'sps2-pdp_pSelector--selectors_thick-black-ring'
        } else if (highlightSelectors && selectionMade) {
            className = 'sps2-pdp_pSelector--selectors_thick-red-ring'
        }

        return (
            <Select
                fieldName={selector?.label}
                id={`${index}_pSelector`}
                key={`${index}_pSelector`}
                selectLabel={selector?.label}
                disabled={selector.disabled}
                value={selector?.selectedValue}
                onChange={(e) => {
                    dispatch({
                        type: 'pdp_on_change_selected_options',
                        payload: {
                            selector: selector,
                            target: e.target,
                            allLabel: translations.all
                        }
                    })
                    trackPDPData(
                        `PDP Buying Zone Product Selector ${selector.label} Click: ${selector?.selectedValue}`,
                        'Engagement Event'
                    )
                }}
                className={className}
            >
                {selector?.values?.map((value, i) => (
                    <option
                        disabled={value?.disabled}
                        className={
                            value?.disabled
                                ? 'sps2-pdp_pSelector--selectors_disabled'
                                : ''
                        }
                        key={`${i}_p-value`}
                        value={value?.api}
                        optionlabel={`${value?.value} ${value?.uom ? value.uom : ''}`}
                    >
                        {`${value?.value} ${value?.uom ? value.uom : ''}${
                            value?.disabled ? ` - ${translations.notApplicable}` : ''
                        }`}
                    </option>
                ))}
            </Select>
        )
    })
}

export default Selectors
