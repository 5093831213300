import React, { useContext } from 'react'
import BrightcoveVideo from './BrightcoveVideo'
import ThreeSixtyIcon from '../../../partials/assets/360Icon.svg'
import Magic360Spinner from './Magic360Spinner'
import { Translations } from '../services/TranslationService'
import {
    MAGIC360_CODE,
    MAIN_IMAGE_CODE,
    ADDITIONAL_IMAGE_CODE,
    VIDEO_CODE
} from '../constants/PDPConstants'

const Thumbnail = ({
    mediaElement,
    modePlaylist,
    magic360media,
    showMore,
    showMoreLabel,
    isMainImage,
    isInnerGallery,
    index
}) => {
    const translations = useContext(Translations)

    return (
        <>
            {/* Images */}
            {(mediaElement?.usageCode === ADDITIONAL_IMAGE_CODE ||
                mediaElement?.usageCode === MAIN_IMAGE_CODE) && (
                <>
                    {showMore && <span>{showMoreLabel}</span>}
                    <img
                        src={`${mediaElement?.url}${isMainImage ? '?width=506' : ''}`}
                        alt={mediaElement?.altText}
                        loading="lazy"
                    ></img>
                </>
            )}
            {/* Videos */}
            {mediaElement?.usageCode === VIDEO_CODE && (
                <>
                    {showMore && <span>{showMoreLabel}</span>}
                    <BrightcoveVideo
                        videoPlayerId={mediaElement?.videoPlayerId}
                        videoPlayerListId={mediaElement?.videoPlayerListId}
                        videoPlayerType={mediaElement?.videoPlayerType}
                        modePlaylist={isMainImage ? modePlaylist : false}
                        disablePlayButton={!isInnerGallery || !isMainImage}
                    />
                </>
            )}
            {/* 360 Spinners */}
            {mediaElement?.usageCode === MAGIC360_CODE && isInnerGallery && (
                <div className="sps2-pdp_gallery--magiczoom-inner">
                    <Magic360Spinner
                        altText={mediaElement?.altText}
                        magic360={magic360media?.find(
                            (element) => element.position === index
                        )}
                    />
                    <div className="sps2-pdp_gallery--hoverText sps2-pdp_gallery--hoverText-inner">
                        {`${translations.dragSpin} | ${translations.clickTapZoom}`}
                    </div>
                </div>
            )}
            {mediaElement?.usageCode === MAGIC360_CODE && !isInnerGallery && (
                <>
                    <div className="sps2-pdp_gallery--list-360icon">
                        <ThreeSixtyIcon
                            style={{ width: `${isMainImage ? '120px' : '40px'}` }}
                        />
                    </div>
                    {showMore && <span>{showMoreLabel}</span>}
                    <img
                        src={
                            magic360media?.find((element) => element.position === index)
                                ?.firstfileurl
                        }
                        alt={mediaElement?.altText}
                        loading="lazy"
                    ></img>
                </>
            )}
        </>
    )
}

Thumbnail.defaultProps = {
    showMore: false,
    isMainImage: false
}

export default Thumbnail
