import React, { useContext } from 'react'
import { Translations } from '../services/TranslationService.jsx'
import { useIsDesktop } from '../services/UtilityService.jsx'
import Results from '../components/Results.jsx'
import '../css/FilterBar.scss'

/**
 * This is the Category and Filter Bar for the PCP - determines the order for mobile and desktop
 * @param {array} categoriesList Array with the items in the categories section
 * @param {number} total Number of total items available for the page
 * @param {array} filterToggle The FilterToggle element to be rendered (avoids prop drilling)
 * @param {array} breadboxList The BreadBoxList element to be rendered (avoids prop drilling)
 * @param {boolean} resultsTitle If it comes with a true it will stay true, otherwise it will be set as false. @ Products View & PCP don't set it up to true.
 */
const FilterBar = ({ total, filterToggle, breadboxList, resultsTitle = false }) => {
    const { products, results, product, result } = useContext(Translations)
    const isDesktop = useIsDesktop()

    const checkTotal = (total, resultsTitle) => {
        var labelOptions = ''
        if (total === 1) {
            resultsTitle ? (labelOptions = result) : (labelOptions = product)
        } else {
            resultsTitle ? (labelOptions = results) : (labelOptions = products)
        }
        return labelOptions
    }

    // Filter bar for the product count and the mobile filter button
    const filterBar = (
        <div className="sps2-bar--top">
            <Results total={total} labelText={checkTotal(total, resultsTitle)} />
            {filterToggle}
        </div>
    )

    // Filter bar changes styles based on mobile vs desktop for sticky filter bar on mobile
    return isDesktop ? (
        <div className="sps2-bar">
            {filterBar}
            {breadboxList}
        </div>
    ) : (
        <>
            <div className="sps2-bar--sticky">{filterBar}</div>
            {breadboxList}
        </>
    )
}

export default FilterBar
