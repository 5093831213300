import React, { useContext, useEffect, useRef, useState } from 'react'
import { StateContext } from '../services/StateService.jsx'
import { FilterToggle, FooterCTAs } from '../fragments/Navigation/MobileNavComponents.jsx'
import {
    getItemsLoaded,
    getRestorePreviousScroll,
    trackScroll,
    updateURL,
    updateWindowState
} from '../services/BrowserService.jsx'
import { getLoadMoreURL, getClearBreadboxLink } from '../services/URLService.jsx'
import { getRequest, postSignalData } from '../services/AxiosService.jsx'
import { PCP_SELECT } from '../constants/constants.js'

import BreadBoxList from '../fragments/BreadBoxList.jsx'
import CategorySection from '../fragments/CategorySection.jsx'
import ProductList from '../fragments/ProductList.jsx'
import BreadCrumbList from '../fragments/BreadCrumbList.jsx'
import PageTitle from '../fragments/PageTitle.jsx'
import Gallery from '../fragments/Gallery.jsx'
import Navigation from '../fragments/Navigation/Navigation.jsx'
import FilterBar from '../fragments/FilterBar.jsx'
import MedalliaFeedback from '../fragments/Medallia.jsx'
import DesktopOverlay from '../components/DesktopOverlay.jsx'
import '../css/PCP.scss'
import RichContentSection from '../components/RichContentSection.jsx'
import JumpLinkSection from '../components/JumpLinkSection.jsx'
import { sortFacets } from '../services/UtilityService.jsx'
import LegalDisclaimer from '../components/LegalDisclaimer.jsx'
import { Buffer } from 'buffer'


const PCP = (_) => {
    const {
        dispatch,
        aggregations,
        breadCrumb,
        endpoints,
        primaryPageTitle,
        secondaryPageTitle,
        locale,
        total,
        breadBox,
        canonicalUrl,
        items,
        loading,
        richContent,
        queryId
    } = useContext(StateContext)

    // References the filter Section for later focus
    const galleryRef = useRef(null)

    // keep static from page load, and sorting facets
    let tempCategoriesList = []
    if (aggregations.sticky?.categories?.facets) {
        tempCategoriesList = sortFacets(aggregations.sticky?.categories?.facets, true)
    }
    const [categorySectionList] = useState(tempCategoriesList)
    const { pcpShowNext, analyticsUrl } = endpoints

    // Create mobile left hand navigation state
    const [showMobileLHN, setShowMobileLHN] = useState(false)

    // Set clear breadBox link to remove all filters including industries & brands
    const clearBreadboxLink = getClearBreadboxLink(canonicalUrl)

    // Function to load more products using axios
    const loadMore = (size, callback) => {
        const endpoint = getLoadMoreURL(pcpShowNext, items.length, size)
        getRequest(endpoint)
            .then((result) => {
                // Set attribute to focus on the first returned result
                if (result.data.items?.length > 0) {
                    result.data.items[0].focusOn = true
                }

                // Update local window state with number of products loaded
                updateWindowState({
                    itemsLoaded: items.length + result.data.items.length
                })

                // Update the state with the new items
                dispatch({
                    type: 'load_more_products',
                    payload: result.data.items
                })

                // using setTimeout allows the callback to be called after the dispatch (otherwise is called before dispatch is reflected)
                if (callback) {
                    setTimeout(callback, 0)
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'show_error_message',
                    payload: `Error on loading more products: ${error}`
                })
            })
    }

    trackScroll()
    const restorePreviousScroll = getRestorePreviousScroll()

    useEffect(() => {
        dispatch({ type: 'set_loading_status', payload: false })

        // Re-open the mobile LHN if '#filter' is in URL
        if (window.location.href.indexOf('#filter') > -1) {
            setShowMobileLHN(true)
            updateURL(window.location.href.replace('#filter', ''))
        }

        // Load more items if previously loaded in browser state
        const previousItemsLoaded = getItemsLoaded()
        if (previousItemsLoaded) {
            loadMore(previousItemsLoaded - items?.length, restorePreviousScroll)
        }
    }, [])

    const richContentList = []
    if (richContent) {
        for (const richContentEncoded of richContent) {
            richContentList.push({
                html: Buffer.from(richContentEncoded.html, 'base64').toString('utf8'),
                oid: richContentEncoded?.oid
            })
        }
    }

    return (
        <div className="sps2-pcp">
            <BreadCrumbList breadcrumbsList={breadCrumb} />
            <LegalDisclaimer />
            <PageTitle
                className="sps2-title"
                primaryTitle={primaryPageTitle}
                secondaryTitle={secondaryPageTitle}
            />
            <CategorySection categoriesList={categorySectionList} locale={locale} />
            {richContentList.length > 0 && (
                <JumpLinkSection
                    total={total}
                    richContentList={richContentList}
                    galleryRef={galleryRef}
                />
            )}
            <RichContentSection richContentList={richContentList} />
            <FilterBar
                total={total}
                filterToggle={
                    aggregations && (
                        <FilterToggle
                            showMobileLHN={showMobileLHN}
                            setShowMobileLHN={setShowMobileLHN}
                        />
                    )
                }
                breadboxList={
                    <BreadBoxList clearLink={clearBreadboxLink} breadboxList={breadBox} />
                }
            />
            <div className="sps2-lhngallery">
                <DesktopOverlay loading={loading} />
                <Navigation
                    breadBox={breadBox}
                    breadCrumb={breadCrumb}
                    aggregations={aggregations}
                    loading={loading}
                    showMobileLHN={showMobileLHN}
                    setShowMobileLHN={setShowMobileLHN}
                    footerCTAs={
                        <FooterCTAs
                            breadBox={breadBox}
                            clearLink={clearBreadboxLink}
                            setShowMobileLHN={setShowMobileLHN}
                            dispatch={dispatch}
                        />
                    }
                />
                <Gallery
                    gallerySize={items?.length}
                    loadMore={() => {
                        loadMore()
                    }}
                    total={total}
                    galleryRef={galleryRef}
                    addHiddenNextLink={true}
                >
                    <ProductList
                        products={items}
                        productTracking={(position, stockNumber) => {
                            postSignalData(
                                analyticsUrl,
                                position,
                                PCP_SELECT,
                                stockNumber,
                                queryId
                            )
                        }}
                    />
                </Gallery>
            </div>
            {/* Temporary fix to hide incomplete section */}
            {/* <MedalliaFeedback /> */}
        </div>
    )
}

export default PCP
