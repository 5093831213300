import React, { useContext, useEffect, useState } from 'react'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService.jsx'
import { getLoadMoreURL } from '../../services/URLService.jsx'
import { NO_IMAGE } from '../../constants/constants.js'
import { getRequest } from '../../services/AxiosService.jsx'
import ProductBar from '@3mcom/mds-library/dist/node/ProductBar'
import { useIsDesktop } from '../../services/UtilityService.jsx'
import { trackPDPData } from '../../services/TealiumService'
import Tabs from '@3mcom/mds-library/dist/node/Tabs'
import Alert from '@3mcom/mds-library/dist/node/Alert'
import '../../css/FrequentlyBoughtTogether.scss'

const FrequentlyBoughtTogether = ({ boughtTogetherRef }) => {
    const [tabsData, setTabsData] = useState(null)
    const [activeTab, setActiveTab] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const isDesktop = useIsDesktop()

    const translations = useContext(Translations)

    const { frequentBoughtTogether, showMore, ajaxError } = translations

    const {
        boughtTogetherMore,
        dispatch,
        boughtTogether: boughtTogetherList,
        endpoints,
        boughtTogetherAggs,
        boughtTogetherType,
        errors
    } = useContext(StateContext)

    useEffect(() => {
        if (boughtTogetherList.length > 0) {
            // On the first render the first active tab will always be the boughtTogetherAggs[0]
            // and the boughtTogetherList on page load will be it's boughtTogetherList
            const tabName = boughtTogetherAggs[0]?.typeCode
            if (tabName) {
                setActiveTab(tabName)
                setTabsData({
                    [tabName]: {
                        boughtTogether: boughtTogetherList,
                        boughtTogetherMore: boughtTogetherMore
                    }
                })
            }
        }
    }, [])

    useEffect(() => {
        if (activeTab !== '' && !tabsData[activeTab]) {
            // get the new resources based on the activeTab
            loadMoreFrequentlyBoughtTogether()
        }
        if (errors?.FBTAjaxError) {
            dispatch({ type: 'set_AJAX_errors', payload: { FBTAjaxError: false } })
        }
    }, [activeTab])

    useEffect(() => {
        if (boughtTogetherType !== '' && activeTab !== '') {
            // boughtTogetherType will be created after we clicked on a new tab the asynchronus ones
            // the first one is the only tab loaded at the beginning so it will
            // append new resources if the boughtTogetherType exist or it will create a new key with the boughtTogetherType and the boughtTogether List
            // and boughtTogetherMore of the specific tab
            setTabsData((prevState) => ({
                ...prevState,
                [boughtTogetherType]: {
                    boughtTogether: [
                        ...(prevState[boughtTogetherType]
                            ? prevState[boughtTogetherType].boughtTogether
                            : []),
                        ...boughtTogetherList
                    ],
                    boughtTogetherMore: boughtTogetherMore
                }
            }))
            setIsLoading(false)
        }
    }, [boughtTogetherType, boughtTogetherList])

    const loadMoreFrequentlyBoughtTogether = () => {
        const tabBoughtTogetherLength = tabsData[activeTab]
            ? tabsData[activeTab].boughtTogether.length
            : 0

        let endpoint = getLoadMoreURL(
            `${endpoints.moreFrequentlyBoughTogether}/`,
            tabBoughtTogetherLength,
            tabBoughtTogetherLength + 4
        )

        endpoint = `${endpoint}&typeCode=${activeTab}`

        getRequest(endpoint)
            .then((response) => {
                if (response.data) {
                    dispatch({
                        type: 'pdp_load_more_bought_together',
                        payload: {
                            ...response.data,
                            boughtTogetherType: activeTab
                        }
                    })
                }
            })
            .catch((error) => {
                dispatch({ type: 'set_AJAX_errors', payload: { FBTAjaxError: true } })
                setIsLoading(false)
                dispatch({
                    type: 'show_error_message',
                    payload: `Error on loading more frequentlyBoughtTogether: ${error}`
                })
            })

        trackPDPData(
            `PDP Frequently Bought Together Button Click: ${showMore}`,
            'Engagement Event'
        )
    }

    const onClickLoadMoreButton = () => {
        if (!isLoading) {
            setIsLoading(true)
            loadMoreFrequentlyBoughtTogether()
        }
    }

    return (
        <div ref={boughtTogetherRef} className="sps2-pdp_section sps2-pdp_FBT">
            <h3 className="mds-font_header--3" tabIndex="-1">
                {frequentBoughtTogether}
            </h3>
            {boughtTogetherAggs?.length > 0 && tabsData && (
                <Tabs.TabsContainer defaultActive={0} alignment={'left'}>
                    {boughtTogetherAggs?.map((tab, index) => (
                        <Tabs.TabItem
                            key={index}
                            title={`${tab.label} (${tab.count})`}
                            onClick={() => setActiveTab(tab.typeCode)}
                        >
                            <ProductBar.Container
                                loadMore={tabsData[activeTab]?.boughtTogetherMore}
                            >
                                {activeTab !== '' &&
                                    tabsData[activeTab]?.boughtTogether.map(
                                        (product, index) => {
                                            return (
                                                <ProductBar.Item key={index}>
                                                    <a
                                                        className="mds-link"
                                                        href={product?.url}
                                                    >
                                                        <ProductBar.Image
                                                            src={
                                                                product?.image || NO_IMAGE
                                                            }
                                                            alt={product?.altText}
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                    <h6 className="sps2-pdp_FBT--category mds-font_header--6">
                                                        {product?.label}
                                                    </h6>
                                                    <ProductBar.Link
                                                        href={product?.url}
                                                        hrefClass="mds-link mds-link_product"
                                                        onClick={() =>
                                                            trackPDPData(
                                                                `PDP Frequently Bought Together Link Click: ${product?.name}`,
                                                                'Engagement Event'
                                                            )
                                                        }
                                                    >
                                                        {product?.name}
                                                    </ProductBar.Link>
                                                </ProductBar.Item>
                                            )
                                        }
                                    )}
                                {!errors?.FBTAjaxError &&
                                    ((isDesktop &&
                                        tabsData[activeTab]?.boughtTogether?.length %
                                            4 !==
                                            0) ||
                                        (!isDesktop &&
                                            tabsData[activeTab]?.boughtTogether?.length %
                                                2 !==
                                                0)) && (
                                        <ProductBar.Item
                                            className="mds-prodBar_item sps2-pdp_blank-item"
                                            tabIndex="-1"
                                        ></ProductBar.Item>
                                    )}
                                {tabsData[activeTab]?.boughtTogetherMore && (
                                    <ProductBar.LoadMore>
                                        <button
                                            onClick={onClickLoadMoreButton}
                                            className="mds-button mds-button_tertiary mds-button_tertiary--large "
                                        >
                                            {showMore}
                                        </button>
                                    </ProductBar.LoadMore>
                                )}
                                {errors?.FBTAjaxError && (
                                    <div className="sps2-pdp_ajaxError--centered mds-margin_medium--top">
                                        <Alert.InContext variant="error">
                                            {ajaxError}
                                        </Alert.InContext>
                                    </div>
                                )}
                            </ProductBar.Container>
                        </Tabs.TabItem>
                    ))}
                </Tabs.TabsContainer>
            )}
        </div>
    )
}

export default FrequentlyBoughtTogether
