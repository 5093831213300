import { useState, useEffect } from 'react'
import { getTabsTotal } from './AxiosService.jsx'
import { getProductsData } from '../fragments/serp/ProductsView.jsx'
import { getWebContentData } from '../fragments/serp/WebContentView.jsx'
import { getResourcesData } from '../fragments/serp/ResourcesView.jsx'
import { getRegulatoryData } from '../fragments/serp/RegulatoryView.jsx'
import {
    getItemsLoaded,
    getRestorePreviousScroll,
    getSerpTab,
    updateWindowState
} from './BrowserService.jsx'
import {
    ALL_RESULTS_TAB,
    INDUSTRY_APPLICATIONS_TAB,
    PRODUCTS_TAB,
    REGULATORY_TAB,
    RESOURCES_TAB,
    WEBCONTENT_FUNCTION,
    LOAD_MORE_WEB_CONTENT
} from '../constants/constants.js'
import { getLoadMoreURL } from './URLService.jsx'

/**
 * TabController to manage the tab states and data requests #TBD
 * @param {function} dispatch
 * @param {array} endpoints
 * @param {object} webContentData
 * @param {object} regulatoryData
 * @param {object} resourcesData
 * @param {array} items
 * @returns {state} returns a new state of the tabs
 */
const tabController = ({
    dispatch,
    endpoints,
    webContentData,
    regulatoryData,
    resourcesData,
    items
}) => {
    const [tab, setTab] = useState(getSerpTab() ?? 0)
    const restorePreviousScroll = getRestorePreviousScroll()
    const previousItemsLoaded = getItemsLoaded()
    const [initialLoad, setInitialLoad] = useState(true)

    useEffect(() => {
        if (initialLoad && tab > -1) {
            initTab(tab, true)
        } else {
            initTab(tab)
            updateWindowState({ serpTab: tab })
        }
        setInitialLoad(false)
    }, [tab])

    // Function to dispatch an update to the endpoints.filterApi state with given endpoint
    const setFilterEndpoint = (endpoint) => {
        dispatch({ type: 'set_filter_endpoint', payload: endpoint })
    }

    const initTab = (tab, restoreBrowser = false) => {
        const { productsSerpApi, regulatoryApi, resourcesApi, webContentApi } = endpoints
        switch (tab) {
            case PRODUCTS_TAB:
                if (restoreBrowser && previousItemsLoaded) {
                    const itemsLoaded = items?.length ?? 0
                    const loadMoreEndpoint = getLoadMoreURL(
                        productsSerpApi,
                        itemsLoaded,
                        previousItemsLoaded - itemsLoaded
                    )
                    getProductsData(
                        loadMoreEndpoint,
                        dispatch,
                        items.length,
                        false,
                        restorePreviousScroll
                    )
                }
                setFilterEndpoint(productsSerpApi)
                break

            case INDUSTRY_APPLICATIONS_TAB:
                initTab(WEBCONTENT_FUNCTION, restoreBrowser)
                break
            case REGULATORY_TAB:
                if(regulatoryApi === undefined){
                    break
                }
                if (regulatoryData?.total !== 0 && !regulatoryData?.items) {
                    if (restoreBrowser) {
                        const loadMoreEndpoint = getLoadMoreURL(
                            regulatoryApi,
                            0,
                            previousItemsLoaded
                        )
                        getRegulatoryData(
                            loadMoreEndpoint,
                            dispatch,
                            restorePreviousScroll
                        )
                    } else {
                        getRegulatoryData(regulatoryApi, dispatch)
                    }
                }
                setFilterEndpoint(regulatoryApi)
                break
            case RESOURCES_TAB:
                if(resourcesApi === undefined){
                    break
                }
                if (resourcesData?.total !== 0 && !resourcesData?.mediaLinks) {
                    if (restoreBrowser) {
                        const loadMoreEndpoint = getLoadMoreURL(
                            resourcesApi,
                            0,
                            previousItemsLoaded
                        )
                        getResourcesData(
                            loadMoreEndpoint,
                            dispatch,
                            restorePreviousScroll
                        )
                    } else {
                        getResourcesData(resourcesApi, dispatch)
                    }
                }
                setFilterEndpoint(resourcesApi)
                break
            case ALL_RESULTS_TAB:
                initTab(WEBCONTENT_FUNCTION, restoreBrowser)
                initTab(REGULATORY_TAB, restoreBrowser)
                initTab(RESOURCES_TAB, restoreBrowser)
                break
            case WEBCONTENT_FUNCTION:
                if (webContentData?.total !== 0 && !webContentData?.items) {
                    if (restoreBrowser) {
                        const loadMoreEndpoint = getLoadMoreURL(
                            webContentApi,
                            0,
                            previousItemsLoaded
                        )
                        getWebContentData(
                            loadMoreEndpoint,
                            dispatch,
                            restorePreviousScroll
                        )
                    } else {
                        // Web content should load in batches of 20
                        const endpoint = getLoadMoreURL(
                            webContentApi,
                            0,
                            LOAD_MORE_WEB_CONTENT
                        )
                        getWebContentData(endpoint, dispatch)
                    }
                }
                setFilterEndpoint(webContentApi)
                break
            default:
                initTab(PRODUCTS_TAB, restoreBrowser)
        }
    }

    return { setTab }
}

// With the EP we set these values to the State props for each tab.
/**
 * updateTabsTotal to manage the tab states and data requests
 * @param {array} endpoints from where to request or get the other JSONs
 * @param {function} dispatch needed to take care of the update on the context.
 * @param {boolean} setLoadedStatus
 */
const updateTabsTotal = (endpoints, dispatch, setLoadedStatus) => {
    if (endpoints) {
        getTabsTotal(
            endpoints.resourcesApi,
            endpoints.webContentApi,
            endpoints.regulatoryApi
        )
            .then((tabsData) => {
                dispatch({
                    type: 'update_tabs_totals',
                    payload: { data: tabsData }
                })
                setLoadedStatus(true)
            })
            .catch((error) => {
                dispatch({
                    type: 'show_error_message',
                    payload: `Error on SERP tabs total number: ${error}`
                })
                setLoadedStatus(true)
            })
    }
}

export { tabController, updateTabsTotal }
