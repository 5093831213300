import React, { useContext } from 'react'
import InformationBlock from '../../components/InformationBlock.jsx'
import Gallery from '../Gallery.jsx'
import { getLoadMoreURL } from '../../services/URLService.jsx'
import { getRequest, postSignalData } from '../../services/AxiosService.jsx'
import { updateWindowState } from '../../services/BrowserService.jsx'
import Results from '../../components/Results.jsx'
import { Translations } from '../../services/TranslationService.jsx'
import { LOAD_MORE_WEB_CONTENT, WEBCONTENT_SELECT } from '../../constants/constants.js'

/**
 * getWebContentData function fetches the webcontent data for industry applications and news tabs
 * @param {object} endpoints The endpoints object containing the webContentApi
 * @param {function} dispatch The dispatch function for the state reducer
 * @param {function} this is the function that will be called after this is done.
 */
const getWebContentData = (endpoint, dispatch, callback) => {
    getRequest(endpoint)
        .then((result) => {
            dispatch({
                type: 'update_webContent_data',
                payload: result.data
            })
            // using setTimeout allows the callback to be called after the dispatch (otherwise is called before dispatch is reflected)
            if (callback) {
                setTimeout(callback, 0)
            }
        })
        .catch((error) => {
            dispatch({
                type: 'show_error_message',
                payload: `Error on loading webcontent data: ${error}`
            })
        })
}

/**
 * This will setup and return the WebContent section wit the updated code from the context.
 * @param {array} items
 * @param {string} total
 * @param {function} dispatch
 * @param {string} endpoints
 * @returns the WebContentView set with all the updated values from the context.
 */
const WebContentView = ({ items, total, dispatch, endpoints, queryId }) => {
    const { results } = useContext(Translations)
    const { webContentApi, analyticsUrl } = endpoints
    const webContentList = items?.map((item) => {
        return (
            <InformationBlock
                key={item.position}
                {...item}
                onClickTracker={() => {
                    postSignalData(
                        analyticsUrl,
                        item.position,
                        WEBCONTENT_SELECT,
                        undefined,
                        queryId
                    )
                }}
            />
        )
    })

    const loadMore = () => {
        // Web content should load in batches of 20
        const endpoint = getLoadMoreURL(
            webContentApi,
            items.length,
            LOAD_MORE_WEB_CONTENT
        )
        getRequest(endpoint)
            .then((result) => {
                // Update the state with the new items
                dispatch({
                    type: 'load_more_webContent',
                    payload: result.data.items
                })

                // Update local window state with number of items loaded
                updateWindowState({
                    itemsLoaded: items?.length + result.data.items.length
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'show_error_message',
                    payload: `Error on loading more web content items: ${error}`
                })
            })
    }

    return (
        <>
            <div className="sps2-bar">
                <div className="sps2-bar--top">
                    <Results total={total} labelText={results} />
                </div>
            </div>

            <div className="sps2-lhngallery">
                <Gallery gallerySize={items?.length} loadMore={loadMore} total={total}>
                    {webContentList}
                </Gallery>
            </div>
        </>
    )
}

export { WebContentView as default, getWebContentData }
