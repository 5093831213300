import React, { useState, useEffect, useContext } from 'react'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { toRelativeURL, createBreadboxDynamicRemoveURL } from '../services/URLService.jsx'
import { getTealiumObj } from '../services/TealiumService.jsx'
import { filterSelection } from '../services/SelectionService.jsx'
import { StateContext } from '../services/StateService.jsx'

/**
 * This is an individual BreadBox of the BreadBoxes List for PCP
 * @param {array} value The potential values of the filtered breadBox - example: 'Manufacturing'
 * @param {string} type The type of the breadBox - example: 'industry'
 * @param {string} name The label / name of the filtered breadBox - example: 'Industries'
 * @param {string} removeUrl The url to remove the breadBox
 * @param {boolean} hide True | False to hide the breadBox component - will be true when user clicks 'Clear all'
 */

const BreadBox = ({ value, name, removeUrl, hide = false, dynamicLoad = false }) => {
    const { dispatch, endpoints } = useContext(StateContext)

    // Variable hidden is the local BreadBox state to hide the breadBox when clicked - user experience improvement to reflect state
    const [hidden, setHidden] = useState(hide)

    // useEffect listens to 'hide' variable changing - is used for 'Clear all' - user experience improvement to reflect state
    useEffect(() => {
        setHidden(hide)
    }, [hide])

    const breadboxValue = value
        .map((val, index) => {
            return index === value.length - 1 ? val : val + ' > '
        })
        .reduce((breadboxLabel, currentValue) => breadboxLabel + currentValue)

    const onClick = () => {
        TealiumTag(getTealiumObj('snaps-breadBox Click: ' + name + ': ' + breadboxValue))
        // let's check if the page is SERP -> dynamicLoad = true.
        if (dynamicLoad) {
            if (!endpoints.filterApi) return
            const breadboxApi = createBreadboxDynamicRemoveURL(
                removeUrl,
                endpoints.filterApi
            )

            filterSelection(breadboxApi, toRelativeURL(removeUrl), endpoints, dispatch)
        } else {
            // if not, it has to be a PCP page.
            dispatch({ type: 'set_loading_status', payload: true })
            setHidden(true)
        }
    }
    // breadbox for PCP.
    const breadboxLink = (
        <a
            className="sps2-breadBox_breadBox mds-font_legal"
            href={toRelativeURL(removeUrl)}
            onClick={onClick}
            rel="nofollow"
        >
            <span className="sps2-breadBox--onDesktop">
                {value.length > 1 ? '' : name + ': '}
            </span>
            {breadboxValue}
            <i className="MMM--icn MMM--icn_close"></i>
        </a>
    )
    // breadbox for SERP.
    const breadboxButton = (
        <button className="sps2-breadBox_breadBox mds-font_legal" onClick={onClick}>
            <span className="sps2-breadBox--onDesktop">
                {value.length > 1 ? '' : name + ': '}
            </span>
            {breadboxValue}
            <i className="MMM--icn MMM--icn_close"></i>
        </button>
    )
    // if clicked on a pcp breadbox, we returned a null
    if (hidden) {
        return null
    }
    // if we have a serp breadbox
    if (dynamicLoad) {
        return breadboxButton
    }
    // if we have a pcp breadbox
    return breadboxLink
}
export default BreadBox
