import React, { useContext } from 'react'
import { StateContext } from '../../services/StateService'
import { getDecodedHTML } from '../../services/UtilityService'

const UpperDetails = () => {
    const { productDetails } = useContext(StateContext)

    const htmlTagRegex = /<[^<>]+>/g

    const shortDescription = productDetails?.shortDescription
    const longDescription = getDecodedHTML(productDetails?.longDescription)
    if (!shortDescription && !longDescription) return null
    return (
        <div className="sps2-pdp_details--white_container">
            <div className="sps2-pdp_details--upper_details">
                {shortDescription &&
                    (htmlTagRegex.test(shortDescription) ? (
                        <div
                            className="mds-font_paragraph"
                            dangerouslySetInnerHTML={{
                                __html: shortDescription
                            }}
                        ></div>
                    ) : (
                        <p
                            className={`mds-font_paragraph ${
                                longDescription ? 'MMM--gapBottom' : ''
                            }`}
                        >
                            {shortDescription}
                        </p>
                    ))}
                {longDescription &&
                    (htmlTagRegex.test(longDescription) ? (
                        <div
                            className="mds-font_paragraph"
                            dangerouslySetInnerHTML={{
                                __html: longDescription
                            }}
                        ></div>
                    ) : (
                        <p className="mds-font_paragraph"> {longDescription} </p>
                    ))}
            </div>
        </div>
    )
}

export default UpperDetails
