export const PDPTranslations = {
    ajaxError: '[Unable to load!]',
    all: '[All]',
    allResults: '[All Results]',
    availability: '[Availability]',
    change: '[Change]',
    changeOptions: '[Change options]',
    chatLive: '[Chat live]',
    chatUnavailable: '[Chat unavailable]',
    clearAll: '[Clear all]',
    clickTapZoom: '[Click or tap image to zoom in]',
    close: '[Close]',
    closeSection: '[Close this section]',
    country: '[3M United States]',
    currentProduct: '[Current product]',
    customerReviews: '[Customer reviews]',
    details: '[Details]',
    dimensionsClassifications: '[Dimensions and Classifications ]',
    disclaimerClosing:
        '[Please be aware that this information (including the original and the subsequent reply) may be transferred to a server located in the U.S. for metrics and storage. If you do not consent to this use of your personal information, please do not use the Contact Us system.]',
    disclaimerLinkText: '[Internet Privacy Policy]',
    disclaimerOpening:
        '[The information you provide on this Contact Us form will be used to respond to your request and as further described in our]',
    disclaimerPunctuation: '[.]',
    disclaimerURL: '[https://www.3m.com/3M/en_US/company-us/privacy-policy/]',
    discontinued: '[Discontinued]',
    done: '[Done]',
    dragSpin: '[Drag to spin]',
    emailAddress: '[Email address]',
    emailAddressFailure: '[Email addresses do not match]',
    emailAddressRetype: '[Retype your email address]',
    emailRequired: '[Enter your email address]',
    faq: '[Frequently asked questions]',
    firstName: '[First name (optional)]',
    frequentBoughtTogether: '[Frequently bought together]',
    frequentlyViewedResources: '[Frequently viewed resources]',
    helpMakeBetter: '[Help us make our site better]',
    highlights: '[Highlights]',
    hoverImageZoom: '[Hover over image to zoom in]',
    invalidEmailAddress: '[Invalid email address]',
    lastName: '[Last name (optional)]',
    learnWTB: '[Learn where to buy]',
    legalDisclaimer: '[Legal disclaimer]',
    less: '[Less]',
    loadMore: '[Load more]',
    loveToHearFeedback:
        "[We'd love to hear from you. Please leave us some feedback as we are always trying to improve our experiences. ]",
    matching: '[matching]',
    message: '[Message]',
    messageDetails: '[Please be as detailed as possible:]',
    messageRequired: '[Please provide a message.]',
    messageUsHeader: '[Message us]',
    messageUsIntro:
        '[We welcome your questions and comments about 3M Products and only use the information you provide to respond to your message.]',
    more: '[More]',
    next: '[Next]',
    notApplicable: '[Not applicable]',
    nsn: '[NSN]',
    of: '[of]',
    open: '[Open]',
    options: '[options]',
    optionsAvailable: '[Options available]',
    pageTop: '[Top]',
    partNumber: '[Part Number]',
    play: '[Play]',
    plusXMore: '[+# more]',
    price: '[Price]',
    priceList: '[Price List]',
    product: '[product]',
    productNameID: '[Product Name and IDs]',
    productNumber: '[3M Product Number]',
    productOptions: '[Product options]',
    products: '[Products]',
    recentlyViewed: '[Recently viewed]',
    relatedProducts: '[Related Products]',
    replacedBy: '[Replaced by]',
    requiredProductsZone: '[Required Products]',
    resources: '[Resources]',
    reviews: '[Reviews]',
    safetyDataSheet: '[Safety Data Sheet]',
    selectOptionsTooltip: '[Select product options ]',
    selectProductOptionsForIDs: '[Select product options for IDs]',
    sendFeedback: '[Send feedback]',
    showMore: '[Show more]',
    showingOfProducts: '[Showing X of X products]',
    specifications: '[Specifications]',
    specificationsAlternate: '[Typical properties]',
    stockNumber: '[Stock #]',
    submit: '[Submit]',
    suggestedApplications: '[Suggested applications]',
    support: '[Support]',
    supportDescription:
        '[Need help finding the right product for your project? Contact us if you need product, technical or application advice or want to collaborate with a 3M technical specialist {#}]',
    supportTitle: '[Connect with us. We’re here to help.]',
    techDataSheet: '[Tech Data Sheet]',
    thankYouHeader: '[Thank you for contacting 3M]',
    thankYouMessage:
        '[Our Goal is to respond to your request within 24 hours. Some questions, however, may need further investigation to provide you with appropriate response.]',
    threeSixtySpin: '[360]',
    upc: '[UPC]',
    userInstructions: '[User Instructions]',
    viewAllProductOptions: '[View all X product options]',
    viewAllResources: '[View all resources]',
    viewBuyingOptions: '[View buying options]',
    viewLegalDisclaimer: '[View legal disclaimer]',
    viewMoreDetails: '[View more details]',
    whereToBuy: '[Where to buy]',
    wtbUnavailable: '[No distributors available'
}
