import React, { useContext } from 'react'
import { Translations } from '../services/TranslationService'
import { useIsDesktop } from '../services/UtilityService'
import { trackPDPData } from '../services/TealiumService'
import { StateContext } from '../services/StateService'
import Thumbnail from './Thumbnail'

const MediaGalleryThumbnails = ({
    list,
    className,
    openOverlay,
    magic360media,
    isInnerGallery
}) => {
    const { mediaMoreCount, dispatch, activeMedia } = useContext(StateContext)

    const translations = useContext(Translations)
    const isDesktop = useIsDesktop()

    const showMore = (position) =>
        mediaMoreCount > 0 && position === list.length - 1 && isDesktop

    const handleClick = (index) => {
        if (activeMedia?.index !== index) {
            dispatch({
                type: 'pdp_set_active_media',
                payload: index
            })
        }
        if (!isInnerGallery && showMore(index)) {
            openOverlay()
            trackPDPData(
                'PDP Buying Zone Media Gallery Gallery List Button Click: show more',
                'Engagement Event'
            )
        }
    }

    const getClassName = (index) => {
        let className = ''
        className += activeMedia?.index === index ? 'active ' : ''
        if (!isInnerGallery) {
            className += showMore(index) ? 'sps2-pdp_gallery--list-btn_loadMore' : ''
        }
        return className
    }

    const showMoreLabel = translations?.plusXMore.replace(
        '#',
        Number.isInteger(mediaMoreCount) ? mediaMoreCount : ''
    )

    return (
        <div className={className}>
            {list?.map((media, i) => {
                return (
                    <button
                        key={`${i}_listbtn`}
                        className={`sps2-pdp_gallery--list-btn ${getClassName(i)}`}
                        onClick={() => handleClick(i)}
                    >
                        <Thumbnail
                            mediaElement={media}
                            magic360media={magic360media}
                            showMore={showMore(i)}
                            showMoreLabel={isInnerGallery ? '' : showMoreLabel}
                            index={i}
                        />
                    </button>
                )
            })}
        </div>
    )
}

export default MediaGalleryThumbnails
