import React from 'react'

const GridContainer = ({ title, content, containerClass, sectionClass }) => {
    if (!content) return null
    return (
        <div className={`sps2-pdp_details--white_container ${containerClass}`}>
            <div className={sectionClass}>
                <h5 className="mds-margin_large--bottom mds-font_header--5">{title}</h5>
                <ul className="sps2-pdp_details--highlights_list">
                    {content?.map((item, i) => (
                        <li
                            key={i}
                            className="sps2-pdp_details--highlights_item mds-font_paragraph"
                        >
                            {item.replace(/\.$/, '')}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default GridContainer
