import React, { useContext, useState } from 'react'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { Translations } from '../services/TranslationService.jsx'

import {
    fancyText,
    formatBytes,
    formatMIME,
    removeEmphasis,
    removeUnderscore
} from '../services/UtilityService.jsx'
import {
    REGULATORY_CONTENT_LENGTH,
    REGULATORY_TITLE_LENGTH
} from '../constants/constants.js'
import '../css/RegulatoryBlock.scss'
import { getTealiumObj } from '../services/TealiumService.jsx'

/**
 * RegulatoryBlock component takes product parameters and returns a customized ContentBox component
 * @param {string} contentType name of the product
 * @param {string} mimeType string with the location of the image file
 * @param {string} dmrId alternative text for Accesibility reasons
 * @param {string} title name of the product
 * @param {string} url where to link the click action
 * @param {string} fileSize information to be displayed if available
 * @param {string} content content to be displayed on this block.
 * @param {string} className for styling purposes
 * @param {string} onClickTracker for information purposes
 * @returns {component} returns the BazaarVoice set up according the locale.
 */
const RegulatoryBlock = ({
    contentType,
    mimeType,
    dmrId,
    title,
    url,
    fileSize,
    content,
    className,
    onClickTracker
}) => {
    const { documentNumber } = useContext(Translations)
    const TealiumEvent = () => {
        TealiumTag(getTealiumObj('snaps-regulatory Click: ' + removeEmphasis(title)))
    }

    const noZeroFileChecker = (fileSize) => {
        if (fileSize === 0) {
            return ''
        } else {
            return `, ${formatBytes(fileSize)}`
        }
    }

    const [printFileSize, setPrintFileSize] = useState(noZeroFileChecker(fileSize))

    return (
        // Not sure if we will need contentType in the future but saving it just in case
        <div className={`sps2-regulatory ${className ?? ''}`} data-type={contentType}>
            <h3 className="sps2-regulatory_title" title={removeEmphasis(title)}>
                <i className="sps2-regulatory_icon MMM--icn MMM--icn_report"></i>
                <a
                    className="mds-link mds-link_product"
                    href={url}
                    onClick={() => {
                        TealiumEvent()
                        onClickTracker()
                    }}
                    dangerouslySetInnerHTML={{
                        __html: fancyText(title, REGULATORY_TITLE_LENGTH)
                    }}
                />
            </h3>
            <span className="sps2-regulatory_metadata">
                <h4 className="sps2-regulatory_number mds-font_paragraph">
                    {documentNumber + ': ' + dmrId}
                </h4>
                <h4 className="sps2-regulatory_size mds-font_body">
                    {`(${formatMIME(mimeType)}${printFileSize})`}
                </h4>
            </span>
            <p
                title={removeEmphasis(content)}
                className="sps2-regulatory_content mds-font_intro--2"
                dangerouslySetInnerHTML={{
                    __html: fancyText(
                        removeUnderscore(content),
                        REGULATORY_CONTENT_LENGTH
                    )
                }}
            />
        </div>
    )
}

export default RegulatoryBlock
