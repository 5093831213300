import React from 'react'
import SpecificationsCell from './SpecificationsCell'

export const col1Mobile = ({ value }) => (
    <>
        <SpecificationsCell
            className="sps2-pdp_specifications--table_label"
            value={value?.label}
        />
        <SpecificationsCell
            className="sps2-pdp_specifications--table_values"
            value={value?.values}
        />
    </>
)

export const col1Desktop = ({ value }) => (
    <SpecificationsCell className="sps2-pdp_specifications--table_label" value={value} />
)
export const col2Desktop = ({ value }) => (
    <SpecificationsCell
        className="sps2-pdp_specifications--table_values sps2-pdp_wrap--700"
        value={value}
    />
)
