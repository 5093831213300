import React, { useEffect, useRef, useState } from 'react'

// Ignoring Checkbox component from coverage as we'll want to update with MDS once completed
/* istanbul ignore next */
const Checkbox = ({ uniqueID, fields, onClick, autoFocus }) => {
    const {
        name,
        fieldName,
        type,
        required,
        value,
        disabled,
        className,
        size,
        checked,
        labelClass,
    } = fields
    const [isChecked, setIsChecked] = useState(
        checked ?? false
    )
    const thisElement = useRef()

    useEffect(() => {
        if (autoFocus) {
            thisElement.current.focus()
        }
    }, [])

    const containerClasses =
        (className ? className : '') +
        ' ' +
        (size ? 'mds-form_checkbox--' + size : 'mds-form_checkbox--x-small')

    const checkboxClick = () => {
        setIsChecked(!isChecked)
    }

    return (
        <div className={`mds-form_checkbox ${containerClasses}`}>
            <input
                type="checkbox"
                name={name}
                placeholder=" "
                id={uniqueID}
                checked={checked} // Using checked makes this a controlled component
                required={required}
                aria-labelledby={`${uniqueID}-Label ${uniqueID}-requiredLabel`}
                defaultValue={value}
                disabled={disabled}
                onClick={onClick}
                onKeyPress={(e) => {
                    const code = e.keyCode || e.which
                    if (code === 13) {
                        //13 is the enter keycode
                        onClick(e)
                    }
                }}
                onChange={() => {
                    checkboxClick()
                }}
                ref={thisElement}
            ></input>
            <span
                className={
                    type
                        ? 'mds-form_checkboxBox mds-form_checkboxBox--' + type
                        : 'mds-form_checkboxBox'
                }
            ></span>
            <label
                htmlFor={uniqueID}
                className={`mds-form_checkboxLabel ${
                    labelClass !== undefined ? labelClass : ''
                }`}
                id={`${uniqueID}-Label`}
            >{`${fieldName}${required ? '*' : ''}`}</label>
        </div>
    )
}

export default Checkbox
