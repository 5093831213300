import React, { useContext } from 'react'
import { StateContext } from '../services/StateService'
import '../css/RichContentSection.scss'
/* istanbul ignore next */
/**
 * Component to render multiple rich content in the PCP page
 * @param {Array} richContentList array of html WCM content.
 * @returns returns all rich content data in a list.
 */
const RichContentSection = ({ richContentList }) => {
    const { highlightRichContent } = useContext(StateContext)
    return (
        <div className="sps2-richContentContainer">
            {richContentList &&
                richContentList.map((richContent, key) => (
                    <>
                        {highlightRichContent && (
                            <h1 className="sps2-richContent_highlight--oid">
                                {richContent?.oid}
                            </h1>
                        )}
                        <div
                            data-oid={richContent?.oid || ''}
                            className={`sps2-richContentContainer_child ${
                                highlightRichContent ? 'sps2-richContent_highlight' : ''
                            }`}
                            key={key}
                            dangerouslySetInnerHTML={{ __html: richContent.html }}
                        />
                    </>
                ))}
        </div>
    )
}

export default RichContentSection
