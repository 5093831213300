import React, { useContext } from 'react'
import { Translations } from '../services/TranslationService.jsx'
import Button from '@3mcom/mds-library/dist/node/Button'
import '../css/Gallery.scss'
import { getTealiumObj } from '../services/TealiumService.jsx'
import { StateContext } from '../services/StateService.jsx'

/**
 * Gallery is the component that renders products for the PCP
 * @param {function} loadMore The function to call when Load More button is clicked
 * @param {number} total The total number of items available
 * @param {Number} gallerySize The number of items in the gallery
 * @param {ReactChildren} children A list of items to show in the gallery
 */
const Gallery = ({
    gallerySize,
    loadMore,
    total,
    children,
    galleryRef,
    addHiddenNextLink = false
}) => {
    const { showMore } = useContext(Translations)
    const { endpoints } = useContext(StateContext)

    // Create mobile left hand navigation state
    const galleryContainer = (
        <div className="sps2-gallery_container" ref={galleryRef} role="main">
            <div className="sps2-gallery">{children}</div>
            {total > gallerySize && (
                <div className="sps2-gallery_showMore">
                    <Button
                        buttonType="tertiary"
                        size="medium"
                        onClick={loadMore}
                        tealiumObj={getTealiumObj(
                            'snaps-gallery_showMore Click: ' + gallerySize
                        )}
                    >
                        {showMore}
                    </Button>
                    {addHiddenNextLink && <a href={endpoints?.pcpShowMoreHidden} />}
                </div>
            )}
        </div>
    )
    return galleryContainer
}

export default Gallery
