import React, { useContext } from 'react'
import BazaarVoice from '../../components/BazaarVoice'
import {
    QUESTIONS,
    REVIEWS,
    REVIEW_HIGHLIGHTS
} from '../../constants/bazaarVoiceConstants'
import { Translations } from '../../services/TranslationService'
import '../../css/Reviews.scss'

const Reviews = ({ reviewsRef, productDetails }) => {
    const translations = useContext(Translations)
    const { reviews } = translations

    return (
        <div ref={reviewsRef} className="sps2-pdp_section sps2-pdp_reviews">
            <h3 className="mds-font_header--3" tabIndex="-1">{reviews}</h3>
            <BazaarVoice
                stockNumber={productDetails.stockNumber}
                bazaarVoiceClientName={productDetails.bazaarVoiceClientName}
                bazaarVoiceDisplayZone={productDetails.bazaarVoiceDisplayZone}
                bazaarVoiceProductID={productDetails.bazaarVoiceProductID}
                bazaarVoiceShow={REVIEW_HIGHLIGHTS}
            />
            <BazaarVoice
                stockNumber={productDetails.stockNumber}
                bazaarVoiceClientName={productDetails.bazaarVoiceClientName}
                bazaarVoiceDisplayZone={productDetails.bazaarVoiceDisplayZone}
                bazaarVoiceProductID={productDetails.bazaarVoiceProductID}
                bazaarVoiceShow={REVIEWS}
            />
            <BazaarVoice
                stockNumber={productDetails.stockNumber}
                bazaarVoiceClientName={productDetails.bazaarVoiceClientName}
                bazaarVoiceDisplayZone={productDetails.bazaarVoiceDisplayZone}
                bazaarVoiceProductID={productDetails.bazaarVoiceProductID}
                bazaarVoiceShow={QUESTIONS}
            />
        </div>
    )
}

export default Reviews
