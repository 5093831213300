import React, { useContext } from 'react'
import { Translations } from '../../services/TranslationService.jsx'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import ProductBlock from '../../components/ProductBlock.jsx'
import ResourceBlock from '../../components/ResourceBlock.jsx'
import RegulatoryBlock from '../../components/RegulatoryBlock.jsx'
import { getAltID } from '../../services/ESService.jsx'
import InformationBlock from '../../components/InformationBlock.jsx'
import { getTealiumObj } from '../../services/TealiumService.jsx'
import { postSignalData } from '../../services/AxiosService.jsx'
import {
    INDUSTRY_APPLICATIONS_TAB,
    MEDIALIBRARY_SELECT,
    PRODUCTS_TAB,
    SEARCH_SELECT,
    REGULATORY_SELECT,
    REGULATORY_TAB,
    RESOURCES_TAB,
    WEBCONTENT_SELECT
} from '../../constants/constants.js'

/**
 * WebContentsSection returns an information block, these are used on the "ResultSection" as children to provide the how many items to display
 * @param {array} items is the state with the items
 * @param {string} analyticsUrl analytics url located here.
 * @returns {component} WebContentsSection returns an information block which is another component, Industy Applications.
 */
const WebContentsSection = ({ items, analyticsUrl, queryId }) =>
    items.map((item) => {
        return (
            <InformationBlock
                key={item.position}
                {...item}
                onClickTracker={() =>
                    postSignalData(
                        analyticsUrl,
                        item.position,
                        WEBCONTENT_SELECT,
                        undefined,
                        queryId
                    )
                }
            />
        )
    })

/**
 * ProductSection returns an information block, these are used on the "ResultSection" as children to provide the how many items to display
 * @param {array} items products to load on the page.
 * @param {string} analyticsUrl analytics url located here.
 * @returns {component} ProductSection returns an information block which is another component.
 */
const ProductsSection = ({ items, analyticsUrl, queryId }) => (
    <div className="sps2-content_container">
        {items.map((item) => {
            const altId = getAltID(item)
            return (
                <ProductBlock
                    key={item.position}
                    className="mds-prodBar_item"
                    altId={altId}
                    {...item}
                    product={item}
                    onClickTracker={() =>
                        postSignalData(
                            analyticsUrl,
                            item.position,
                            SEARCH_SELECT,
                            item.stockNumber,
                            queryId
                        )
                    }
                />
            )
        })}
    </div>
)

/**
 * ResourcesSection returns an information block component, these are used on the "ResultSection" as children to provide the how many items to display
 * @param {array}resourcesData the items are loaded here and later mapped.
 * @param {string}analyticsUrl analytics url located here
 * @returns {component} returns a ResourceBlock which is another component.
 */
const ResourcesSection = ({ resourcesData, analyticsUrl, queryId }) => (
    <div className="sps2-content_container">
        {resourcesData.map((item) => {
            return (
                <ResourceBlock
                    key={item.position}
                    className="mds-prodBar_item"
                    item={item}
                    {...item}
                    onClickTracker={() =>
                        postSignalData(
                            analyticsUrl,
                            item.position,
                            MEDIALIBRARY_SELECT,
                            undefined,
                            queryId
                        )
                    }
                />
            )
        })}
    </div>
)

/**
 * RegulatorySection returns an information regulatory block, these are used on the "ResultSection" as children to provide the how many items to display
 * @param {array}regulatoryData content of the page here
 * @param {string}analyticsUrl analytics url located here
 * @returns {component} RegulatorySection returns an information block which is another component.
 */
const RegulatorySection = ({ regulatoryData, analyticsUrl, queryId }) => (
    <div className="sps2-content_container">
        {regulatoryData.map((item, index) => {
            return (
                <RegulatoryBlock
                    key={item.position}
                    className="mds-prodBar_item"
                    {...item}
                    onClickTracker={() =>
                        postSignalData(
                            analyticsUrl,
                            item.position,
                            REGULATORY_SELECT,
                            undefined,
                            queryId
                        )
                    }
                />
            )
        })}
    </div>
)

/**
 * This is the title of each one of the sections within, all results page, gets render on the return of this component, this in order to not having to duplicated html unnecesary.
 * @param {string}title title of each section under "All Results"
 * @param {string}linkName text for the link that takes user to the actual current tab.
 * @param {array}linkAction where this is going to link
 * @param {component}children This tells the section what section is going to be rendered by sending the component and the amount of elements to display.
 */
const ResultSection = ({ title, linkName, linkAction, children }) => (
    <div className="sps2-results_section">
        <div className="sps2-results_section--header">
            <p className="mds-font_header--4 sps2-results_title">{title}</p>
            <button className="mds-link_category mds-link--button" onClick={linkAction}>
                {linkName}
            </button>
        </div>
        {children}
    </div>
)

/**
 * This will render the content for the "All Results" tab... These items come from the endpoint, which is loaded via AJAX.
 * @param {array} items products data.
 * @param {array} regulatoryData  regulatory data
 * @param {array} resourcesData resources data
 * @param {object} setActiveTab in order to set the display initial status for the Tab.
 * @param {array} webContentData Industry Applications data
 * @param {string} analyticsUrl provided for SEO reasons.
 * @returns {component} The content for the "All Results" tab
 */
const AllResultsView = ({ allTabData, setActiveTab, analyticsUrl, queryId }) => {
    const {
        capProducts,
        industryApplications,
        regulatory,
        resources,
        allResultsProducts,
        allResultsIndustryApplications,
        allResultsRegulatory,
        allResultsResources
    } = useContext(Translations)

    return (
        <div className="sps2-results">
            {allTabData.items?.length > 0 && (
                <ResultSection
                    title={capProducts}
                    linkName={allResultsProducts}
                    linkAction={() => {
                        setActiveTab(PRODUCTS_TAB)
                        TealiumTag(getTealiumObj('mds-tabs_jumpTo Click: Products'))
                    }}
                >
                    <ProductsSection
                        items={allTabData.items.slice(0, 8)}
                        analyticsUrl={analyticsUrl}
                        queryId={queryId}
                    />
                </ResultSection>
            )}
            {allTabData.webContent && (
                <ResultSection
                    title={industryApplications}
                    linkName={allResultsIndustryApplications}
                    linkAction={() => {
                        setActiveTab(INDUSTRY_APPLICATIONS_TAB)
                        TealiumTag(
                            getTealiumObj('mds-tabs_jumpTo Click: Industry Applications')
                        )
                    }}
                >
                    <WebContentsSection
                        items={allTabData.webContent.slice(0, 6)}
                        analyticsUrl={analyticsUrl}
                        queryId={queryId}
                    />
                </ResultSection>
            )}
            {allTabData.regulatory && (
                <ResultSection
                    title={regulatory}
                    linkName={allResultsRegulatory}
                    linkAction={() => {
                        setActiveTab(REGULATORY_TAB)
                        TealiumTag(getTealiumObj('mds-tabs_jumpTo Click: Regulatory'))
                    }}
                >
                    <RegulatorySection
                        regulatoryData={allTabData.regulatory.slice(0, 8)}
                        analyticsUrl={analyticsUrl}
                        queryId={queryId}
                    />
                </ResultSection>
            )}
            {allTabData.resources && (
                <ResultSection
                    title={resources}
                    linkName={allResultsResources}
                    linkAction={() => {
                        setActiveTab(RESOURCES_TAB)
                        TealiumTag(getTealiumObj('mds-tabs_jumpTo Click: Resources'))
                    }}
                >
                    <ResourcesSection
                        resourcesData={allTabData.resources.slice(0, 8)}
                        analyticsUrl={analyticsUrl}
                        queryId={queryId}
                    />
                </ResultSection>
            )}
        </div>
    )
}

export { AllResultsView as default }
