import React, { forwardRef, useContext } from 'react'
import { NavigationContext } from '../NavigationContext'
import { filterSelection } from '../../../services/SelectionService.jsx'

/**
 * SingleSelection
 * @param {ref} ref Reference to be assigned to the SingleSelection (for focus handling & accessibility)
 * @param {string} api the api that can be used to dynamically filter data per selection (to be used with dynamicLoad)
 * @param {url} the URL to update the window to / send user's to upon filtering
 * @param {function} onClick (optional) function to be called when user makes selection
 * @param {string} name the name of the selection
 * @returns a link with the selection URL and styling or a button that will dynamically update the data depending on if dynamicLoad is enabled for the navigation
 */
const SingleSelection = forwardRef((props, ref) => {
    const { api, url, onClick = () => null, name } = props
    const { dynamicLoad, endpoints, dispatch } = useContext(NavigationContext)

    // let's check if the page is SERP -> dynamicLoad = true.
    if (dynamicLoad) {
        return (
            <button
                className="mds-link_secondary"
                onClick={() => {
                    onClick()
                    filterSelection(api, url, endpoints, dispatch)
                }}
                ref={ref}
            >
                {name}
            </button>
        )
    }
    // if not, it has to be a PCP page.
    return (
        <a
            href={url}
            className="mds-link_secondary"
            ref={ref}
            onClick={() => {
                onClick()
                dispatch({ type: 'set_loading_status', payload: true })
            }}
        >
            {name}
        </a>
    )
})
SingleSelection.displayName = 'Single Selection'

export default SingleSelection
