import React, { useContext } from 'react'
import MainGalleryElement from './MainGalleryElement'
import MediaGalleryThumbnails from './MediaGalleryThumbnails'
import MobileMediaGallery from '../fragments/DetailPage/MobileMediaGallery'
import { StateContext } from '../services/StateService'
import '../css/OuterGallery.scss'

const OuterGallery = ({
    media,
    openOverlay,
    startMagicZoom,
    magic360media,
    magicZoomState,
    outerSplideRef,
    outerThumbsRef,
    isDesktop
}) => {
    const { activeMedia } = useContext(StateContext)
    const current360Data = magic360media?.find(
        (element) => element.position === activeMedia?.index
    )

    return (
        <div className="sps2-pdp_outerGallery--container">
            {isDesktop ? (
                <>
                    <MainGalleryElement
                        className="sps2-pdp_outerGallery--active_container"
                        onClick={openOverlay}
                        startMagicZoom={startMagicZoom}
                        isInnerGallery={false}
                        magicZoomState={magicZoomState}
                        current360Data={current360Data}
                        modePlaylist={false}
                    />
                    <MediaGalleryThumbnails
                        list={media}
                        className="sps2-pdp_outerGallery--list_container"
                        openOverlay={openOverlay}
                        magic360media={magic360media}
                        isInnerGallery={false}
                    />
                </>
            ) : (
                <MobileMediaGallery
                    isInnerGallery={false}
                    modePlaylist={false}
                    openOverlay={openOverlay}
                    magic360media={magic360media}
                    splideRef={outerSplideRef}
                    thumbnailsRef={outerThumbsRef}
                />
            )}
        </div>
    )
}

export default OuterGallery
