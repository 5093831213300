import React from 'react'

const Benefits = ({ benefits }) => {
    if (benefits?.length === 0) return null

    return (
        <div className="sps2-pdp_description--benefits">
            {benefits?.map((benefit, index) => (
                <p key={index} className="mds-font_body sps2-pdp_description--benefit">
                    {benefit}
                </p>
            ))}
        </div>
    )
}

export default Benefits
