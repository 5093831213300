import React, { useContext, useState, useEffect, useRef } from 'react'
import Button from '@3mcom/mds-library/dist/node/Button'
import ScreenTip from '@3mcom/mds-library/dist/node/ScreenTip'
import Link from '@3mcom/mds-library/dist/node/Link'
import RichContent from '../../components/RichContent'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService.jsx'
import { trackPDPData } from '../../services/TealiumService'
import { scrollToSection } from '../../services/UtilityService'

import {
    primaryButtonStyle,
    primaryButtonStyleHover,
    secondaryButtonStyle,
    secondaryButtonStyleHover,
    tertiaryButtonStyle,
    tertiaryButtonStyleHover
} from '../../constants/MDSButtonStyle'

/* istanbul ignore next */
const CTASection = ({
    productOptionsRef,
    prodOptButtonRef,
    supportRef,
    supportSecondaryRef,
    supportTertiaryRef
}) => {
    const {
        ctaInfo,
        isParent,
        replacement,
        moreOptions,
        productOptions,
        richContent,
        postPurchaseZone,
        pdpPageOffset,
        selectedOptions,
        highlightSelectors,
        focusViewProductOptions,
        dispatch
    } = useContext(StateContext)
    const translations = useContext(Translations)
    const { whereToBuy, selectOptionsTooltip, viewBuyingOptions } = translations
    const {
        leadFormLabel,
        leadFormLabel2,
        contactAcquisitionLabel,
        callToActionLabel: ctaLabel,
        callToActionUrl: ctaUrl,
        serviceLocatorLabel,
        serviceLocatorRank = '3',
        productAdvisorLabel,
        productAdvisorLink
    } = ctaInfo || {}
    const { leadForm, leadForm2, contactAcquisition, postPurchaseForm } =
        richContent || {}

    /**
     * Only 4 buttons should be displayed on the PDP page and must follow the next order.
     * When each button is being created they will get their classNames from this array.
     * Mkplace and WTB are always primary
     */
    const buttonsPrioritization = [
        'mds-button mds-button_primary mds-button_primary--large sps2-pdp_purchase--cta-section-button',
        'mds-button mds-button_secondary mds-button_secondary--large sps2-pdp_purchase--cta-section-button',
        'mds-button mds-button_tertiary mds-button_tertiary--large sps2-pdp_purchase--cta-section-button',
        'mds-button mds-button_tertiary mds-button_tertiary--large sps2-pdp_purchase--cta-section-button'
    ]

    const [isLeadLightboxHovered, setIsLeadLightboxHovered] = useState(false)
    const [isLead2LightboxHovered, setIsLead2LightboxHovered] = useState(false)
    const [isContactLightboxHovered, setIsContactLightboxHovered] = useState(false)

    const screenTipRef = useRef(null)

    /** if screentip was previously open, toggle the screentip after a selection was made */
    useEffect(() => {
        if (
            selectedOptions?.totalSelected !== moreOptions?.totalSelected &&
            highlightSelectors
        ) {
            screenTipRef?.current?.toggle()
            dispatch({
                type: 'pdp_update_UI_options',
                payload: {
                    highlightSelectors: false
                }
            })
        }
    }, [selectedOptions?.totalSelected])

    /**
     * This function creates the correct styles for Lightbox buttons according to the established
     * prioritization because a 3m_theme_assets script removes theirs
     * @param {string} buttonName
     * @returns an inline styling object
     */
    const getButtonStyle = (buttonName) => {
        const buttonClass = buttonsPrioritization?.shift()
        switch (buttonName) {
            case 'leadLightboxButton':
                return buttonStyle(isLeadLightboxHovered, buttonClass)
            case 'lead2LightboxButton':
                return buttonStyle(isLead2LightboxHovered, buttonClass)
            case 'contactLightboxButton':
                return buttonStyle(isContactLightboxHovered, buttonClass)
        }
    }

    const buttonStyle = (isHover, buttonClass) => {
        if (buttonClass.includes('primary')) {
            return isHover ? primaryButtonStyleHover : primaryButtonStyle
        }
        if (buttonClass.includes('secondary')) {
            return isHover ? secondaryButtonStyleHover : secondaryButtonStyle
        }
        if (buttonClass.includes('tertiary')) {
            return isHover ? tertiaryButtonStyleHover : tertiaryButtonStyle
        }
    }

    const checkScreenTip =
        isParent && moreOptions?.options?.length > 1 && !replacement.discontinued

    const checkWTBScreenTip =
        checkScreenTip &&
        selectedOptions?.totalSelected > 1 &&
        moreOptions?.childHasCcSubId &&
        (moreOptions?.displayTable
            ? true
            : selectedOptions?.totalSelected === moreOptions?.totalSelected)

    // Logic carried over from SNAPS1, when the contact acquisiton is lightbox then the CTA won't be present
    // because in most cases they had the same label (DATA ISSUE)
    const showCTALabel = ctaLabel && ctaUrl && !contactAcquisition?.isContactLightbox

    let postPurchaseLabel
    if (postPurchaseZone) {
        postPurchaseLabel =
            postPurchaseZone.postPruchaseFormTitle ||
            postPurchaseZone.postPurchaseFormLabel
    }

    const onClickScreenTip = () => {
        if (productOptions?.length > 0) {
            productOptionsRef?.current?.focus()
            scrollToSection(productOptionsRef, pdpPageOffset)
        } else if (selectedOptions?.totalSelected === moreOptions?.totalSelected) {
            dispatch({
                type: 'pdp_update_UI_options',
                payload: {
                    highlightSelectors: !highlightSelectors
                }
            })
            screenTipRef.current.toggle()
        } else if (selectedOptions?.totalSelected !== moreOptions?.totalSelected) {
            if (!focusViewProductOptions) {
                prodOptButtonRef?.current?.focus()
            }
            dispatch({
                type: 'pdp_update_UI_options',
                payload: {
                    focusViewProductOptions: !focusViewProductOptions
                }
            })
        }
    }

    const isValidUrl = (string) => {
        try {
            const newUrl = new URL(string)
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
        } catch (err) {
            return false
        }
    }

    const ctaURLValidation = (url) => {
        if (isValidUrl(url)) {
            return url
        } else {
            return `https://${ctaUrl}`
        }
    }

    return (
        <div className="sps2-pdp_purchase--cta-section">
            {/* ScreenTip for Where To Buy */}
            {checkWTBScreenTip && buttonsPrioritization?.shift() && (
                <ScreenTip
                    ref={screenTipRef}
                    buttonName={whereToBuy}
                    content={selectOptionsTooltip}
                    disableAutoToggle={true}
                    onClick={onClickScreenTip}
                    tealiumTracking={true}
                    btnTealiumObj={{
                        event_name: `PDP Buying Zone Purchase Options Button Click: ${whereToBuy}`,
                        event_type: 'Engagement Event',
                        experience_type: 'SNAPS2',
                        wtb_click: 1
                    }}
                />
            )}

            {/* Service Locator with Rank Value = 1 */}
            {serviceLocatorRank === '1' && (
                <a
                    className={`cc-sf-button ${buttonsPrioritization.shift()}`}
                    href="#servicelocator"
                >
                    <span>{serviceLocatorLabel}</span>
                </a>
            )}

            {/* Lead Form 1 */}
            {leadFormLabel && leadForm && (
                <>
                    {leadForm?.isLeadLightbox && (
                        <RichContent richContentObj={leadForm} />
                    )}
                    <button
                        id={'leadLightboxButton'}
                        style={getButtonStyle('leadLightboxButton')}
                        onMouseEnter={() => setIsLeadLightboxHovered(true)}
                        onMouseLeave={() => setIsLeadLightboxHovered(false)}
                        onClick={() => {
                            !leadForm?.isLeadLightbox &&
                                scrollToSection(supportRef, pdpPageOffset)
                            trackPDPData(
                                `PDP Buying Zone Purchase Options Button Click: ${leadFormLabel}`,
                                'Engagement Event'
                            )
                        }}
                    >
                        <span>{leadFormLabel}</span>
                    </button>
                </>
            )}

            {/* Lead Form 2 */}
            {leadFormLabel2 && leadForm2 && (
                <>
                    {leadForm2?.isLead2Lightbox && (
                        <RichContent richContentObj={leadForm2} />
                    )}
                    <button
                        id={'lead2LightboxButton'}
                        style={getButtonStyle('lead2LightboxButton')}
                        onMouseEnter={() => setIsLead2LightboxHovered(true)}
                        onMouseLeave={() => setIsLead2LightboxHovered(false)}
                        onClick={() => {
                            !leadForm2?.isLead2Lightbox &&
                                scrollToSection(supportTertiaryRef, pdpPageOffset)
                            trackPDPData(
                                `PDP Buying Zone Purchase Options Button Click: ${leadFormLabel2}`,
                                'Engagement Event'
                            )
                        }}
                    >
                        <span>{leadFormLabel2}</span>
                    </button>
                </>
            )}

            {/* Service Locator with Rank Value = 2 */}
            {serviceLocatorRank === '2' && buttonsPrioritization?.length > 0 && (
                <a
                    className={`cc-sf-button ${buttonsPrioritization.shift()}`}
                    href="#servicelocator"
                >
                    <span>{serviceLocatorLabel}</span>
                </a>
            )}

            {/* Contact Acquisition Form */}
            {contactAcquisitionLabel && contactAcquisition && (
                <>
                    {contactAcquisition?.isContactLightbox && (
                        <RichContent richContentObj={contactAcquisition} />
                    )}
                    <button
                        id={'contactLightboxButton'}
                        style={getButtonStyle('contactLightboxButton')}
                        onMouseEnter={() => setIsContactLightboxHovered(true)}
                        onMouseLeave={() => setIsContactLightboxHovered(false)}
                        onClick={() => {
                            trackPDPData(
                                `PDP Buying Zone Purchase Options Button Click: ${contactAcquisitionLabel}`,
                                'Engagement Event'
                            )
                            !contactAcquisition?.isContactLightbox &&
                                scrollToSection(supportSecondaryRef, pdpPageOffset)
                        }}
                    >
                        <span>{contactAcquisitionLabel}</span>
                    </button>
                </>
            )}

            {/* Service Locator with Rank Value = 3 */}
            {serviceLocatorRank === '3' &&
                serviceLocatorLabel &&
                buttonsPrioritization?.length > 0 && (
                    <a
                        className={`cc-sf-button ${buttonsPrioritization.shift()}`}
                        href="#servicelocator"
                    >
                        <span>{serviceLocatorLabel}</span>
                    </a>
                )}

            {/* Call To Action */}
            {showCTALabel && buttonsPrioritization.length > 0 && (
                <a
                    className={buttonsPrioritization?.shift()}
                    href={ctaURLValidation(ctaUrl)}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                        trackPDPData(
                            `PDP Buying Zone Purchase Options Link Click: ${ctaLabel}`,
                            'Engagement Event'
                        )
                        !contactAcquisition?.isContactLightbox &&
                            scrollToSection(supportSecondaryRef, pdpPageOffset)
                    }}
                >
                    <span>{ctaLabel}</span>
                </a>
            )}

            {/* Post Purchase Form */}
            {postPurchaseForm && buttonsPrioritization.length > 0 && (
                <Button
                    className={buttonsPrioritization.shift()} // Removes and returns the first elemenet of the buttonsPrioritization array
                    onClick={() =>
                        scrollTo(
                            0,
                            document.querySelector('#sps2_post--purchase_zone').offsetTop
                        )
                    }
                    tealiumTracking={true}
                    tealiumObj={{
                        event_name: `PDP Buying Zone Purchase Options Button Click: ${postPurchaseLabel}`,
                        event_type: 'Engagement Event',
                        experience_type: 'SNAPS2',
                        wtb_click: 1
                    }}
                >
                    <span>{postPurchaseLabel}</span>
                </Button>
            )}

            {productAdvisorLink && (
                <Link
                    href={productAdvisorLink}
                    className="mds-link mds-link_primary sps2-pdp_purchase--cta-section-productAdvisorLink"
                >
                    <span>{productAdvisorLabel}</span>
                </Link>
            )}
        </div>
    )
}

export default CTASection
