import React, { useContext } from 'react'
import { Translations } from '../../../services/TranslationService.jsx'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { toRelativeURL } from '../../../services/URLService.jsx'
import { NavigationContext } from '../NavigationContext.js'
import { getTealiumObj } from '../../../services/TealiumService.jsx'
import { PRODUCTS_TAB } from '../../../constants/constants'
import {
    createFilterEndpoint,
    filterSelection,
    createHierarchyEndpoint
} from '../../../services/SelectionService.jsx'
import { v4 as uuidv4 } from 'uuid'

/**
 * Hierarchy Selection is the LHN filter hierarchy links and header for Categories and Industries filters
 * @param {string} type Type of hierarchy to be returned.  Currently only 'categories' and 'industries' are supported
 */
const HierarchySelection = ({ type = 'default' }) => {
    // Get the breadCrumb and breadBox data from state context
    const { breadCrumb, breadBox, dynamicLoad, endpoints, dispatch, currentTab } =
        useContext(NavigationContext)
    const { all } = useContext(Translations)

    // hierarchyLink returns the HTML for the hierarchy link
    const hierarchyLink = (name, url) => {
        const onClickEvent = () => {
            TealiumTag(getTealiumObj('sps2-filter_hierarchy Click: ' + name))
        }
        // let's check if the page is SERP -> dynamicLoad = true.
        if (dynamicLoad) {
            let endpoint = createFilterEndpoint(url, endpoints)
            if (currentTab === PRODUCTS_TAB) {
                endpoint = createHierarchyEndpoint(url, endpoints)
            }
            return (
                <li className="sps2-lhn_hierarchy--link" key={uuidv4()}>
                    <button
                        className="sps2-lhn_link"
                        onClick={() => {
                            onClickEvent()
                            filterSelection(endpoint, url, endpoints, dispatch)
                        }}
                    >
                        {`< ${name}`}
                    </button>
                </li>
            )
        }
        // if not, it has to be a PCP page.
        return (
            <li className="sps2-lhn_hierarchy--link" key={uuidv4()}>
                <a
                    className="sps2-lhn_link"
                    href={toRelativeURL(url)}
                    onClick={() => {
                        onClickEvent()
                        dispatch({ type: 'set_loading_status', payload: true })
                    }}
                >
                    {`< ${name}`}
                </a>
            </li>
        )
    }

    /**
     * hierarchyHeading returns the HTML for the heading of the current applied filter
     * @param {string} name the name to be displayed.
     */
    const hierarchyHeading = (name) => {
        return (
            <li className="sps2-lhn_hierarchy--heading" key={uuidv4()}>
                <p key={name}>{name}</p>
            </li>
        )
    }

    /**
     * Returns the HTML for hierarchy links and headings according to the breadBox type
     * @param {string} breadBoxType
     */
    const getHierarchyFromBreadboxes = (breadBoxType) => {
        // Filter the breadboxes by type. Eg. 'industry' , 'collections'
        const breadboxes = breadBox?.filter((breadBox) => breadBox.type === breadBoxType)
        // 'All' link that returns users to no filter selected
        const allLink =
            breadboxes?.length > 0 ? hierarchyLink(all, breadboxes[0].removeUrl) : null
        // Returns links/headings based on remaining breadboxes
        const hierarchyLinks = breadboxes?.map((breadBox, index) => {
            if (index === breadboxes.length - 1) {
                return hierarchyHeading(breadBox.value[breadBox.value.length - 1])
            } else {
                return hierarchyLink(
                    breadBox.value[breadBox.value.length - 1],
                    breadboxes[index + 1].removeUrl
                )
            }
        })
        return (
            <>
                {allLink}
                {hierarchyLinks}
            </>
        )
    }

    const typeOptions = {
        // Categories hierarchy will come from the breadcrumbs
        categories: () => {
            // Return category hierarchy after the 'All products' breadCrumb objects
            if (breadCrumb?.length > 1) {
                return breadCrumb.map((breadCrumb, index) => {
                    if (breadCrumb.removeUrl) {
                        return hierarchyLink(
                            index > 0 ? breadCrumb.value : all,
                            breadCrumb.removeUrl
                        )
                    } else {
                        return hierarchyHeading(breadCrumb.value)
                    }
                })
            } else {
                return null
            }
        },

        // Industries hierarchy will come from Industry breadboxes
        industries: () => {
            // Returns the hierarchy from Industry breadboxes
            return getHierarchyFromBreadboxes('industries')
        },

        // Branded collections hierarchy will come from collections breadboxes
        collections: () => {
            // Returns the hierarchy from branded collections breadboxes
            return getHierarchyFromBreadboxes('collections')
        },

        // Default will return null if not hierarchy links are required for the type option
        default: () => {
            return null
        }
    }

    return typeOptions[type]()
}

export default HierarchySelection
