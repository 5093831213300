export const SPECIFICATIONS = 'specifications'
export const DETAILS = 'details'
export const RESOURCES = 'resources'
export const RELATED_PRODUCTS = 'relatedProducts'
export const SUPPORT = 'support'
export const BOUGHT_TOGETHER = 'boughtTogether'
export const WINDOW_TOP = 'windowTop'
export const PAGE_OFFSET = 84 // Offset for scrollTo() when the stickyNav is visible
export const SCROLL_OFFSET = 88 // Offset for the scrolling position to activate the active buttons
export const NOT_VISIBLE_OFFSET = 131 // Offset for scrollTo() when the stickyNav is not visible
export const MMM_ID = '3M ID'
export const UPC = 'UPC'
export const MAGIC360_CODE = '40'
export const MORE_OPTIONS_PAGE_SIZE = 30
export const MESSAGE_US_ENDPOINT = '/L7/ebus/eForm/v1/submitMailForm' // This is an exception /L7/ reference that has been configured to point to Mulesoft
export const UTM_TERM = 'utm_term'
export const CAMPAIGN_TRACKING_CODE = 'campaign_tracking_code'
export const SF_CONFIG = {
    LIVE_AGENT_JS: 'https://d.la2-c2-ph2.salesforceliveagent.com/chat',
    ORG_ID: '00Do0000000KvQR'
}
export const SF_CONFIG_HC = {
    DEPLOYMENT_JS_URL:
        'https://c.la1-core1.sfdc-lywfpd.salesforceliveagent.com/content/g/js/60.0/deployment.js',
    LIVE_AGENT_JS: 'https://d.la1-core1.sfdc-lywfpd.salesforceliveagent.com/chat',
    ORG_ID: '00DDn00000AvXMw'
}
export const HCBG_DIVISION_CODES_WITH_ALTERNATE_SALESFORCE = [
    'AC',
    'EB',
    'EF',
    'EQ',
    'DG',
    'HQ'
]
export const DEPLOYMENT_URL =
    'https://c.la1-c1-ord.salesforceliveagent.com/content/g/js/42.0/deployment.js'
export const MULTIMEDIA_ENDPOINT = '/aag/mws/media360/' // /aag/ is the AWS API Gateway proxy
export const IFU_PDF_INLINE_LOCALES = ['cs_CZ', 'pl_PL']
export const TEBG_DIVISION_CODES_WITH_ALTERNATE_LABEL = ['AM', 'AT', 'BF', 'DA', 'KT'] // en_US only
export const SIBG_DIVISION_CODES_WITH_ALTERNATE_LABEL = [
    'AB',
    'AD',
    'AE',
    'AF',
    'BK',
    'BY',
    'EP',
    'HA'
] // all locales
export const MAIN_IMAGE_CODE = 'main_image'
export const ADDITIONAL_IMAGE_CODE = '8'
export const VIDEO_CODE = 'video'
export const VIDEO_PLAYER_ID = 3259523391001
export const PRODUCT_OPTIONS_ENDPOINT = 'productOptions'
export const MORE_MEDIA_ENDPOINT = 'moreMedia'
export const TECHSPEC_DIMENSIONS = 'techspec_dimensions'
export const WTB_API_URL =
    'https://api-retailer.global.commerce-connector.com/REST/2.0/ArticleReach/'
export const DROPDOWN_SIZE = 6
export const SENTENCE_CASE_LOCALES = [
    'bg_BG',
    'cs_CZ',
    'da_DK',
    'de_AT',
    'de_CH',
    'de_DE',
    'el_GR',
    'es_ES',
    'et_EE',
    'fi_FI',
    'fr_BE',
    'fr_CH',
    'fr_FR',
    'fr_MA',
    'hr_HR',
    'hu_HU',
    'it_IT',
    'iw_IL',
    'lt_LT',
    'lv_LV',
    'nl_BE',
    'nl_NL',
    'no_NO',
    'pl_PL',
    'pt_PT',
    'ro_RO',
    'ru_KZ',
    'sk_SK',
    'sl_SI',
    'sr_RS',
    'sv_SE',
    'tr_TR',
    'uk_UA'
]
export const NO_IMAGE_CONTENT_TYPES = [
    'Article Information Letter',
    'Regulatory Data Sheet',
    'Safety Data Sheets',
    'Article Information Sheet'
]
