import React, { useContext } from 'react'
import { Translations } from '../../services/TranslationService.jsx'
import Highlights from './Highlights'
import UpperDetails from './UpperDetails'
import '../../css/Details.scss'
import SuggestedApps from './SuggestedApps.jsx'
import RecommendedIndustries from './RecommendedIndustries.jsx'
import { StateContext } from '../../services/StateService.jsx'
import AlternateVideo from './AlternateVideo.jsx'

const Details = ({ detailsRef }) => {
    const translations = useContext(Translations)
    const { productDetails, richContent, detailsZone } = useContext(StateContext)
    const { details } = translations
    const { suggestedApplications } = productDetails || {}

    return (
        <div ref={detailsRef} className="sps2-pdp_section sps2-pdp_details">
            <h3 className="mds-font_header--3" tabIndex="-1">
                {details}
            </h3>
            <Highlights />
            <UpperDetails />
            {suggestedApplications && <SuggestedApps />}
            {richContent?.recommendedIndustries && <RecommendedIndustries />}
            {detailsZone && detailsZone?.alternateVideo && <AlternateVideo />}
        </div>
    )
}

export default Details
