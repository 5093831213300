/**
 * @file Manages Axios Service
 * @author SI Team
 */

import { selectorsArrayToObj } from './PDPService.jsx'

/**
 * will execute changes on the state based on the filter received.
 * @param {object} state is the global state of the app
 * @param {object} action The type of action that will be executed on the state.
 * @returns A new state with the changes applied: updated items, breadboxes, aggregations, loading status to the state reducer.
 */
const filterItems = (state, action) => {
    return {
        ...state,
        ...action.payload,
        breadBox: action.payload.breadBox ?? [],
        items: action.payload.items ?? [],
        loading: false,
        endpoints: { ...state.endpoints, ...action.payload.endpoints }
    }
}

/**
 * will append products to the state based.
 * @param {object} state State required to apply the payload, at this point it is the current list of products
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the appended products to the state reducer /?/ manager instead of reducer?
 */
const loadMoreProducts = (state, action) => {
    return {
        ...state,
        items: [...state.items, ...action.payload]
    }
}

/**
 * loadMoreResources returns the appended mediaLinks to the state.resourcesData reducer
 * @param {object} state State required to apply the payload, at this point it is the current list of products
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the appended mediaLinks to the state.resourcesData reducer
 */
const loadMoreResources = (state, action) => {
    return {
        ...state,
        resourcesData: {
            ...state.resourcesData,
            mediaLinks: [...(state.resourcesData.mediaLinks ?? []), ...action.payload]
        }
    }
}

/**
 * loadMoreRegulatory returns the updated list to the state reducer
 * @param {object} state State required to apply the payload, at this point it is the current list of products
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the updated list to the state reducer
 */
const loadMoreRegulatory = (state, action) => {
    return {
        ...state,
        regulatoryData: {
            ...state.regulatoryData,
            items: [...(state.regulatoryData?.items ?? []), ...action.payload]
        }
    }
}

/**
 * loadMoreWebContent returns the updated list to the state reducer
 * @param {object} state State required to apply the payload, at this point it is the current list of products
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the updated list to the state reducer
 */
const loadMoreWebContent = (state, action) => {
    return {
        ...state,
        webContentData: {
            ...state.webContentData,
            items: [...(state.webContentData?.items ?? []), ...action.payload]
        }
    }
}

/**
 * updateTotals update the totals of the state
 * @param {object} state State required to apply the payload, at this point it is the current list of products
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the updated state
 */
const updateTotals = (state, action) => {
    return {
        ...state,
        resourcesData: {
            ...state.resourcesData,
            total: action.payload.data[0]?.total ?? 0
        },
        webContentData: {
            ...state.webContentData,
            total: action.payload.data[1]?.total ?? 0
        },
        regulatoryData: {
            ...state.regulatoryData,
            total: action.payload.data[2]?.total ?? 0
        }
    }
}

/**
 * pdpLoadMoreResources loads new resources, resourcesMore and resourcesType (which would be the last Content Type it was clicked on)
 * to the context from a API call based on the contentType, there is no concatenation
 * because the old resources are being save inside a Resources useState in the Resources Component
 * @param {object} state State required to apply the payload, at this point it is the current product.
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the new resources, the resourcesMore, and resourcesType value updated
 */
const pdpLoadMoreResources = (state, action) => {
    return {
        ...state,
        resources: action.payload?.resources,
        resourcesMore: action.payload?.resourcesMore ?? false,
        resourcesType: action.payload?.contentType ?? ''
    }
}

/**
 * pdpLoadMoreProductOptions returns the updated list to the to the state.productOptions reducer.
 * @param {object} state State required to apply the payload, at this point it is the current product.
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the appended product options to the state.productOptions reducer
 */
const pdpLoadMoreProductOptions = (state, action) => {
    return {
        ...state,
        productOptions: [
            ...(state.productOptions ?? []),
            ...action.payload.productOptions
        ]
    }
}

/**
 * pdpSetProductOptions will update the state for productOptions, the ammount of matchingProductOptions
 * and also the endpoints to add the one to load more product options.
 * @param {object} state State required to apply the payload, at this point it is the current product.
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the list of  product options, ammount of matching products, and the endpoint to load more product options.
 */
const pdpSetProductOptions = (state, action) => {
    return {
        ...state,
        productOptions: action.payload.productOptions,
        marketplaceTableInfo: action?.payload?.marketplaceTableInfo,
        matchingProductOptions: action.payload.moreOptions.totalSelected,
        endpoints: {
            ...state.endpoints,
            moreProductOptions: action.payload.endpoint
        }
    }
}

/**
 * Set Loading state for the data table, detect when it's an update of the displayed table
 */
const pdpSetLoadingProductOptions = (state, action) => {
    return {
        ...state,
        loadingProductOptions: {
            loading: action.payload.loading,
            isUpdate: action.payload.isUpdate
        }
    }
}

/**
 * pdpLoadMoreMedia returns the appended media to the state.media reducer
 * and also the mediaMore value updated.
 * @param {object} state State required to apply the payload, at this point it is the current product.
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the appended media to the state.media reducer and the mediaMore value updated
 */
const pdpLoadMoreMedia = (state, action) => {
    if (Array.isArray(action.payload.media)) {
        return {
            ...state,
            media: [...(state.media ?? []), ...action.payload.media],
            mediaMore: action.payload.mediaMore
        }
    } else {
        return state
    }
}
/**
 * pdpLoadMoreBoughtTogether and boughtTogetherType (which would be the last typeCode it was clicked on)
 * to the context from a API call based on the typeCode, there is no concatenation
 * because the old fbt are being save inside a fbt useState in the fbt Component
 * @param {object} state State required to apply the payload, at this point it is the current product.
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the appended frequently bought together and the updated value for boughtTogetherMore
 */
const pdpLoadMoreBoughtTogether = (state, action) => {
    return {
        ...state,
        boughtTogether: action.payload?.boughtTogether,
        boughtTogetherMore: action.payload?.boughtTogetherMore ?? false,
        boughtTogetherType: action.payload?.boughtTogetherType ?? ''
    }
}

/**
 * pdpRelatedProductsCompare returns the appended related products compare to the state
 * and updates the pdpRelatedProductsCompare value.
 * @param {object} state State required to apply the payload, at this point it is the current product.
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the appended related products compare and the updated values for relatedProductsCompare
 */
const pdpRelatedProductsCompare = (state, action) => {
    return {
        ...state,
        relatedProductsAttributes: [
            ...(state.attributes ?? []),
            ...(action.payload.attributes ?? [])
        ]
    }
}
/**
 * It will happen ONLY on page load in the buying zone
 * @param {*} state previous state
 * @param {*} action payload.selectedOptions will have the moreOptions.options array coverted to an object to acces it easily
 * @returns selectedOptions append to the context
 */
const pdpSetSelectedOptions = (state, action) => {
    let selectedOptions = action.payload.selectedOptions
    if (action.payload.initialState) {
        selectedOptions = {
            options: selectorsArrayToObj(state.moreOptions?.options),
            totalSelected: state.moreOptions?.totalSelected,
            totalSelectors: state.moreOptions?.options?.length || 0,
            hasChanged: false
        }
    }
    return {
        ...state,
        selectedOptions: selectedOptions
    }
}

/**
 * It will happen every time a selection is made
 * @param {*} state previous state
 * @param {*} action payload.selector will have the data of the selected value
 * @returns selectedOptions append to the context
 */
const pdpOnChangeSelectedOptions = (state, action) => {
    const { target, selector, allLabel } = action.payload

    const optionLabel = target.selectedOptions[0].getAttribute('optionlabel')
    let lastSelectedValue = ''
    if (optionLabel.trim() === allLabel) {
        const url = target.value === 'all' ? selector.selectedValue : target.value
        lastSelectedValue = url.replace(
            new RegExp('[?&]' + selector.identifier + '=[^&]*([.?&]|$)'),
            '$1'
        )
    } else {
        lastSelectedValue = target.value
    }

    const selectedOptions = {
        ...state.selectedOptions,
        options: {
            ...state.selectedOptions.options,
            [selector.identifier]: {
                ...selector,
                selectedValue: target.value
            }
        },
        hasChanged: true,
        lastSelected: {
            label: optionLabel,
            value: lastSelectedValue,
            identifier: selector.identifier
        }
    }

    return {
        ...state,
        selectedOptions: selectedOptions
    }
}

/**
 * Action to update different ui options that needs to live in the Context to avoid prop drilling
 * @param {*} state previous state
 * @param {*} action payload
 */
const pdpUpdateUIOptions = (state, action) => {
    return {
        ...state,
        ...action.payload
    }
}

/**
 * Action to set the loading state for the WTB widget
 */
const pdpSetLoadingCCWTBScript = (state, action) => {
    return {
        ...state,
        ctaInfo: { ...state.ctaInfo, loadingCCWTBScript: action.payload }
    }
}

/**
 * when narrow down to 1 single product the info will be stored in oneProductSelected
 */
const pdpSetOneProductSelection = (state, action) => {
    return {
        ...state,
        oneProductSelection: action.payload
    }
}

const showErrorMessage = (state, action) => {
    const today = new Date()
    const date =
        today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
    if (!action.payload) {
        return {
            ...state,
            errorMessage: undefined
        }
    }
    return {
        ...state,
        errorMessage: {
            timeStampError: date + ' ' + time,
            errorData: action.payload
        }
    }
}

const pdpSetActiveMedia = (state, action) => {
    return {
        ...state,
        activeMedia: { ...state.media[action.payload], index: action.payload }
    }
}

const setAjaxArrors = (state, action) => {
    return {
        ...state,
        errors: {
            ...state.errors,
            ...action.payload
        }
    }
}

/**
 * Returns the appended required products to the state.requiredProducts reducer
 * @param {object} state State required to apply the payload, at this point it is the current list of required products
 * @param {object} action The type of action that will be executed on the state.
 * @returns returns the appended mediaLinks to the state.requiredProducts reducer
 */
const pdpLoadMoreRequiredProducts = (state, action) => {
    return {
        ...state,
        requiredProducts: [
            ...(state.requiredProducts ?? []),
            ...action.payload.requiredProducts
        ],
        requiredProductsMore: action.payload?.requiredProductsMore ?? false
    }
}

export {
    filterItems,
    loadMoreProducts,
    loadMoreResources,
    loadMoreRegulatory,
    loadMoreWebContent,
    updateTotals,
    pdpLoadMoreResources,
    pdpLoadMoreProductOptions,
    pdpLoadMoreMedia,
    pdpSetProductOptions,
    pdpSetLoadingProductOptions,
    pdpLoadMoreBoughtTogether,
    pdpRelatedProductsCompare,
    pdpSetSelectedOptions,
    pdpOnChangeSelectedOptions,
    pdpUpdateUIOptions,
    pdpSetLoadingCCWTBScript,
    pdpSetOneProductSelection,
    pdpSetActiveMedia,
    showErrorMessage,
    setAjaxArrors,
    pdpLoadMoreRequiredProducts
}
