const createProductSchema = ({ name, canonical }) => ({
    '@context': 'https://schema.org',
    '@type': 'Product',
    name,
    url: canonical,
    brand: {
        '@type': 'Brand',
        name: '3M'
    }
})

const createChildProductSchema = (baseURL, childID) => ({
    '@type': 'Product',
    brand: {
        '@type': 'Brand',
        name: '3M'
    },
    url: `${baseURL}/dc/${childID}/`
})

const createChildrenProductSchema = (childrenSeo, canonicalURL) => {
    const baseURL = canonicalURL.split('/d')[0]
    return childrenSeo?.map((childID) => createChildProductSchema(baseURL, childID))
}

/**
 * Adds structured data for product variations (children) to the <head> element of the HTML page,
 * enhancing search engine understanding and indexing of individual child products.
 */
const addChildrenStructuredData = (productDetails, childrenSeo) => {
    const productSchema = createProductSchema(productDetails)
    productSchema.hasVariant = createChildrenProductSchema(
        childrenSeo,
        productDetails.canonical
    )

    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.id = 'childrenStructuredData'
    script.innerHTML = JSON.stringify(productSchema)
    document.head.appendChild(script)
}

export { addChildrenStructuredData }
