import React, { useContext } from 'react'
import { Link } from '@3mcom/mds-library/dist/node/index'
import { Translations } from '../../services/TranslationService'
import { StateContext } from '../../services/StateService'
import { NO_IMAGE } from '../../constants/constants.js'
import '../../css/DiscontinuedZone.scss'

const DiscontinuedZone = () => {
    const translations = useContext(Translations)
    const { contactCustomerService, discontinued, replacedBy } = translations
    const { replacement } = useContext(StateContext)
    return (
        <div className="sps2-pdp_discontinued">
            <div className="sps2-pdp_discontinued-tooltip">{discontinued}</div>
            {replacement.name && replacement.url && (
                <div className="sps2-pdp_discontinued-replacement">
                    <div className="sps2-pdp_discontinued-replacement--header">
                        <p className="mds-font_legal">{replacedBy}</p>
                    </div>
                    <div className="sps2-pdp_discontinued-replacement--column">
                        <div className="sps2-pdp_discontinued-replacement--column_img-container">
                            <img
                                src={
                                    replacement?.main_image
                                        ? replacement.main_image
                                        : NO_IMAGE
                                }
                                alt={replacement?.altText}
                                loading="lazy"
                            />
                        </div>
                        <div className="sps2-pdp_discontinued-replacement--column_data">
                            <Link
                                className="mds-link mds-link_product"
                                href={replacement.url}
                                tealiumObj={{
                                    event_name: `PDP Buying Zone Discontinued Zone Replacement Link Click: ${replacement.name}`,
                                    event_type: 'Engagement Event',
                                    experience_type: 'SNAPS2'
                                }}
                            >
                                {replacement.name}
                            </Link>
                            {/* Temporally removed. Reference: https://jira.mmm.com/browse/DOTCOM-8853 */}
                            {/* <p className="mds-font_body">{contactCustomerService}</p> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DiscontinuedZone
