import React, { useContext, useEffect, useState } from 'react'
import ResultsTitle from '../../components/ResultsTitle'
import { Translations } from '../../services/TranslationService'
import { getRequest } from '../../services/AxiosService'
import CategorySection from '../CategorySection'
import * as ContactBar from '../../components/ContactBar.jsx'
import { CHAT_ICON, EMAIL_ICON, PHONE_ICON } from '../../constants/constants'
import { getAllProductsURL, getCategoriesBaseURL } from '../../services/URLService.jsx'
import { hideContactUsBasedOnLocale } from '../../services/URLService'

/**
 * NoResultsView
 * @param {string} primaryPageTitle The page title for the results page
 * @param {object} endpoints The endpoints object to create the API for featured categories
 * @param {string} canonicalUrl The canonical Url of the non-search page - used to view All Products
 * @returns Page view for when there are 0 results
 */
const NoResultsView = ({
    primaryPageTitle,
    endpoints,
    canonicalUrl,
    dispatch,
    fuzeRequest,
    brandedSite
}) => {
    const {
        chat,
        chatUrl,
        email,
        emailUrl,
        featuredCategories,
        contactUs,
        phone,
        phoneNumberText1,
        phoneNumberText2,
        phoneNumberText3,
        phoneNumberText4,
        popularRecentSearches,
        viewAllProducts,
        couldNotMatchSearch
    } = useContext(Translations)

    const hideContactUs = hideContactUsBasedOnLocale()

    const displayPhone = phoneNumberText2 !== 'null' && phone !== 'null'
    const displayContactBar =
        emailUrl !== 'null' || chatUrl !== 'null' || displayPhone !== 'null'

    const viewAllProductsLink = getAllProductsURL(canonicalUrl)

    const [categoryData, setCategoryData] = useState([])

    useEffect(() => {
        // Since there is no dedicated enpoint we pull the data from the serp products base api (without a specific search nor filter)
        // Get product api for /p/ GPH root
        const url = getCategoriesBaseURL(endpoints.productsSerpApi)

        if (url) {
            getRequest(url)
                .then(({ data }) => {
                    setCategoryData(
                        data?.aggregations?.sticky?.categories?.facets?.slice(0, 12) ?? []
                    )
                })
                .catch((error) => {
                    dispatch({
                        type: 'show_error_message',
                        payload: `Error on no result view: ${error}`
                    })
                })
        }
    }, [])

    return (
        <div className="sps2-serp">
            <div className="mds--centered">
                <ResultsTitle text={primaryPageTitle} />
                <p className="mds-font_intro--1">{couldNotMatchSearch}</p>
            </div>
            <hr className="mds-margin_xLarge--top mds-margin_xLarge--bottom"></hr>
            {!brandedSite?.length && (
                <div className="sps2-results">
                    <div className="sps2-results_section">
                        <div className="sps2-results_section--header">
                            <p className="mds-font_header--4 sps2-results_title">
                                {featuredCategories}
                            </p>
                            <a
                                href={viewAllProductsLink}
                                className="mds-link_category mds-link--button"
                            >
                                {viewAllProducts}
                            </a>
                        </div>
                        {categoryData.length > 0 && (
                            <CategorySection categoriesList={categoryData} />
                        )}
                    </div>
                </div>
            )}
            {!brandedSite?.length && displayContactBar && !hideContactUs && (
                <ContactBar.Container
                    background="grey"
                    className="mds-margin_xLarge--top"
                >
                    <ContactBar.Heading>{contactUs}</ContactBar.Heading>
                    <ContactBar.Bar>
                        {emailUrl !== 'null' && email !== 'null' && (
                            <ContactBar.Item
                                name={email}
                                icon={EMAIL_ICON}
                                url={emailUrl}
                            />
                        )}
                        {chatUrl !== 'null' && chat !== 'null' && (
                            <ContactBar.Item name={chat} icon={CHAT_ICON} url={chatUrl} />
                        )}
                        {displayPhone && (
                            <ContactBar.Item
                                phone={phone}
                                phoneNumber={phoneNumberText2}
                                icon={PHONE_ICON}
                            >
                                {phoneNumberText1 !== 'null' && (
                                    <p className="mds-font_header--6">
                                        {phoneNumberText1}
                                    </p>
                                )}
                                {phoneNumberText2 !== 'null' && (
                                    <p className="mds-font_header--6">
                                        {phoneNumberText2}
                                    </p>
                                )}
                                {phoneNumberText3 !== 'null' && (
                                    <p className="mds-font_body">{phoneNumberText3}</p>
                                )}
                                {phoneNumberText4 !== 'null' && (
                                    <p className="mds-font_body">{phoneNumberText4}</p>
                                )}
                            </ContactBar.Item>
                        )}
                    </ContactBar.Bar>
                </ContactBar.Container>
            )}
        </div>
    )
}

export default NoResultsView
