import React, { useContext } from 'react'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService.jsx'
import GridContainer from './GridContainer'

const Highlights = () => {
    const translations = useContext(Translations)

    const { highlights } = translations

    const { benefits } = useContext(StateContext)

    return <GridContainer title={highlights} content={benefits} />
}

export default Highlights
