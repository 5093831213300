import React from 'react'
import { INLINE_RATING } from '../constants/bazaarVoiceConstants.js'

/**
 * BazaarVoice is the component that renders the stars and ratings for products
 * @param {number} stockNumber The ID of the product
 * @param {string} bazaarVoiceClientName The client name of the bazaar voice placement (example: "3m-country-catalog")
 * @param {array} bazaarVoiceDisplayZone The display zone of the bazaar voice (example: ["FUZEexperience"])
 * @param {string} bazaarVoiceShow The type of bazaar voice content to display. Please use an option from bazaarVoiceConstants.js (example: REVIEW_HIGHLIGHTS)
 * @returns {component} React context that returns the BazaarVoice set up according the locale.
 */
const BazaarVoice = ({
    stockNumber,
    bazaarVoiceClientName = undefined,
    bazaarVoiceDisplayZone = [],
    bazaarVoiceProductID = '',
    bazaarVoiceShow = INLINE_RATING
}) => {
    let bvData = {}
    // let's check if we have bv data available
    if (bazaarVoiceClientName && bazaarVoiceDisplayZone.length > 0) {
        // Create the inline bazaar voice data that will be referenced by bv script
        bvData = {
            'data-bv-product-id': bazaarVoiceProductID,
            'data-bv-show': bazaarVoiceShow
        }
    }
    return <div className="sps2-content_bv" {...bvData} />
}

export default BazaarVoice
