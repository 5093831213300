import React, { useState, useContext, useRef, useEffect } from 'react'
import { Translations } from '../../services/TranslationService.jsx'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import * as LHNAccordion from './LHNAccordion.jsx'
import HierarchySelection from './selectionTypes/HierarchySelection.jsx'
import Selection from './Selection.jsx'
import { getTealiumObj } from '../../services/TealiumService.jsx'
import { FACETS_OPTIONS_SHOWN } from '../../constants/UtilityConstants.js'
import { sortFacets } from '../../services/UtilityService.jsx'
import { NavigationContext } from './NavigationContext.js'

/**
 * FilterController is the LHN filter group controller of accordion and show all configurations
 * @param {string} name Name of the filter - will be the accordion toggle
 * @param {array} facets The facets / options for the filter
 * @param {string} selectType The selection type of the filter - can be 'single', 'multi' or undefined which gives the 'default' link
 * @param {string} stickyType The type of sticky filter to return associated hierarchy - currently only 'categories' and 'industries' are supported
 */
const FilterController = ({
    name,
    facets = [],
    selectType,
    stickyType = undefined,
    showHierarchy
}) => {
    if (!facets.length && !showHierarchy) {
        return null
    }

    const { showAll } = useContext(Translations)
    const { dynamicLoad, breadBox } = useContext(NavigationContext)
    const [showAllFlag, setShowAllFlag] = useState(false)
    const [sortedFacets, setSortedFacets] = useState([...facets])
    const lastFacet = useRef(false)

    useEffect(() => {
        // Prevents hierarchy for breadBoxes in branded collections
        if (stickyType === 'collections' && breadBox?.length === 1 && facets.length) {
            facets = []
        }
        // for when are facets available
        if (facets.length) {
            let tempFacets = facets
            // let's check if the facets are not dynamic and in pcp.
            if (selectType === 'single' && !dynamicLoad) {
                tempFacets = sortFacets(facets, showAllFlag)
            }
            // for SERP and all dynamic filters
            setSortedFacets(tempFacets)
            lastFacet.current = false
        } else if (!lastFacet.current && !facets.length) {
            // if we have 0 facets
            setSortedFacets(facets)
            lastFacet.current = true
        }
    }, [facets, showAllFlag])

    return (
        <div className="sps2-lhn_category">
            <LHNAccordion.LHNAccordion>
                <LHNAccordion.Header
                    title={name}
                    onClick={() => {
                        TealiumTag(getTealiumObj('snaps-filter_accordion Click: ' + name))
                    }}
                />
                <LHNAccordion.Body>
                    <ul className="sps2-lhn_filters">
                        <HierarchySelection type={stickyType} />
                        {sortedFacets?.map((filterItem, index) => {
                            return !showAllFlag &&
                                index > FACETS_OPTIONS_SHOWN - 1 ? null : (
                                <Selection
                                    name={name}
                                    key={index}
                                    facets={filterItem}
                                    selectType={selectType}
                                    autoFocus={index === FACETS_OPTIONS_SHOWN} // When user clicks 'Show all' - refocus to next option in the list for accessibility
                                />
                            )
                        })}
                        {sortedFacets?.length > FACETS_OPTIONS_SHOWN && !showAllFlag && (
                            <li>
                                <button
                                    className="sps2-lhn_link"
                                    onClick={() => {
                                        setShowAllFlag(true)
                                        TealiumTag(
                                            getTealiumObj(
                                                'snaps-filter_showMore Click: ' + name
                                            )
                                        )
                                    }}
                                    aria-label={showAll + ' ' + name}
                                >
                                    {`+ ${showAll}`}
                                </button>
                            </li>
                        )}
                    </ul>
                </LHNAccordion.Body>
            </LHNAccordion.LHNAccordion>
        </div>
    )
}
export default FilterController
