import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@3mcom/mds-library/dist/node/Button'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { Translations } from '../../services/TranslationService.jsx'
import { toSentenceCase } from '../../services/UtilityService.jsx'
import { getTealiumObj } from '../../services/TealiumService.jsx'
import { createFilterEndpoint, filterSelection } from '../../services/SelectionService.jsx'
import { toRelativeURL, clearAllLinkProdTab } from '../../services/URLService.jsx'
import { PRODUCTS_TAB } from '../../constants/constants'

/**
 * FilterToggle is the LHN 'Filter' button that appears on mobile and opens the LHN navigation on mobile
 * @param {string} filter Label for the word 'filter'
 * @param {boolean} showMobileLHN A true|false value for if the mobile navigation is visible
 * @param {func} setShowMobileLHN A function to set the state of showMobileLHN in parent
 */
const FilterToggle = ({ showMobileLHN, setShowMobileLHN }) => {
    const { filter } = useContext(Translations)
    const thisElement = useRef()
    const [initialRender, setInitialRender] = useState(true)

    // When the LHN mobile menu closes refocus on the Filter button
    useEffect(() => {
        // Verify that we don't focus on filter button immediately upon page load
        if (!initialRender && !showMobileLHN) {
            thisElement?.current?.focus()
        }
        setInitialRender(false)
    }, [showMobileLHN])

    return (
        <div className="sps2-lhn_toggle">
            <button
                className="mds-button mds-button_secondary mds-button_secondary--small "
                onClick={() => {
                    setShowMobileLHN(true)
                    TealiumTag(getTealiumObj('snaps-button MOBILE Click: ' + filter))
                }}
                ref={thisElement}
            >
                {filter}
            </button>
            {/* Will re-use MDS-button component once it's integrated with refs for accessibility
                <Button
                class="mds-button_secondary"
                size="small"
                text={filter} // TODO - Update when translation comes in
                function={() => {
                    setShowMobileLHN(true)
                }}
            /> */}
        </div>
    )
}

/**
 * FooterCTAs is the LHN 'Clear' and 'View Results' CTA buttons that appears on the mobile filter menu
 * @param {array} breadBox The array of breadBox items, determines if the 'Clear' CTA should be visible
 * @param {string} clearLink The URL for the 'Clear' CTA to clear all breadboxes / filters chosen
 * @param {function} setShowMobileLHN A function to toggle the visibility of the mobile navigation menu
 */
const FooterCTAs = ({ breadBox, clearLink, setShowMobileLHN, dynamicLoad, endpoints, currentTab, dispatch}) => {
    const { clear, viewResults } = useContext(Translations)

    return (
        <div className="sps2-lhn_footer">
            {/* If any filters are chosen clear all filters using the clearLink and re-open navigation menu */}
            {breadBox?.length > 0 && (
                <Button
                    buttonType="tertiary"
                    size="medium"
                    onClick={() => {
                        if (dynamicLoad) {
                            let clearAllApi = createFilterEndpoint(clearLink, endpoints)
                            if (currentTab === PRODUCTS_TAB) {
                                clearAllApi = clearAllLinkProdTab(clearLink, endpoints)
                            }
                            filterSelection(clearAllApi, toRelativeURL(clearLink), endpoints, dispatch)
                        } else {
                            // if not, it has to be a PCP page.
                            dispatch({ type: 'set_loading_status', payload: true })
                            window.location.href = clearLink + '#filter'
                        }
                    }}
                    tealiumObj={getTealiumObj('snaps-button MOBILE Click: ' + clear)}
                >
                    {toSentenceCase(clear)}
                </Button>
            )}
            <Button
                buttonType="primary"
                size="medium"
                onClick={() => {
                    setShowMobileLHN(false)
                }}
                tealiumObj={getTealiumObj('snaps-button MOBILE Click: View results')}
            >
                {toSentenceCase(viewResults)}
            </Button>
        </div>
    )
}

export { FilterToggle, FooterCTAs }
