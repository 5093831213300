import React from 'react'

/**
 * Results is a component that will be displayed on top of the LHN
 * @param {number} stockNumber The ID of the product
 * @param {string} bazaarVoiceClientName The client name of the bazaar voice placement (example: "3m-country-catalog")
 * @returns {html} with all the total results.
 */
const Results = ({ total, labelText }) => {
    return (
        <h2 className="sps2-bar_total mds-font_header--4">
            {total} {labelText}
        </h2>
    )
}

export default Results
