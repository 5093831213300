import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Translations } from '../services/TranslationService.jsx'
import { StateContext } from '../services/StateService.jsx'
import MainGalleryElement from './MainGalleryElement'
import MediaGalleryThumbnails from './MediaGalleryThumbnails.jsx'
import MobileMediaGallery from '../fragments/DetailPage/MobileMediaGallery.jsx'
import {
    MAGIC360_CODE,
    MAIN_IMAGE_CODE,
    ADDITIONAL_IMAGE_CODE
} from '../constants/PDPConstants.js'
import Alert from '@3mcom/mds-library/dist/node/Alert'
import '../css/InnerGallery.scss'

const InnerGallery = ({
    name,
    magic360media,
    innerSplideRef,
    innerThumbsRef,
    isDesktop
}) => {
    const innerMagicZoomInitialState = {
        ready: false,
        zoomState: 'out'
    }
    const translations = useContext(Translations)
    const { activeMedia, media, errors } = useContext(StateContext)
    const getPlaylistMode = activeMedia?.videoPlayerType?.includes('Single') && false
    const current360Data = magic360media?.find(
        (element) => element.position === activeMedia?.index
    )
    const [innerMagicZoomState, setInnnerMagicZoomState] = useState(
        innerMagicZoomInitialState
    )
    /* istanbul ignore next */
    const spinnerReady = (event) => {
        // Cleanup -> Removes the Magic360 EventListeners
        document
            .getElementById(event?.detail?.spinnerId)
            ?.removeEventListener('spinnerReady', spinnerReady)
    }
    /* istanbul ignore next */
    const initializeSpinner = (index) => {
        // onReady EventListener for Magic360 Spinner
        document
            .getElementById(`magic360_${index}`)
            ?.addEventListener('spinnerReady', spinnerReady)
        try {
            window?.Magic360?.stop()
            window?.Magic360?.start()
        } catch (error) {
            // setIsMagic360Loading(false)
        }
    }
    /* istanbul ignore next */
    const onZoomInInnerGallery = () =>
        setInnnerMagicZoomState((state) => ({ ...state, zoomState: 'in' }))
    /* istanbul ignore next */
    const onZoomOutInnerGallery = () =>
        setInnnerMagicZoomState((state) => ({ ...state, zoomState: 'out' }))

    useEffect(() => {
        if (isDesktop) {
            // onZoomIn EventListener - InnerGallery
            document
                .getElementById('innerMagicZoom')
                ?.addEventListener('zoomIn', onZoomInInnerGallery)

            // onZoomOut EventListener - InnerGallery
            document
                .getElementById('innerMagicZoom')
                ?.addEventListener('zoomOut', onZoomOutInnerGallery)
        }

        // Cleanup -> Removes the MagicZoom EventListeners
        return () => {
            document
                .getElementById('innerMagicZoom')
                ?.removeEventListener('zoomIn', onZoomInInnerGallery)

            document
                .getElementById('innerMagicZoom')
                ?.removeEventListener('zoomOut', onZoomOutInnerGallery)
        }
    }, [activeMedia])

    useEffect(() => {
        if (isDesktop) {
            /* istanbul ignore next */
            if (activeMedia?.usageCode === MAGIC360_CODE) {
                initializeSpinner(activeMedia?.index)
            }
            if (
                activeMedia?.usageCode === ADDITIONAL_IMAGE_CODE ||
                activeMedia?.usageCode === MAIN_IMAGE_CODE
            ) {
                window?.MagicZoom?.stop('innerMagicZoom')
                window?.MagicZoom?.start('innerMagicZoom')
            }
        }
    }, [activeMedia])

    return (
        <div className="sps2-pdp_innerGallery">
            {isDesktop ? (
                <Fragment>
                    <div className="sps2-pdp_innerGallery--sidebar">
                        <h5 className="mds-font_header--5">{name}</h5>
                        <MediaGalleryThumbnails
                            list={media}
                            className="sps2-pdp_innerGallery--list"
                            magic360media={magic360media}
                            isInnerGallery={true}
                        />
                        {errors?.mediaMoreAjaxError && (
                            <div className="sps2-pdp_ajaxError--centered mds-margin_medium--top">
                                <Alert.InContext variant="error">
                                    {translations.ajaxError}
                                </Alert.InContext>
                            </div>
                        )}
                    </div>
                    <MainGalleryElement
                        className="sps2-pdp_innerGallery--active_container"
                        isInnerGallery={true}
                        magicZoomState={innerMagicZoomState}
                        current360Data={current360Data}
                        modePlaylist={getPlaylistMode}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <div className="sps2-pdp_innerGallery--header">
                        <h5 className="mds-font_intro--1 sps2-pdp_innerGallery--header_title">
                            {name}
                        </h5>
                    </div>
                    <MobileMediaGallery
                        isInnerGallery={true}
                        modePlaylist={getPlaylistMode}
                        magic360media={magic360media}
                        initializeSpinner={initializeSpinner}
                        splideRef={innerSplideRef}
                        thumbnailsRef={innerThumbsRef}
                    />
                </Fragment>
            )}
        </div>
    )
}

export default InnerGallery
