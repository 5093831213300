import React, { useContext, useState, useRef, useEffect } from 'react'
import { StateContext } from '../../services/StateService'
import Overlay from '@3mcom/mds-library/dist/node/Overlay'
import InnerGallery from '../../components/InnerGallery'
import OuterGallery from '../../components/OuterGallery'
import { Translations } from '../../services/TranslationService'
import { getRequest } from '../../services/AxiosService'
import { useIsDesktop } from '../../services/UtilityService'
import {
    MAGIC360_CODE,
    MAIN_IMAGE_CODE,
    ADDITIONAL_IMAGE_CODE,
    MULTIMEDIA_ENDPOINT
} from '../../constants/PDPConstants'
import { trackPDPData } from '../../services/TealiumService'
import '../../css/MediaGallery.scss'

const MediaGallery = () => {
    const translations = useContext(Translations)
    const {
        productDetails,
        media,
        mediaMore,
        endpoints,
        mediaMoreCount,
        mediaOffset,
        activeMedia,
        dispatch
    } = useContext(StateContext)

    const outerMagicZoomInitialState = {
        ready: false,
        zoomState: 'out'
    }

    const [initialMedia] = useState(media)
    const outerSplideRef = useRef()
    const innerSplideRef = useRef()
    const outerThumbsRef = useRef()
    const innerThumbsRef = useRef()
    const overlayRef = useRef()
    const isDesktop = useIsDesktop()
    const [outerMagicZoomState, setOuterMagicZoomState] = useState(
        outerMagicZoomInitialState
    )
    const [magic360media, setMagic360Media] = useState([])
    const [zoomReady, setZoomReady] = useState(false)
    const firstRender = useRef(true)
    const isImage =
        activeMedia?.usageCode === MAIN_IMAGE_CODE ||
        activeMedia?.usageCode === ADDITIONAL_IMAGE_CODE

    useEffect(() => {
        if (!isDesktop && outerSplideRef?.current && outerThumbsRef?.current?.splide) {
            outerSplideRef.current.sync(outerThumbsRef.current.splide)
        }
    }, [isDesktop])

    useEffect(() => {
        if (!activeMedia) {
            dispatch({
                type: 'pdp_set_active_media',
                payload: 0
            })
        }
        get360Data()
    }, [media])

    useEffect(() => {
        if (isDesktop) {
            // onZoomReady EventListener - OuterGallery
            document
                .getElementById('outerMagicZoom')
                ?.addEventListener('zoomReady', onZoomReadyOuterGallery)

            // onZoomIn EventListener - OuterGallery
            document
                .getElementById('outerMagicZoom')
                ?.addEventListener('zoomIn', onZoomInOuterGallery)

            // onZoomOut EventListener - OuterGallery
            document
                .getElementById('outerMagicZoom')
                ?.addEventListener('zoomOut', onZoomOutOuterGallery)
        }

        // Cleanup -> Removes the MagicZoom EventListeners
        return () => {
            document
                .getElementById('outerMagicZoom')
                ?.removeEventListener('zoomReady', onZoomReadyOuterGallery)

            document
                .getElementById('outerMagicZoom')
                ?.removeEventListener('zoomIn', onZoomInOuterGallery)

            document
                .getElementById('outerMagicZoom')
                ?.removeEventListener('zoomOut', onZoomOutOuterGallery)
        }
    }, [activeMedia])

    useEffect(() => {
        if (
            !firstRender.current &&
            activeMedia?.usageCode === MAGIC360_CODE &&
            magic360media.length > 0
        ) {
            setOuterMagicZoomState((state) => ({ ...state, ready: false }))
        }
    }, [activeMedia])

    useEffect(() => {
        /* istanbul ignore next */
        if (zoomReady) openOverlay()
    }, [activeMedia, zoomReady])

    useEffect(() => {
        if (!isDesktop && mediaMoreCount > 0) {
            getMoreMedia()
        }
    }, [isDesktop])

    useEffect(() => {
        firstRender.current = false
    }, [])

    /* istanbul ignore next */
    const get360Data = () => {
        const tempMedia = media?.length > 4 ? media.slice(4) : media
        tempMedia?.forEach((element, i) => {
            if (element.usageCode === MAGIC360_CODE) {
                const media360endpoint = `${window.location.origin.toString()}${MULTIMEDIA_ENDPOINT}${
                    element?.dmrId
                }/properties.json`
                // 360 endpoint to use locally
                // const media360endpoint = `localhost:3002/360?dmrId=${element?.dmrId}`
                getRequest(media360endpoint)
                    .then((response) => {
                        if (response?.data) {
                            const position = media?.length > 4 ? i + 4 : i
                            setMagic360Media((magic360media) => [
                                ...magic360media,
                                { ...response.data, position }
                            ])
                        }
                    })
                    .catch((error) => {
                        dispatch({
                            type: 'show_error_message',
                            payload: `Error on getting 360 data: ${error}`
                        })
                    })
            }
        })
    }

    const openOverlay = () => {
        overlayRef.current.toggle()
        const feedbackBtn = document.getElementById('kampyleButtonContainer')
        /* istanbul ignore next */
        if (feedbackBtn) {
            feedbackBtn.style.display = 'none'
        }
        trackPDPData(
            'PDP Buying Zone Media Gallery Main Element Button Click: open overlay',
            'Engagement Event'
        )
        getMoreMedia()
    }

    const onCloseOverlay = () => {
        if (!isDesktop) {
            dispatch({
                type: 'pdp_set_active_media',
                payload: innerSplideRef?.current?.splide?.index
            })
            outerSplideRef?.current?.go(innerSplideRef?.current?.splide?.index)
        }
        pauseVideo()
        const feedbackBtn = document.getElementById('kampyleButtonContainer')
        /* istanbul ignore next */
        if (feedbackBtn) {
            feedbackBtn.style.display = 'inline'
        }
    }

    /* istanbul ignore next */
    const startMagicZoom = () => {
        if (isDesktop && isImage && !outerMagicZoomState?.ready) {
            window?.MagicZoom?.start('outerMagicZoom')
        }
    }

    /* istanbul ignore next */
    const pauseVideo = () => {
        const videoPlayer = window?.videojs?.getPlayer('videoPlayer')
        if (videoPlayer) {
            videoPlayer.pause()
        }
    }

    const getMoreMedia = () => {
        if (mediaMore) {
            dispatch({
                type: 'set_AJAX_errors',
                payload: {
                    mediaMoreAjaxError: false
                }
            })
            getRequest(
                `${endpoints.moreMedia}/?mediaSize=${mediaMoreCount}&start=${mediaOffset}`
            )
                .then((response) => {
                    if (response.data) {
                        dispatch({
                            type: 'pdp_load_more_media',
                            payload: response.data
                        })
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: 'set_AJAX_errors',
                        payload: {
                            mediaMoreAjaxError: true
                        }
                    })
                    dispatch({
                        type: 'show_error_message',
                        payload: `Error on loading more media: ${error}`
                    })
                })
        }
    }

    /* istanbul ignore next */
    const onZoomReadyOuterGallery = (event) => {
        if (event.detail.magicZoomId === 'outerMagicZoom') {
            setOuterMagicZoomState((state) => ({ ...state, ready: true }))
            const magicZoom = document.getElementById('outerMagicZoom')
            magicZoom.onclick = () => setZoomReady((prevState) => !prevState)
        }
    }

    /* istanbul ignore next */
    const onZoomInOuterGallery = () =>
        setOuterMagicZoomState((state) => ({ ...state, zoomState: 'in' }))
    /* istanbul ignore next */
    const onZoomOutOuterGallery = () =>
        setOuterMagicZoomState((state) => ({ ...state, zoomState: 'out' }))
    /* istanbul ignore next */

    return (
        <div className="sps2-pdp_gallery--box">
            <OuterGallery
                media={initialMedia}
                openOverlay={openOverlay}
                startMagicZoom={startMagicZoom}
                magic360media={magic360media}
                magicZoomState={outerMagicZoomState}
                outerSplideRef={outerSplideRef}
                outerThumbsRef={outerThumbsRef}
                isDesktop={isDesktop}
            />

            <Overlay.Container
                closeLabel={translations.close}
                ref={overlayRef}
                onClickCallBack={onCloseOverlay}
                unmountOnExit={true}
            >
                <Overlay.Content>
                    <InnerGallery
                        name={productDetails?.name}
                        magic360media={magic360media}
                        innerSplideRef={innerSplideRef}
                        innerThumbsRef={innerThumbsRef}
                        isDesktop={isDesktop}
                    />
                </Overlay.Content>
            </Overlay.Container>
        </div>
    )
}

export default MediaGallery
