import React, { useContext, useEffect, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { StateContext } from '../../services/StateService'
import {
    ADDITIONAL_IMAGE_CODE,
    MAIN_IMAGE_CODE,
    MAGIC360_CODE
} from '../../constants/PDPConstants'
import { Translations } from '../../services/TranslationService'
import Thumbnail from '../../components/Thumbnail'

const MobileMediaGallery = ({
    isInnerGallery,
    modePlaylist,
    openOverlay,
    magic360media,
    initializeSpinner,
    splideRef,
    thumbnailsRef
}) => {
    const { media, dispatch, activeMedia } = useContext(StateContext)
    const translations = useContext(Translations)
    const isFirstRender = useRef(true)

    const mainOptions = {
        pagination: false,
        arrows: false,
        fixedHeight: 340,
        flickMaxPages: 1
    }

    const thumbsOptions = {
        type: 'slide',
        gap: '10px',
        pagination: false,
        fixedWidth: 90,
        fixedHeight: 90,
        isNavigation: true,
        arrows: false,
        flickMaxPages: 1
    }

    const mainOptionsInner = {
        drag: false,
        pagination: false,
        arrows: false,
        fixedHeight: 400,
        perPage: 1,
        perMove: 1,
        start: activeMedia?.index,
        flickMaxPages: 1
    }

    const thumbsOptionsInner = {
        type: 'slide',
        gap: '15px',
        pagination: false,
        fixedWidth: 50,
        fixedHeight: 50,
        isNavigation: true,
        arrows: true,
        start: activeMedia?.index,
        flickMaxPages: 1
    }

    const handleActiveChange = (splide) => {
        if (!isInnerGallery) {
            dispatch({
                type: 'pdp_set_active_media',
                payload: splide.index
            })
        } else {
            const activeCode = media[splide.index]?.usageCode
            if (activeCode === MAGIC360_CODE) {
                initializeSpinner(splide.index)
            }
        }
    }

    useEffect(() => {
        if (isInnerGallery && isFirstRender) {
            isFirstRender.current = false
            if (splideRef?.current && thumbnailsRef?.current?.splide) {
                splideRef.current.sync(thumbnailsRef.current.splide)
            }
            if (activeMedia?.usageCode === MAGIC360_CODE) {
                initializeSpinner(activeMedia?.index)
            }
        }
    }, [])

    return (
        <>
            <Splide
                options={isInnerGallery ? mainOptionsInner : mainOptions}
                ref={splideRef}
                onMoved={(splide) => {
                    handleActiveChange(splide)
                }}
                id={`sps2-pdp_gallery--${
                    isInnerGallery ? 'inner-slider' : 'outer-slider'
                }`}
            >
                {media.map((element, i) => (
                    <SplideSlide
                        key={`slide-${i}`}
                        onClick={isInnerGallery ? null : () => openOverlay()}
                        className={`${
                            !isInnerGallery &&
                            (element?.usageCode === ADDITIONAL_IMAGE_CODE ||
                                element?.usageCode === MAIN_IMAGE_CODE)
                                ? 'mds-padding_bottom--50'
                                : ''
                        }`}
                    >
                        <Thumbnail
                            isMainImage={true}
                            isInnerGallery={isInnerGallery}
                            mediaElement={element}
                            magic360media={magic360media}
                            modePlaylist={modePlaylist}
                            index={i}
                        />
                        {!isInnerGallery &&
                            (element?.usageCode === ADDITIONAL_IMAGE_CODE ||
                                element?.usageCode === MAIN_IMAGE_CODE) && (
                                <div className="sps2-pdp_gallery--hoverText sps2-pdp_gallery--hoverText-inner">
                                    {translations.clickTapZoom}
                                </div>
                            )}
                    </SplideSlide>
                ))}
            </Splide>
            <Splide
                options={isInnerGallery ? thumbsOptionsInner : thumbsOptions}
                ref={thumbnailsRef}
                className={isInnerGallery ? '' : 'mds-margin_small--top'}
                id={`sps2-pdp_gallery--${
                    isInnerGallery ? 'inner-slider_thumbnails' : 'outer-slider_thumbnails'
                }`}
            >
                {media?.map((element, i) => (
                    <SplideSlide key={`thumbnail-${i}`}>
                        <Thumbnail
                            mediaElement={element}
                            magic360media={magic360media}
                            index={i}
                        />
                    </SplideSlide>
                ))}
            </Splide>
        </>
    )
}

MobileMediaGallery.defaultProps = {
    modePlaylist: false,
    isInnerGallery: false
}

export default MobileMediaGallery
