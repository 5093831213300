import React, { useEffect, useContext } from 'react'
import { StateContext } from '../../services/StateService'
import PropTypes from 'prop-types'

const ServiceLocator = ({ widgetId, subId }) => {
    const { locale } = useContext(StateContext)

    const convertedLocale = locale.replace(/_/, '-')
    const language = locale.split('_')
    useEffect(() => {
        loadServiceLocator()
    }, [])

    const loadServiceLocator = () => {
        const serviceLocatorScript = document.createElement('script')
        serviceLocatorScript.id = 'service-locator-widget'
        serviceLocatorScript.type = 'text/javascript'
        serviceLocatorScript.src =
            'https://storefindernew.commerce-connector.com/storefinder.min.js'
        serviceLocatorScript.setAttribute(
            'data-token',
            '99c976f1d1aab575ceaba5749f1c60fa4df039d9'
        )
        serviceLocatorScript.setAttribute('data-locale', convertedLocale)
        serviceLocatorScript.setAttribute('data-displaylanguage', language[0])
        serviceLocatorScript.setAttribute('data-widgetid', widgetId)
        serviceLocatorScript.setAttribute('data-subid', subId)
        serviceLocatorScript.addEventListener('load', () => {
            console.log('serviceLocatorScript loaded')
        })
        serviceLocatorScript.addEventListener('error', () => {
            console.log('serviceLocatorScript failed')
        })

        document
            .querySelector('.sps2-pdp_serviceLocator--container')
            .appendChild(serviceLocatorScript)
    }

    return <div className={`sps2-pdp_serviceLocator--container`}></div>
}

ServiceLocator.propTypes = {
    widgetId: PropTypes.string,
    subId: PropTypes.string
}

export default ServiceLocator
