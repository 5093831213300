import React, { useState, useContext, useEffect } from 'react'
import Checkbox from '../checkbox.jsx'
import { NavigationContext } from '../NavigationContext.js'
import { joinUrlBaseAndParams } from '../../../services/URLService.jsx'
import { filterSelection } from '../../../services/SelectionService.jsx'

/**
 * MultipleSelection is a thin schema checkbox controller for the LHN
 * @param {string} name Name of the thin schema filter type (Ex. 'Color')
 * @param {string} value Value of the thin schema attribute (Ex. 'Blue')
 * @param {number} count Number of products available using this filter
 * @param {string} url The URL that will display these filters
 * @param {string} uom The unit of measure of the thin schema (Ex. 'Kilogram')
 * @param {boolean} selected Signifies if the thin schema is already selected
 */
const MultipleSelection = ({
    name,
    autoFocus = false,
    value,
    count,
    api,
    url,
    uom,
    selected = false,
    onClick = () => {
        return null
    }
}) => {
    const { dispatch, breadBox, endpoints } = useContext(NavigationContext)

    // Create state variables for if the checkbox is selected to get the associated removeUrl
    // NICE TO HAVE: In the future BE might update the thin schema url to be removeUrl - for now it comes from breadBox removeUrl
    const [removeUrl, setRemoveUrl] = useState(null)

    const matchVal = value + (uom ? ' ' + uom : '')
    const checkboxName = name.replace(' ', '_') + '|' + matchVal.replace(' ', '_')

    // Get removeUrl from associated breadBox
    const getBreadboxRemoveUrl = () => {
        // Filter breadBox based on the checkbox name and value
        const matchedBreadbox = breadBox?.filter(
            (breadBox) =>
                breadBox.name === name &&
                breadBox.value[breadBox.value.length - 1] === matchVal
        )

        // Set remove url if the breadBox matches
        if (matchedBreadbox?.length > 0) setRemoveUrl(matchedBreadbox[0].removeUrl)
    }

    useEffect(() => {
        selected ? getBreadboxRemoveUrl() : setRemoveUrl(null)
    }, [selected, breadBox])

    const checkboxProps = {
        uniqueID: checkboxName,
        fields: {
            name: checkboxName,
            fieldName: matchVal + ' (' + count + ')',
            size: 'x-small',
            type: 'secondary',
            checked: selected
        }
    }

    return (
        <Checkbox
            {...checkboxProps}
            autoFocus={autoFocus}
            onClick={(e) => {
                e.preventDefault()
                let updateUrlOnFilter = url
                if (selected) {
                    updateUrlOnFilter = removeUrl
                    api = joinUrlBaseAndParams(api, removeUrl)
                }

                filterSelection(api, updateUrlOnFilter, endpoints, dispatch)
                onClick()
            }}
        />
    )
}

export default MultipleSelection
