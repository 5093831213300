import React, { useContext, useState } from 'react'
import { StateContext } from '../../services/StateService'
import { scrollToSection, useIsDesktop } from '../../services/UtilityService'
import Benefits from '../../components/Benefits'
import DataSheets from './DataSheets'
import DiscontinuedZone from './DiscontinuedZone'
import DisclaimerLinks from '../../components/DisclaimerLinks'
import ProductSelector from './ProductSelector'
import Link from '@3mcom/mds-library/dist/node/Link'
import '../../css/DescriptionZone.scss'
import { Translations } from '../../services/TranslationService'
import { DATA_SHEET_EXCLUDED_DIVISION_CODE } from '../../constants/UtilityConstants'

const DescriptionZone = ({
    disclaimerInformationRef,
    resourcesRef,
    detailsRef,
    showDetails,
    prodOptButtonRef,
    handleProductSelectorRequest
}) => {
    const isDesktop = useIsDesktop()
    const {
        isParent,
        benefits,
        tds,
        sds,
        replacement,
        instructions,
        pdpPageOffset,
        productDetails
    } = useContext(StateContext)
    const [displaySelectors, setDisplaySelectors] = useState(false)


    const translations = useContext(Translations)

    const showDataSheets =
        isDesktop &&
        !DATA_SHEET_EXCLUDED_DIVISION_CODE.includes(productDetails.divisionCode) &&
        (tds?.length > 0 || sds?.length > 0 || instructions?.length > 0)
    return (
        <div className="sps2-pdp_description--container">
            {!isParent && (
                <ProductSelector
                    prodOptButtonRef={prodOptButtonRef}
                    handleProductSelectorRequest={handleProductSelectorRequest}
                    displaySelectors={displaySelectors}
                    setDisplaySelectors={setDisplaySelectors}
                />
            )}
            <Benefits benefits={benefits?.slice(0, 3)} />
            {showDetails && isDesktop && (
                <Link
                    className="mds-link mds-link_primary mds-margin_medium--top"
                    onClick={() => scrollToSection(detailsRef, pdpPageOffset)}
                >
                    {translations.viewMoreDetails}
                </Link>
            )}
            {showDataSheets && <DataSheets resourcesRef={resourcesRef} />}
            {replacement?.discontinued && <DiscontinuedZone />}
            {isDesktop && (
                <DisclaimerLinks disclaimerInformationRef={disclaimerInformationRef} />
            )}
            {/* COMMENTED UNTIL THE BEHAVIOR IS CLEAR */}
            {/* <button className="sps2-pdp_description--terms-conditions mds-link_secondary">
                {translations.termsAndConditions}
            </button> */}
        </div>
    )
}

export default DescriptionZone