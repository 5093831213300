import React, { useEffect, useRef } from 'react'
import '../css/ContentBlock.scss'

const Image = ({ src, altText }) => {
    return (
        <div className="sps2-content_img">
            <img src={src} loading="lazy" alt={altText}></img>
        </div>
    )
}

const Name = ({ name, title }) => {
    return (
        <div className="sps2-content_name">
            <p
                className="mds-link_product"
                dangerouslySetInnerHTML={{ __html: name }}
                title={title}
            />
        </div>
    )
}

const Link = ({
    url,
    className,
    title,
    children,
    focusOn = false,
    onClick = () => {
        return null
    },
}) => {
    const thisElement = useRef()

    useEffect(() => {
        if (focusOn) {
            thisElement?.current?.focus()
        }
    }, [])

    return (
        <a
            className={className}
            href={url}
            title={title}
            ref={thisElement}
            onClick={onClick}
        >
            {children}
        </a>
    )
}

const OptionsAvailable = ({ label }) => {
    return (
        <>
            <i className="MMM--icn MMM--icn_gallery sps2-content_data--options"></i>
            <span className="sps2-content_data--optionsLabel">{label}</span>
        </>
    )
}

const InlineData = ({ name, value }) => {
    return value ? (
        <div className="sps2-content_data--set">
            <p className="sps2-content_data--label">{name}</p>
            <p
                className="sps2-content_data--number"
                dangerouslySetInnerHTML={{ __html: value }}
            />
        </div>
    ) : null
}

const DataType = ({ name, type, size }) => {
    return (
        <div className="sps2-content_data--type">
            <p className="mds-font_paragraph">{name}</p>
            <p className="mds-font_body">
                ({type}, {size})
            </p>
        </div>
    )
}

const HR = (_) => <div className="sps2-content_hr"></div>

const ContentBlock = ({ className = '', children = null }) => (
    <div className={`sps2-content ${className}`}>{children}</div>
)

export { ContentBlock, Image, Name, OptionsAvailable, InlineData, DataType, HR, Link }
