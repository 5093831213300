import React from 'react'
import { useIsDesktop } from '../services/UtilityService.jsx'
import '../css/PageTitle.scss'

/**
 * PageTitle is the header of the PCP
 * @param {string} primaryTitle Primary title of the page
 * @param {string} secondaryTitle (Optional) Secondary title that appears below|appended to the primary title
 * @param {string} className (Optional) Class name that will wrap the headers
 */
const PageTitle = ({ primaryTitle, secondaryTitle, className }) => {
    const isDesktop = useIsDesktop()

    const secondaryTitleView = isDesktop ? (
        <span className="mds-font_header--2b sps2-title--secondary">
            {secondaryTitle}
        </span>
    ) : (
        ' ' + secondaryTitle
    )

    return (
        <div className={className}>
            <h1 className={isDesktop ? 'mds-font_header--1' : 'mds-font_header--2a'}>
                {primaryTitle}
                {secondaryTitle && secondaryTitleView}
            </h1>
        </div>
    )
}

PageTitle.defaultProps = {
    primaryTitle: '',
    secondaryTitle: '',
    className: '',
}

export default PageTitle
