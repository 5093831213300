export const PCPTranslations = {
    country: '[3M United States]',
    primaryPageTitle: '[{brand} {category}]',
    stockNumber: '[3M Stock]',
    for: '[for {industry}]',
    previousStockNumber: '[Previous 3M Stock]',
    viewResults: '[View results]',
    allMmmProducts: '[All {brand} Products]',
    products: '[products]',
    catalogId: '[3M Catalog ID]',
    showAll: '[Show all]',
    catBrandPageTitle: '[{brand} {category}]',
    replacementId:
        '[Produkt je zdravotnickým prostředkem. Vždy si prosím pečlivě prostudujte návod k použití (byl-li vydán) a informace o jeho bezpečném používání.]',
    categories: '[Categories]',
    all: '[All]',
    product: '[product]',
    brands: '[Brands]',
    brandProductcategories: '[Product Categories]',
    healthCareStatement:
        '[Legal Notice: You are on a site intended for healthcare professionals. The basic information contained in the instructions for use of the medical device is available on individual product pages.]',
    clear: '[Clear]',
    yourSelections: '[Your selections:]',
    upc: '[UPC]',
    brandPagetitle: '[{brand} {collection} {productCategory}]',
    productNumber: '[Product Number]',
    allProducts: '[All Products]',
    capProducts: '[Products]',
    filter: '[Filter]',
    alternativeId: '[Alternative ID]',
    industries: '[Industries]',
    showMore: '[Show more]',
    noAdditionalFiltersAvailable: '[No additional filters available.]',
    partNumber: '[Part Number]',
    viewXProducts: '[View {##} Products]',
    optionsAvailable: '[Options available]',
    safetyIndustrialStatement:
        '[The products depicted or mentioned on this page are medical devices. Please always carefully read the instructions for use (if issued) and information on the safe use of the product.]',
    brandCollections: '[Collections]',
    LHNFilteringOptions: '[Filtering Options]'
}
