import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'

/**
 * getTealiumObj
 * @param {string} eventName The tealium event name to assign to the event_name tealium attribute
 * @returns a consistent and standard tealium object for unique SNAPS2 tealium tags
 */
const getTealiumObj = (eventName) => ({
    event_name: eventName,
    event_type: 'Engagement Event',
    event_id: '99',
    experience_type: 'SNAPS2'
})

/**
 * trackSERPOnloadData triggers a Tealium tag with the data layer of the SERP page
 * @param {number} total The total number of products available
 * @param {number} totalResults The total number of products, regulatory, resources, and webcontent data
 * @param {boolean} greater The true | false value returned from ES if there is additional data available
 * @param {string} query The search query
 */
const trackSERPOnloadData = (total, totalResults, greater, query) => {
    TealiumTag({
        number_of_products: total,
        more_search_results: greater,
        search_phrase: query,
        search_results: totalResults,
        unsuccessful_search: total < 1 ? '1' : '0'
    })
}

/**
 * trackPDPData triggers a Tealium tag with PDP data
 * @param {string} eventName
 * @param {string} eventType
 * @param {object} customAttributes to add any other custom {key: value}, i.e commerce connector values
 */
const trackPDPData = (eventName, eventType, customAttributes) => {
    TealiumTag({
        event_name: eventName,
        event_type: eventType,
        experience_type: 'SNAPS2',
        ...customAttributes
    })
}
/**
 * Track Tealium Data on Page Load
 * @param {object} customAttributes object that needs to be tracked on page load
 */
const trackDataOnLoad = (eventName, eventType, customAttributes) => {
    if (typeof window.utag_data === 'undefined') window.utag_data = {}
    window.utag_data = {
        event_name: eventName,
        event_type: eventType,
        experience_type: 'SNAPS2',
        ...customAttributes
    }
}

export { trackSERPOnloadData, getTealiumObj, trackPDPData, trackDataOnLoad }
