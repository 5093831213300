import React, { useContext } from 'react'
import { StateContext } from '../services/StateService'
import { useIsDesktop } from '../services/UtilityService'

const MediaGalleryScripts = () => {
    const {
        endpoints: { snapServerUrl }
    } = useContext(StateContext)

    const isDesktop = useIsDesktop()

    return (
        <>
            {/* Magic360 */}
            <script
                id="Magic360Script"
                type="text/javascript"
                src={`${snapServerUrl}magic360/magic360.js`}
                async
            />
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `
                        var Magic360Options  = {}
                        Magic360Options  = {
                            onready: function(spin) {
                                if(spin.id) {
                                    const event = new CustomEvent('spinnerReady', { detail: {spinnerId: spin.id }});
                                    document?.getElementById(spin.id)?.dispatchEvent(event);
                                }
                            }
                        }
                    `
                }}
            />
            {/* MagicZoom */}
            {isDesktop && (
                <>
                    <script
                        id="MagicZoomScript"
                        type="text/javascript"
                        src={`${snapServerUrl}magiczoomplus/magiczoomplus.js`}
                        async
                    />
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `
                                var mzOptions = {}
                                mzOptions = {
                                    onZoomIn: function() {
                                        if(arguments[0]) {
                                            const event = new CustomEvent('zoomIn', { detail: {magicZoomId: arguments[0] }});
                                            document?.getElementById(arguments[0])?.dispatchEvent(event);
                                        }
                                    },
                                    onZoomOut: function() {
                                        if(arguments[0]) {
                                            const event = new CustomEvent('zoomOut', { detail: {magicZoomId: arguments[0] }});
                                            document?.getElementById(arguments[0])?.dispatchEvent(event);
                                        }
                                    },
                                    onZoomReady: function() {
                                        if(arguments[0]) {
                                            const event = new CustomEvent('zoomReady', { detail: {magicZoomId: arguments[0] }});
                                            document?.getElementById(arguments[0])?.dispatchEvent(event);
                                        }
                                    }
                                }
                            `
                        }}
                    />
                </>
            )}
        </>
    )
}

export default MediaGalleryScripts
