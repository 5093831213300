import React from 'react'
import ProductBlock from '../components/ProductBlock.jsx'
import { getAltID } from '../services/ESService.jsx'

/**
 * This will render the products block and that will display the individual products
 * @param {array} items The items to render in the product gallery
 * @param {function} productTracking The analytics function to call when an item is clicked
 */

const ProductList = ({ products, productTracking }) => {
    const productList = products?.map((product, index) => {
        const altId = getAltID(product)
        return (
            <ProductBlock
                key={index}
                product={product}
                altId={altId}
                onClickTracker={() =>
                    productTracking(product.position, product.stockNumber)
                }
                {...product}
            />
        )
    })

    return productList ?? null
}

export default ProductList
