import React, { useContext } from 'react'
import { MMM_ID, UPC } from '../../constants/constants.js'
import { Translations } from '../../services/TranslationService.jsx'
import { trackPDPData } from '../../services/TealiumService'
import { scrollToSection } from '../../services/UtilityService'
import { StateContext } from '../../services/StateService'
import BazaarVoice from '../../components/BazaarVoice'
import '../../css/Header.scss'

const Header = ({ reviewsRef }) => {
    const translations = useContext(Translations)
    const { pdpPageOffset, productDetails } = useContext(StateContext)

    const { partNumber, optionsAvailable, selectProductOptionsForIDs, productNumber } =
        translations

    return (
        <div className="sps2-pdp_header--container">
            <h1 className="mds-font_header--6 sps2-pdp_header--name">
                {productDetails?.name}
            </h1>
            <div className="sps2-pdp_header--details_container">
                {productDetails?.hasMoreOptions ? (
                    <ul className="sps2-pdp_header--details_container_ids">
                        <li
                            className="mds-font_intro--2 sps2-pdp_header--details_parent_options"
                            key={'Options'}
                            role="note"
                        >
                            {optionsAvailable}
                        </li>
                        <li
                            className="mds-font_body sps2-pdp_header--details_parent_select"
                            key={'Select'}
                            role="note"
                        >
                            {` | ${selectProductOptionsForIDs}.`}
                        </li>
                    </ul>
                ) : (
                    <ul className="sps2-pdp_header--details_container_ids">
                        {productDetails?.partNumber && (
                            <li
                                className="mds-font_paragraph sps2-pdp_header--details_child"
                                key={`${productDetails.partNumber}_partNumber`}
                                role="note"
                            >
                                {`${partNumber} ${productDetails.partNumber}`}
                            </li>
                        )}
                        {productDetails?.productNumber && (
                            <li
                                className="mds-font_paragraph sps2-pdp_header--details_child"
                                key={`${productDetails.productNumber}_productNumber`}
                                role="note"
                            >
                                {`${productNumber} ${productDetails.productNumber}`}
                            </li>
                        )}
                        {productDetails?.stockNumber && (
                            <li
                                className="mds-font_paragraph sps2-pdp_header--details_child"
                                key={`${productDetails.stockNumber}_stockNumber`}
                                role="note"
                            >
                                {`${MMM_ID} ${productDetails.stockNumber}`}
                            </li>
                        )}
                        {productDetails?.upc && (
                            <li
                                className="mds-font_paragraph sps2-pdp_header--details_child"
                                key={`${productDetails.upc}_upc`}
                                role="note"
                            >
                                {`${UPC} ${productDetails.upc}`}
                            </li>
                        )}
                    </ul>
                )}
                {productDetails?.bazaarVoiceDisplayZone && (
                    <button
                        className="sps2-content_bv--button"
                        onClick={() => {
                            scrollToSection(reviewsRef, pdpPageOffset)
                            trackPDPData(
                                'PDP Header BazaarVoice Button Click',
                                'Engagement Event'
                            )
                        }}
                    >
                        <BazaarVoice
                            stockNumber={productDetails.stockNumber}
                            bazaarVoiceClientName={productDetails.bazaarVoiceClientName}
                            bazaarVoiceDisplayZone={productDetails.bazaarVoiceDisplayZone}
                            bazaarVoiceProductID={productDetails.bazaarVoiceProductID}
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Header
