import React, { useContext } from 'react'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService'
import { getDecodedHTML } from '../../services/UtilityService'
import '../../css/DisclaimerInformation.scss'

const DisclaimerInformation = ({ disclaimerInformationRef }) => {
    const translations = useContext(Translations)
    const { disclaimerInfo } = useContext(StateContext)

    return (
        <div className="sps2-pdp_disclaimer" ref={disclaimerInformationRef}>
            {disclaimerInfo?.disclaimer && (
                <>
                    <h5 className="sps2-pdp_disclaimer--legal mds-font_intro--1">
                        {translations?.legalDisclaimer}
                    </h5>
                    <div
                        className="mds-margin_xLarge--bottom"
                        dangerouslySetInnerHTML={{
                            __html: disclaimerInfo?.disclaimer
                        }}
                    />
                </>
            )}
            {disclaimerInfo?.extendedIntendedUseDisclaimer && (
                <>
                    <h5 className="sps2-pdp_disclaimer--intended mds-font_intro--1">
                        {disclaimerInfo?.intendedUseDisclaimer}
                    </h5>
                    <p
                        className="sps2-pdp_disclaimer--extended mds-font_body"
                        dangerouslySetInnerHTML={{
                            __html: getDecodedHTML(
                                disclaimerInfo?.extendedIntendedUseDisclaimer
                            )
                        }}
                    ></p>
                </>
            )}
        </div>
    )
}

export default DisclaimerInformation
