import React, { useContext } from 'react'
import { StateContext } from '../services/StateService'
import { getDecodedHTML } from '../services/UtilityService'
import '../css/RichContent.scss'

const RichContent = ({ richContentObj: { html, oid }, className }) => {
    const { highlightRichContent } = useContext(StateContext)
    return (
        <>
            {highlightRichContent && (
                <h1 className="sps2-richContent_highlight--oid">{oid}</h1>
            )}
            <div
                data-oid={oid || ''}
                className={`sps2-richContent ${className || ''} ${
                    highlightRichContent ? 'sps2-richContent_highlight' : ''
                }`}
                dangerouslySetInnerHTML={{ __html: getDecodedHTML(html) }}
            ></div>
        </>
    )
}

export default RichContent
