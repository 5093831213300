import React from 'react'
import { toRelativeURL } from '../services/URLService.jsx'
import { trackPDPData } from '../services/TealiumService.jsx'

/**
 * This is an individual Breadcrumb of the Breadcrumb List for PCP
 * @param {string} value Label of the Breadcrumb.
 * @param {string} removeUrl link to page where the breadcrumb will not be selected.
 * @returns {object} and html section with the one breadcrumb element, li tag.
 */

const BreadCrumb = ({ value, removeUrl }) => {
    if (removeUrl) {
        return (
            <li>
                <a
                    href={toRelativeURL(removeUrl)}
                    onClick={() => {
                        trackPDPData(
                            `PDP BreadCrum Link Click: ${value}`,
                            'Engagement Event'
                        )
                    }}
                >
                    {value}
                </a>
                <i className="MMM--icn MMM--icn_breadcrumb"></i>
            </li>
        )
    } else {
        return <li>{value}</li>
    }
}

export default BreadCrumb
