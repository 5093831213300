import React, { useContext } from 'react'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService.jsx'
import GridContainer from './GridContainer'

const SuggestedApps = () => {
    const translations = useContext(Translations)

    const { suggestedApplications } = translations

    const { productDetails } = useContext(StateContext)

    return (
        <GridContainer
            title={suggestedApplications}
            content={productDetails?.suggestedApplications}
            containerClass="sps2-pdp_details--suggestedApps"
            sectionClass="sps2-pdp_details--section"
        />
    )
}

export default SuggestedApps
