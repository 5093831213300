import React, { useState } from 'react'
import BreadBoxList from '../BreadBoxList.jsx'
import Gallery from '../Gallery.jsx'
import Navigation from '../Navigation/Navigation.jsx'
import ProductList from '../ProductList.jsx'
import FilterBar from '../FilterBar.jsx'
import { FilterToggle, FooterCTAs } from '../Navigation/MobileNavComponents.jsx'
import { getLoadMoreURL, getClearBreadboxLink } from '../../services/URLService.jsx'
import { updateWindowState } from '../../services/BrowserService.jsx'
import { getRequest, postSignalData } from '../../services/AxiosService.jsx'
import { SEARCH_SELECT } from '../../constants/constants.js'
import DesktopOverlay from '../../components/DesktopOverlay.jsx'

/**
 * In order to get the data from the BE set up on the context
 * @param {string} endpoint
 * @param {object} dispatch
 * @param {array} itemsLoaded
 * @param {boolean} focusOnFirst
 * @param {object} callback
 */
const getProductsData = (
    endpoint,
    dispatch,
    itemsLoaded,
    focusOnFirst = false,
    callback
) => {
    getRequest(endpoint)
        .then((result) => {
            // Set attribute to focus on the first returned result
            if (focusOnFirst && result.data.items?.length > 0) {
                result.data.items[0].focusOn = true
            }

            // Update the state with the new items
            dispatch({
                type: 'load_more_products',
                payload: result.data.items
            })

            // using setTimeout allows the callback to be called after the dispatch (otherwise is called before dispatch is reflected)
            if (callback) {
                setTimeout(callback, 0)
            }

            // Update local window state with number of products loaded
            updateWindowState({
                itemsLoaded: itemsLoaded + result.data.items.length
            })
        })
        .catch((error) => {
            dispatch({
                type: 'show_error_message',
                payload: `Error on loading more products: ${error}`
            })
        })
}

/**
 * Displays the products view
 * @param {string} aggregations content do be rendered
 * @param {string} breadBox content do be rendered
 * @param {string} canonicalUrl content do be rendered to avoid duplicate pages.
 * @param {string} dispatch this allows to modify the state if necessary.
 * @param {string} endpoints where the rest of the data is being displayed
 * @param {string} items content do be rendered
 * @param {string} loading relates to the loading icon
 * @param {string} total total amount of products available
 * @returns {object} this will be the react component already set up.
 */
const ProductsView = ({
    aggregations,
    breadBox,
    breadCrumb,
    canonicalUrl,
    dispatch,
    endpoints,
    items,
    loading,
    total,
    allEndpoints,
    currentTab,
    queryId
}) => {
    // Create mobile left hand navigation state
    const [showMobileLHN, setShowMobileLHN] = useState(false)
    const clearLink = getClearBreadboxLink(canonicalUrl)
    const { productsSerpApi, analyticsUrl } = endpoints

    // Function to load more products using axios
    const loadMore = () => {
        const endpoint = getLoadMoreURL(productsSerpApi, items.length)
        getProductsData(endpoint, dispatch, items.length, true)
    }

    return (
        <>
            <FilterBar
                total={total}
                filterToggle={
                    aggregations && (
                        <FilterToggle
                            showMobileLHN={showMobileLHN}
                            setShowMobileLHN={setShowMobileLHN}
                        />
                    )
                }
                breadboxList={
                    <BreadBoxList
                        clearLink={clearLink}
                        breadboxList={breadBox}
                        dynamicLoad={true}
                        currentTab={currentTab}
                    />
                }
            />
            <div className="sps2-lhngallery">
                <DesktopOverlay loading={loading} />
                <Navigation
                    aggregations={aggregations}
                    loading={loading}
                    showMobileLHN={showMobileLHN}
                    setShowMobileLHN={setShowMobileLHN}
                    dynamicLoad={true}
                    currentTab={currentTab}
                    breadBox={breadBox}
                    breadCrumb={breadCrumb}
                    footerCTAs={
                        <FooterCTAs
                            breadBox={breadBox}
                            clearLink={clearLink}
                            setShowMobileLHN={setShowMobileLHN}
                            dynamicLoad={true}
                            dispatch={dispatch}
                            endpoints={allEndpoints}
                            currentTab={currentTab}
                        />
                    }
                />
                <Gallery gallerySize={items?.length} loadMore={loadMore} total={total}>
                    <ProductList
                        products={items}
                        productTracking={(position, stockNumber) => {
                            postSignalData(
                                analyticsUrl,
                                position,
                                SEARCH_SELECT,
                                stockNumber,
                                queryId
                            )
                        }}
                    />
                </Gallery>
            </div>
        </>
    )
}

export { ProductsView as default, getProductsData }
