import React, { useState, useContext } from 'react'
import BreadBox from '../components/BreadBox.jsx'
import { Translations } from '../services/TranslationService.jsx'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { toSentenceCase } from '../services/UtilityService.jsx'
import '../css/BreadBoxList.scss'
import { getTealiumObj } from '../services/TealiumService.jsx'
import { StateContext } from '../services/StateService.jsx'
import { createFilterEndpoint, filterSelection } from '../services/SelectionService.jsx'
import { toRelativeURL, clearAllLinkProdTab } from '../services/URLService.jsx'
import { PRODUCTS_TAB } from '../constants/constants'

/**
 * This is the list of BreadBoxes for the specific SNAPS2 page we are rendering.
 * @param {string} clearLink A URL to clear all breadboxes
 * @param {array} breadboxList An array of breadboxes
 * @returns {object} an html section with the breadbox components for SERP in this case
 */
const BreadBoxList = ({ clearLink, breadboxList, dynamicLoad = false, currentTab }) => {
    const { yourSelections, clear } = useContext(Translations)
    const { dispatch, endpoints } = useContext(StateContext)

    // Hide the breadboxes from the UI before page reloads - helps reflect user's actions in UI before the page reloads
    const [hideBreadboxes, setHideBreadboxes] = useState(false)

    // Local states to define when to show components - helps reflect user's actions in UI before the page reloads
    const showBreadboxComponents = breadboxList?.length > 0 && !hideBreadboxes

    // Label and Clear button components
    const clearOnClick = () => {
        TealiumTag(getTealiumObj('snaps-breadBox Click: ' + toSentenceCase(clear)))
        // let's check if the page is SERP -> dynamicLoad = true.
        if (dynamicLoad) {
            let clearAllApi = createFilterEndpoint(clearLink, endpoints)
            if (currentTab === PRODUCTS_TAB) {
                clearAllApi = clearAllLinkProdTab(clearLink, endpoints)
            }
            filterSelection(clearAllApi, toRelativeURL(clearLink), endpoints, dispatch)
        } else {
            // if not, it has to be a PCP page.
            dispatch({ type: 'set_loading_status', payload: true })
            setHideBreadboxes(true)
        }
    }
    // clear section for PCP.
    const clearAllLink = (
        <a
            className="mds-link mds-font_body sps2-breadBox_clear"
            href={clearLink}
            onClick={clearOnClick}
            rel="nofollow"
        >
            {toSentenceCase(clear)}
        </a>
    )
    // clear section for SERP.
    const clearAllButton = (
        <button
            className="mds-link mds-font_body sps2-breadBox_clear"
            onClick={clearOnClick}
        >
            {toSentenceCase(clear)}
        </button>
    )
    // let's check if we're in SERP or PCP.
    const clearAll = dynamicLoad ? clearAllButton : clearAllLink

    return showBreadboxComponents ? (
        <div className="sps2-breadBox">
            <p className="sps2-breadBox_label">{yourSelections}</p>
            <ul>
                {breadboxList.map((breadBox, index) => {
                    return (
                        <li key={index}>
                            <BreadBox
                                {...breadBox}
                                hide={hideBreadboxes}
                                dynamicLoad={dynamicLoad}
                                currentTab={currentTab}
                            />
                        </li>
                    )
                })}
                <li>{clearAll}</li>
            </ul>
        </div>
    ) : null
}
export default BreadBoxList
