import React, { useContext } from 'react'
import { StateContext } from '../../services/StateService.jsx'

/* Navigation Context for SNAPS2 */
const NavigationContext = React.createContext({})
NavigationContext.displayName = 'Navigation Context'

/**
 * NavigationProvider is the provider and context for a SNAPS2 application
 * it can be used to set relative breadCrumb, breadBox, and dynamicLoad statuses for the navigation component
 * @param {array} breadCrumb breadCrumb array used to set hierarchy selections within navigation component
 * @param {array} breadBox breadBox array used to calculate removeUrl values for dynamic filters and the hierarchy selection
 * @param {boolean} dynamicLoad true | false for if the navigation filters should be filtered using ajax
 * @param {object} children any children to be rendered inside this context
 */
const NavigationProvider = ({ breadCrumb, breadBox, dynamicLoad = false, currentTab, children }) => {
    const state = useContext(StateContext)
    return (
        <NavigationContext.Provider
            value={{
                breadCrumb: breadCrumb ?? state.breadCrumb,
                breadBox: breadBox ?? state.breadBox,
                dispatch: state.dispatch,
                dynamicLoad: dynamicLoad,
                endpoints: state.endpoints,
                currentTab: currentTab ?? state.currentTab
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}

export { NavigationContext, NavigationProvider }
