import React, { useContext } from 'react'
import { Translations } from '../../services/TranslationService.jsx'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import FilterController from './FilterController.jsx'
import FocusTrap from 'focus-trap-react'
import '../../css/Navigation.scss'
import { useIsDesktop } from '../../services/UtilityService.jsx'
import { preventBackgroundScroll } from '../../services/BrowserService.jsx'
import { getTealiumObj } from '../../services/TealiumService.jsx'
import { NavigationProvider } from './NavigationContext.js'
import { PRODUCTS_TAB } from '../../constants/constants'

/**
 * Navigation is the left hand navigation for SNAPS2
 * @param {bool} showMobile True | False value for whether or not the mobile left hand navigation is visible
 * @param {func} setShowMobile Function that sets the showMobile value in parent
 */
const Navigation = ({
    aggregations,
    breadBox, // used to set relative state
    breadCrumb, // used to set relative state
    dynamicLoad, // used to set relative state
    loading = false,
    showMobileLHN = false,
    setShowMobileLHN = () => null,
    footerCTAs,
    currentTab
}) => {
    if (!aggregations) return null // Early exit
    const { filter, noAdditionalFiltersAvailable, LHNFilteringOptions } = useContext(Translations)

    // preventBackgroundScroll when mobile device and menu is showing
    const isMobile = !useIsDesktop()
    preventBackgroundScroll(isMobile && showMobileLHN)

    const { sticky = {}, dynamic = [] } = aggregations
    if(Object.keys(sticky).length === 0 && dynamic.length === 0) return <p className='sps2-lhn'>{noAdditionalFiltersAvailable}</p>

    // Create sticky schema array
    const stickySchema = []
    let {
        categories,
        industries,
        brand,
        docType,
        resourceType,
        contentType,
        brandedCollection,
        brandedCategories
    } = sticky

    // Check to apply this to only to the PRODUCTS_TAB nav
    if (currentTab === PRODUCTS_TAB) {
        if (!categories) categories = {}
        if (!industries) industries = {}
    }

    if (categories) {
        categories.showHierarchy = breadCrumb?.length > 1 // Verifying if we need to show the hierarchy
        stickySchema.push({ ...categories, stickyType: 'categories' })
    }
    if (industries) {
        industries.showHierarchy = !!breadBox?.find((bb) => bb.type === 'industries') // Verifying if we need to show the hierarchy
        stickySchema.push({ ...industries, stickyType: 'industries' })
    }
    if (brand) {
        stickySchema.push(brand)
    }
    if (brandedCategories) {
        brandedCategories.showHierarchy = breadCrumb?.length > 1
        stickySchema.push({ ...brandedCategories, stickyType: 'categories' })
    }
    if (brandedCollection) {
        brandedCollection.showHierarchy = !!breadBox?.find(
            (bb) => bb.type === 'collections'
        )
        stickySchema.push({ ...brandedCollection, stickyType: 'collections' })
    }
    if (docType) {
        docType.isMultiSelect = true // I think this value is missing from the JSON
        dynamic.docType = docType
    }
    if (resourceType) {
        resourceType.isMultiSelect = true
        dynamic.resourceType = resourceType
    }
    if (contentType) {
        contentType.isMultiSelect = true
        dynamic.contentType = contentType
    }

    const stickyFilters = stickySchema.map((filter = {}, index) => {
        return (
            <FilterController
                key={index}
                name={filter?.name}
                facets={filter?.facets}
                selectType="single"
                stickyType={filter.stickyType}
                showHierarchy={filter.showHierarchy}
            />
        )
    })

    // Create dynamic array
    const dynamicFilters = []
    Object.keys(dynamic).forEach((key, index) => {
        dynamicFilters.push(
            <FilterController
                key={index}
                name={dynamic[key].name}
                facets={dynamic[key].facets}
                selectType={dynamic[key].isMultiSelect ? 'multi' : undefined}
            />
        )
    })

    // Return Left Hand Nav of sticky and dynamic filters
    return (
        <NavigationProvider
            breadBox={breadBox}
            breadCrumb={breadCrumb}
            dynamicLoad={dynamicLoad}
            currentTab={currentTab}
        >
            <FocusTrap active={showMobileLHN}>
                <div
                    className={`sps2-lhn--container ${
                        showMobileLHN ? '' : 'sps2-lhn--hidden'
                    }`}
                >
                    {showMobileLHN && loading && <div className="sps2-lhn--overlay" />}
                    <div className="sps2-lhn">
                        <div className="sps2-lhn_header">
                            <p className="mds-font_header--5">{filter}</p>
                            <button
                                aria-label="Close"
                                className="sps2-lhn_close"
                                onClick={() => {
                                    setShowMobileLHN(false)
                                    TealiumTag(
                                        getTealiumObj('snaps-button MOBILE Click: Close')
                                    )
                                }}
                            >
                                <i className="MMM--icn MMM--icn_close"></i>
                            </button>
                        </div>
                        <nav className="sps2-lhn_categories" aria-label={LHNFilteringOptions}>
                            {stickyFilters}
                            {dynamicFilters}
                        </nav>
                        {footerCTAs}
                    </div>
                </div>
            </FocusTrap>
        </NavigationProvider>
    )
}

export default Navigation
