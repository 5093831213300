import React, { useContext, useState } from 'react'
import { Link } from '@3mcom/mds-library/dist/node/index'
import { Translations } from '../services/TranslationService'
import { MMM_ID } from '../constants/PDPConstants'

const NameAndIDsCell = ({ value }) => {
    const translations = useContext(Translations)
    const { more, less, upc, partNumber, nsn, productNumber, catalogNumber } =
        translations
    const [expanded, setExpanded] = useState(false)
    return (
        <div className="sps2-pdp_product-options--name_ids">
            <div className="sps2-pdp_product-options--name_ids_first-row">
                <div className="sps2-pdp_product-options--name_ids_first-row-img-container">
                    <img src={value?.imageUrl} alt={value?.altText} loading="lazy" />
                </div>
                <Link
                    className="mds-link mds-link_primary"
                    href={value?.url}
                    target="_blank"
                    tealiumObj={{
                        event_name: `PDP Buying Zone Product Options Table Link Click: ${value?.name}`,
                        event_type: 'Engagement Event',
                        experience_type: 'SNAPS2'
                    }}
                >
                    {value?.name}
                </Link>
            </div>
            <div className="sps2-pdp_product-options--name_ids_collapsible">
                <p className="mds-font_body sps2-pdp_product-options--name_ids_collapsible-gray--2">
                    {MMM_ID}
                </p>
                <p className="mds-font_body mds-font_body sps2-pdp_product-options--name_ids_collapsible-mmmId">
                    {value?.stockNumber}
                    {expanded ? (
                        <button
                            className="sps2-pdp_no-style-btn"
                            onClick={() => setExpanded(false)}
                        >
                            {less}
                            <span> -</span>
                        </button>
                    ) : (
                        value?.stockNumber &&
                        (value?.upc ||
                            value?.partNumber ||
                            value?.nsn ||
                            value?.productNumber ||
                            value?.catalogNumber) && (
                            <button
                                className="sps2-pdp_no-style-btn"
                                onClick={() => setExpanded(true)}
                            >
                                {more}
                                <span> +</span>
                            </button>
                        )
                    )}
                </p>
                {expanded && (
                    <>
                        {value?.upc && (
                            <>
                                <p className="mds-font_body sps2-pdp_product-options--name_ids_collapsible-gray--2">
                                    {upc}
                                </p>
                                <p className="mds-font_body">{value.upc}</p>
                            </>
                        )}
                        {value?.partNumber ? (
                            <>
                                <p className="mds-font_body sps2-pdp_product-options--name_ids_collapsible-gray--2">
                                    {partNumber}
                                </p>
                                <p className="mds-font_body">{value.partNumber}</p>
                            </>
                        ) : value?.productNumber ? (
                            <>
                                <p className="mds-font_body sps2-pdp_product-options--name_ids_collapsible-gray--2">
                                    {productNumber}
                                </p>
                                <p className="mds-font_body">{value.productNumber}</p>
                            </>
                        ) : (
                            value?.catalogNumber && (
                                <>
                                    <p className="mds-font_body sps2-pdp_product-options--name_ids_collapsible-gray--2">
                                        {catalogNumber}
                                    </p>
                                    <p className="mds-font_body">{value.catalogNumber}</p>
                                </>
                            )
                        )}
                        {value?.nsn && (
                            <>
                                <p className="mds-font_body sps2-pdp_product-options--name_ids_collapsible-gray--2">
                                    {nsn}
                                </p>
                                <p className="mds-font_body">{value?.nsn}</p>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default NameAndIDsCell
