export const SERPTranslations = {
    all: '[All]',
    allResults: '[All Results]',
    allResultsIndustryApplications: '[See all industry applications results]',
    allResultsNews: '[See all news results]',
    allResultsProducts: '[See all product results]',
    allResultsRegulatory: '[See all regulatory results]',
    allResultsResources: '[See all resources results]',
    alternativeId: '[Alternative ID]',
    autoCorrect: '[Search instead for "{query}"]',
    brands: '[Brands]',
    cannotFind: "[Can't find what you're looking for?]",
    capProducts: '[Products]',
    categories: '[Categories]',
    chat: '[Chat]',
    chatUrl: undefined,
    clear: '[Clear]',
    contactUs: '[Have questions, contact us]',
    contentType: '[Content Type]',
    couldNotMatchSearch: "[We couldn't find a match for your search.]",
    documentNumber: '[Document Number]',
    documentType: '[Document Type]',
    email: '[Help Center]',
    emailUrl: '[https://www.3m.com/3M/en_US/company-us/help-center/]',
    featuredCategories: '[Featured Categories]',
    filter: '[Filter]',
    helpMakeBetter: '[Help us make our site better]',
    helpfulLinks: '[Helpful links]',
    industries: '[Industries]',
    industryApplications: '[Industry Applications]',
    loveToHearFeedback:
        "[We'd love to hear from you.  Please leave us some feedback as we are always trying to improve our experiences.]",
    mostPopularCategories: '[Most popular categories]',
    news: '[News]',
    optionsAvailable: '[Options available]',
    phone: '[Phone]',
    phoneNumber: '[(1-888-364-3577)]',
    phoneNumberText1: '[1-888-3M-HELPS]',
    phoneNumberText2: '[1-888-364-3577]',
    phoneNumberText3: '[Call Monday - Friday]',
    phoneNumberText4: '[8AM – 4:30PM CST]',
    previousStockNumber: '[Previous 3M Stock]',
    product: '[product]',
    products: '[products]',
    recentlyViewedProducts: '[Recently viewed products]',
    regulatory: '[Regulatory]',
    relatedSearches: '[Related searches]',
    resources: '[Resources]',
    result: '[result]',
    results: '[results]',
    resultsFor: '[Results for {query}]',
    searchInsteadFor: '[Search instead for]',
    searchResults: '[Search Results]',
    searchTip: '[Search tip:]',
    searchTipText1: '[Enter exact item number or try these intsead]',
    seeAllPartialQueryResults1: '[See all]',
    sendFeedback: '[Send feedback]',
    showAll: '[Show all]',
    showMore: '[Show more]',
    stockNumber: '[3M Stock]',
    upc: '[UPC]',
    viewAllProducts: '[View all products]',
    viewResults: '[View results]',
    yourSelections: '[Your selections:]',
    zeroResultsFor: '[0 Results for {query}'
}
