import React, { useContext, useState, useEffect } from 'react'
import { Translations } from '../../services/TranslationService'
import { StateContext } from '../../services/StateService'
import { Link } from '@3mcom/mds-library/dist/node/index'
import {
    formatMIME,
    formatBytes,
    scrollToSection
} from '../../services/UtilityService.jsx'
import Icons from '@3mcom/mds-library/dist/node/Icons'
import '../../css/DataSheets.scss'

const DataSheets = ({ resourcesRef }) => {
    const translations = useContext(Translations)
    const {
        techDataSheet,
        safetyDataSheet,
        userInstructions,
        priceList,
        viewAllResources,
        frequentlyViewedResources
    } = translations
    const { tds, sds, instructions, pdpPageOffset } = useContext(StateContext)
    const [safetyDataSheetDoc] = sds || []
    const [techDataSheetDoc] = tds || []
    const [userInstructionsDoc, setUserInstructionsDoc] = useState()

    useEffect(() => {
        if (instructions) {
            if (instructions.length > 1) {
                const instructionsDoc = instructions.find(
                    (doc) => doc?.contentTypeCode === 'instructions'
                )
                setUserInstructionsDoc(instructionsDoc || instructions[0])
            } else {
                setUserInstructionsDoc(instructions[0])
            }
        }
    }, [])

    const getTypeAndSize = (doc) => {
        if (doc?.mimeType || doc?.fileSize !== undefined) {
            return ` (${formatMIME(doc.mimeType)}${
                doc?.mimeType ? ', ' : ''
            }${formatBytes(doc.fileSize)})`
        } else return ''
    }

    return (
        <div className="sps2-pdp_pSelector--dataSheets">
            <h6 className="mds-font_header--6">{frequentlyViewedResources}</h6>
            {techDataSheetDoc && (
                <Link
                    href={techDataSheetDoc?.originalUrl}
                    target="_blank"
                    className="sps2-pdp_pSelector--dataSheets-mb-16 mds-link"
                >
                    <Icons.icon_document color="black" />
                    {`${techDataSheet}${getTypeAndSize(techDataSheetDoc)}`}
                </Link>
            )}
            {safetyDataSheetDoc && (
                <Link
                    href={safetyDataSheetDoc?.originalUrl}
                    target="_blank"
                    className="mds-link"
                >
                    <Icons.icon_document color="black" />
                    {`${safetyDataSheet}${getTypeAndSize(safetyDataSheetDoc)}`}
                </Link>
            )}
            {userInstructionsDoc && (
                <Link
                    href={userInstructionsDoc?.originalUrl}
                    target="_blank"
                    className="mds-link"
                >
                    <Icons.icon_document color="black" />
                    {`${
                        userInstructionsDoc?.contentTypeCode === 'instructions'
                            ? userInstructions
                            : priceList
                    }${getTypeAndSize(userInstructionsDoc)}`}
                </Link>
            )}
            <button
                className="sps2-pdp_no-style-btn sps2-pdp_pSelector--dataSheets_viewAll-btn mds-link mds-link_primary"
                onClick={() => scrollToSection(resourcesRef, pdpPageOffset)}
            >
                {viewAllResources}
            </button>
        </div>
    )
}

export default DataSheets
