import React, { useContext, useState, useRef, useEffect, useCallback } from 'react'
import RichContent from './RichContent'
import Spinner from './Spinner'
import Input from '@3mcom/mds-library/dist/node/Input'
import TextArea from '@3mcom/mds-library/dist/node/TextArea'
import Button from '@3mcom/mds-library/dist/node/Button'
import Modal from '@3mcom/mds-library/dist/node/Modal'
import { StateContext } from '../services/StateService'
import { Translations } from '../services/TranslationService'
import { postSupportForm } from '../services/AxiosService'

const ContactUsForm = ({ formContainerRef, setShowForm, showForm }) => {
    const { richContent, contactUs, productDetails } = useContext(StateContext)
    const { messageMailBinId } = contactUs || {}
    const { supportForm } = richContent || {}
    const translations = useContext(Translations)
    const {
        messageUsHeader,
        messageUsIntro,
        firstName,
        lastName,
        emailAddress,
        emailRequired,
        emailAddressRetype,
        emailAddressFailure,
        message,
        messageDetails,
        messageRequired,
        disclaimerOpening,
        disclaimerLinkText,
        disclaimerURL,
        disclaimerPunctuation,
        disclaimerClosing,
        thankYouHeader,
        thankYouMessage,
        submit,
        done,
        invalidEmailAddress
    } = translations

    const modalRef = useRef(null)
    const isFirstRender = useRef(true)
    const formInit = {
        firstName: '',
        lastName: '',
        email: '',
        emailRequired: false,
        emailInvalid: false,
        repeatEmail: '',
        repeatEmailRequired: false,
        repeatEmailInvalid: false,
        message: '',
        messageRequired: false
    }
    const resetInputErrors = {
        email: { emailRequired: false, emailInvalid: false },
        repeatEmail: { repeatEmailRequired: false, repeatEmailInvalid: false },
        message: { messageRequired: false }
    }
    const [emailForm, setEmailForm] = useState({
        ...formInit
    })

    const [sendingMail, setSendingMail] = useState(false)

    useEffect(() => {
        isFirstRender.current = false
    }, [])

    const isEmailValid = (email) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        )
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setEmailForm((prevState) => ({
            ...prevState,
            [name]: value.trim(),
            ...(name === 'email' && {
                emailInvalid: !isEmailValid(value),
                repeatEmailInvalid: value !== emailForm.repeatEmail
            }),
            ...(name === 'repeatEmail' && {
                repeatEmailInvalid: emailForm.email !== value
            })
        }))
    }

    const isFormValid = useCallback(() => {
        return (
            !emailForm.emailInvalid &&
            !emailForm.repeatEmailInvalid &&
            emailForm.message !== ''
        )
    }, [emailForm.emailInvalid, emailForm.repeatEmailInvalid, emailForm.message])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSendingMail(true)
        const date = new Date()
        const data = {
            mail_subject: '3M Product Catalog Inquiry',
            form_key: Math.floor(date.getTime()),
            resolence_morthy_gread: '',
            form_id: messageMailBinId,
            firstName: emailForm.firstName,
            lastName: emailForm.lastName,
            email: emailForm.email,
            emailconfirm: emailForm.repeatEmail,
            message: `${emailForm.message}  \n${window?.location?.href} \n3M ID: ${productDetails?.stockNumber}`
        }
        const dataAsFormUrlEencoded = new URLSearchParams(data)
        postSupportForm(dataAsFormUrlEencoded)
            .then((result) => {
                if (result?.data) {
                    // Activates the 'Thank you for contacting 3M' modal when the mail is sent succesfully
                    modalRef.current.toggle()
                }
            })
            .catch((err) => {
                console.error('There was an error: ' + err)
            })
            .finally(() => {
                setSendingMail(false)
            })
    }

    const handleBlur = (e) => {
        const { name, value } = e.target
        const inputErrors = {
            email: { emailRequired: !value },
            repeatEmail: {
                repeatEmailRequired: !value,
                repeatEmailInvalid: emailForm?.email !== value
            },
            message: { messageRequired: !value }
        }
        setEmailForm((prevState) => ({
            ...prevState,
            ...inputErrors[name]
        }))
    }

    const handleFocus = (e) => {
        const { name } = e.target
        setEmailForm((prevState) => ({
            ...prevState,
            ...resetInputErrors[name]
        }))
    }

    return (
        <section
            className={`sps2-pdp_support--forms${!showForm ? '--hidden' : ''}`}
            ref={formContainerRef}
        >
            {messageMailBinId ? (
                <form onSubmit={handleSubmit} className="sps2-pdp_support--forms-mailbin">
                    <h3
                        className="sps2-pdp_support--forms-mailbin_title mds-font_header--3"
                        tabIndex="-1"
                    >
                        {messageUsHeader}
                    </h3>
                    <p className="sps2-pdp_support--forms-mailbin_description mds-font_paragraph">
                        {messageUsIntro}
                    </p>
                    <div>
                        <Input
                            className="mds-form_element--2up"
                            fieldName={firstName}
                            value={emailForm.firstName}
                            id="contactUs_firstName"
                            name="firstName"
                            onChange={handleOnChange}
                        ></Input>
                        <Input
                            className="mds-form_element--2up"
                            fieldName={lastName}
                            value={emailForm.lastName}
                            name="lastName"
                            onChange={handleOnChange}
                            id="contactUs_lastName"
                        ></Input>
                    </div>

                    <Input
                        fieldName={`${emailAddress}${emailForm.emailRequired ? '' : '*'}`}
                        value={emailForm.email}
                        required={emailForm.emailRequired}
                        requiredLabel={emailRequired}
                        invalid={emailForm.emailInvalid}
                        invalidLabel={invalidEmailAddress}
                        id="contactUs_email"
                        name="email"
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    ></Input>
                    <Input
                        fieldName={`${emailAddressRetype}${
                            emailForm.repeatEmailRequired ? '' : '*'
                        }`}
                        value={emailForm.repeatEmail}
                        requiredLabel={emailAddressFailure}
                        invalid={emailForm.repeatEmailInvalid}
                        invalidLabel={emailAddressFailure}
                        id="contactUs_repeatEmail"
                        name="repeatEmail"
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    ></Input>
                    <TextArea
                        className="sps2-pdp_support--forms-mailbin_message"
                        fieldName={`${message}${emailForm.messageRequired ? '' : '*'}`}
                        value={emailForm.message}
                        required={emailForm.messageRequired}
                        requiredLabel={messageRequired}
                        helpText={messageDetails}
                        help={emailForm.message !== ''}
                        id="contactUs_message"
                        name="message"
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    ></TextArea>
                    <div className="sps2-pdp_support--forms-disclaimer">
                        <p className="mds_font-paragraph">
                            {disclaimerOpening}{' '}
                            <a href={disclaimerURL}>{disclaimerLinkText}</a>
                            {disclaimerPunctuation}
                        </p>

                        <p className="mds_font-paragraph">{disclaimerClosing}</p>
                    </div>
                    <div className="sps2-pdp_support--forms-mailbin_btn">
                        <Button type="submit" disabled={!isFormValid() || sendingMail}>
                            {submit}
                        </Button>
                        {sendingMail && <Spinner />}
                    </div>
                </form>
            ) : (
                supportForm && <RichContent richContentObj={supportForm} />
            )}
            <Modal.Container
                size="small"
                ref={modalRef}
                unmountOnExit={true}
                onCloseCallBack={() => {
                    setEmailForm(formInit)
                    setShowForm(false)
                }}
            >
                <Modal.Heading title={thankYouHeader}></Modal.Heading>
                <Modal.Content>
                    <p className="mds-font_paragraph">{thankYouMessage}</p>
                </Modal.Content>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            modalRef.current.toggle()
                            setEmailForm(formInit)
                            setShowForm(false)
                        }}
                    >
                        {done}
                    </Button>
                </Modal.Footer>
            </Modal.Container>
        </section>
    )
}

export default ContactUsForm
