/**
 * PDP Service
 * Include helper functions, custom hooks, or helper function
 * that applies for PDP logic
 */
import {
    TEBG_DIVISION_CODES_WITH_ALTERNATE_LABEL,
    SIBG_DIVISION_CODES_WITH_ALTERNATE_LABEL,
    IFU_PDF_INLINE_LOCALES,
    MAGIC360_CODE,
    VIDEO_CODE,
    NO_IMAGE_CONTENT_TYPES,
    HCBG_DIVISION_CODES_WITH_ALTERNATE_SALESFORCE
} from '../constants/PDPConstants'

/**
 * Converts the array of buying zone selectors into an object
 * @param {array} selectors the array of selectors
 */
const selectorsArrayToObj = (selectors) => {
    if (Array.isArray(selectors)) {
        return selectors.reduce((previusValue, currentValue) => {
            return {
                ...previusValue,
                [currentValue.identifier]: {
                    ...currentValue,
                    selectedValue: currentValue?.selectedValue
                        ? currentValue?.selectedValue
                        : ''
                }
            }
        }, {})
    } else return null
}

/**
 * It will add the Google Product Structured Data to the head element on the page
 * based on marketplace data, and product details
 */
const addProductStructuredDataScript = (offer) => {
    try {
        const {
            id,
            listPriceHighUom,
            listPriceLowUom,
            media,
            pdpPriceDisplayType,
            name,
            offerCount
        } = offer
        const imgURL = media?.find(
            (img) => img.usageCode !== VIDEO_CODE || img.usageCode !== MAGIC360_CODE
        )?.url
        let injectSchemaData = true

        if (name && id && listPriceHighUom && imgURL) {
            const schemaData = {
                '@context': 'https://schema.org',
                '@type': 'Product',
                name: name,
                sku: id,
                brand: {
                    '@type': 'Brand',
                    name: '3M'
                },
                offers: {
                    '@type': 'Offer',
                    url: 'https://ui.mkpl.3m.com/buyingoptions/' + id,
                    priceCurrency: 'USD',
                    itemCondition: 'https://schema.org/NewCondition',
                    availability: 'https://schema.org/InStock'
                },
                image: [imgURL]
            }

            if (
                listPriceLowUom &&
                listPriceHighUom &&
                pdpPriceDisplayType &&
                pdpPriceDisplayType === 'listboth'
            ) {
                schemaData.offers['@type'] = 'AggregateOffer'
                schemaData.offers.offerCount = offerCount?.activeCount
                schemaData.offers.lowPrice = listPriceLowUom
                schemaData.offers.highPrice = listPriceHighUom
            } else if (listPriceHighUom) {
                schemaData.offers.price = listPriceHighUom
            } else if (listPriceLowUom) {
                schemaData.offers.price = listPriceLowUom
            } else {
                // something wrong don't inject schemaData
                injectSchemaData = false
            }

            if (injectSchemaData) {
                var script = document.createElement('script')
                script.type = 'application/ld+json'
                script.innerHTML = JSON.stringify(schemaData)
                document.getElementsByTagName('head')[0].appendChild(script)
            }
        }
    } catch (error) {
        console.log('PDP Error: Structured data was not added', offer)
    }

    return null
}

/**
 * If one of the division code matches and the locale it's on en_US
 * the specifications label will use the alternateLable parameter
 * @param {*} locale
 * @param {*} divisionCode
 * @param {*} specifications
 * @param {*} specificationsAlternate
 * @returns specifications label based on the paremeters
 */
const getSpecificationsLabel = (
    locale,
    divisionCode,
    specifications,
    specificationsAlternate
) => {
    return (TEBG_DIVISION_CODES_WITH_ALTERNATE_LABEL.includes(divisionCode) &&
        locale === 'en_US') ||
        SIBG_DIVISION_CODES_WITH_ALTERNATE_LABEL.includes(divisionCode)
        ? specificationsAlternate
        : specifications
}

/**
 * getIFUDocument helps with a legal requirement where we need to show the IFU Document right away on pageloads
 * @param {*} locale
 * @param {*} instrucions This where we get the pdf link on load
 * @returns IFU URL
 */
const getIFUDocument = (instrucions, locale) => {
    if (!instrucions?.length || !IFU_PDF_INLINE_LOCALES.includes(locale)) {
        return ''
    }
    const IFUDoc = instrucions?.find((document) => {
        return document?.originalUrl && document?.originalUrl.includes('-IFU')
    })

    return IFUDoc?.originalUrl ?? ''
}

const checkContentType = ({ contentType }) => {
    if (NO_IMAGE_CONTENT_TYPES.includes(contentType)) return false
    return true
}

/**
 * If one of the division code matches a Healthcare code
 * an alternate Salesforce configuration will be used
 * @param {*} divisionCode
 * @param {*} config
 * @param {*} configAlternate
 * @returns Salesforce config values based on the paremeters
 */
const getSalesforceConfig = (
    divisionCode,
    config,
    configAlternate
) => {
    return HCBG_DIVISION_CODES_WITH_ALTERNATE_SALESFORCE.includes(divisionCode)
        ? configAlternate
        : config
}

export {
    selectorsArrayToObj,
    addProductStructuredDataScript,
    getSpecificationsLabel,
    getIFUDocument,
    checkContentType,
    getSalesforceConfig
}
