import React, { useContext } from 'react'
import { StateContext } from '../services/StateService'
import { Translations } from '../services/TranslationService'
import { trackPDPData } from '../services/TealiumService'
import '../css/DisclaimerLinks.scss'
import { scrollToSection } from '../services/UtilityService'

export const DisclaimerLinks = ({ disclaimerInformationRef }) => {
    const { disclaimerInfo, pdpPageOffset } = useContext(StateContext)
    const translations = useContext(Translations)
    return (
        <>
            {disclaimerInfo?.disclaimer && (
                <button
                    className="sps2-pdp_no-style-btn sps2-pdp_disclaimer--link mds-link_primary"
                    onClick={() => {
                        scrollToSection(disclaimerInformationRef, pdpPageOffset)
                        trackPDPData(
                            `PDP Buying Zone Disclaimer Link Click: ${translations.viewLegalDisclaimer}`,
                            'Engagement Event'
                        )
                    }}
                >
                    {translations.viewLegalDisclaimer}
                </button>
            )}
            {disclaimerInfo?.extendedIntendedUseDisclaimer && (
                <button
                    className="sps2-pdp_no-style-btn sps2-pdp_disclaimer--link mds-link_primary"
                    onClick={() => {
                        scrollToSection(disclaimerInformationRef, pdpPageOffset)
                        trackPDPData(
                            `PDP Buying Zone Disclaimer Link Click: ${disclaimerInfo?.intendedUseDisclaimer}`,
                            'Engagement Event'
                        )
                    }}
                >
                    {disclaimerInfo?.intendedUseDisclaimer}
                </button>
            )}
        </>
    )
}

export default DisclaimerLinks
