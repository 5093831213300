import React, { useContext, useState } from 'react'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService.jsx'
import { getRequest } from '../../services/AxiosService.jsx'
import { trackPDPData } from '../../services/TealiumService'
import { isImageURL, getLoadMoreURL } from '../../services/URLService.jsx'
import { NO_IMAGE, RESOURCE_TITLE_LENGTH } from '../../constants/constants.js'
import {
    fancyText,
    removeEmphasis,
    useIsDesktop
} from '../../services/UtilityService.jsx'
import { ProductBar } from '@3mcom/mds-library/dist/node/index'
import Alert from '@3mcom/mds-library/dist/node/Alert'

const RequiredProducts = () => {
    const [isLoading, setIsLoading] = useState(false)

    const translations = useContext(Translations)

    const { requiredProductsZone, showMore, ajaxError } = translations

    const { requiredProducts, requiredProductsMore, endpoints, dispatch, errors } =
        useContext(StateContext)

    const isDesktop = useIsDesktop()

    const loadMoreRequiredProducts = () => {
        setIsLoading(true)

        if (errors?.requiredProductsAjaxError) {
            dispatch({
                type: 'set_AJAX_errors',
                payload: { requiredProductsAjaxError: false }
            })
        }

        const requiredProductsListLength = requiredProducts?.length

        const endpoint = getLoadMoreURL(
            `${endpoints.moreRequiredProducts}/`,
            requiredProductsListLength,
            requiredProductsListLength + 4
        )

        getRequest(endpoint)
            .then((response) => {
                if (response.data) {
                    dispatch({
                        type: 'pdp_load_more_required_products',
                        payload: {
                            ...response.data
                        }
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'set_AJAX_errors',
                    payload: { requiredProductsAjaxError: true }
                })
                dispatch({
                    type: 'show_error_message',
                    payload: `Error loading more Required Products: ${error}`
                })
            })
            .finally(() => {
                setIsLoading(false)
            })

        trackPDPData(
            `PDP Required Products Button Click: ${showMore}`,
            'Engagement Event'
        )
    }

    const onClickLoadMoreButton = () => {
        if (!isLoading) {
            loadMoreRequiredProducts()
        }
    }

    return (
        <div className="sps2-pdp_section">
            <h3 tabIndex="-1" className="mds-font_header--3">
                {requiredProductsZone}
            </h3>
            <ProductBar.Container>
                {requiredProducts?.map((product, i) => {
                    return (
                        <ProductBar.Item key={product.name + i}>
                            <a className="mds-link" href={product.url}>
                                <ProductBar.Image
                                    src={
                                        isImageURL(product?.image)
                                            ? product?.image
                                            : NO_IMAGE
                                    }
                                    alt={removeEmphasis(product?.altText)}
                                />
                            </a>
                            <h6 className="mds-font_header--6 mds-margin_medium--bottom">
                                {product.label}
                            </h6>
                            <ProductBar.Link
                                href={product.url}
                                hrefClass="mds-link mds-link_product"
                                onClick={() =>
                                    trackPDPData(
                                        `PDP Required Product Link Click: ${product.name}`,
                                        'Engagement Event'
                                    )
                                }
                                target="_blank"
                            >
                                {fancyText(product.name, RESOURCE_TITLE_LENGTH)}
                            </ProductBar.Link>
                        </ProductBar.Item>
                    )
                })}
                {((isDesktop && requiredProducts?.length % 4 !== 0) ||
                    (!isDesktop && requiredProducts?.length % 2 !== 0)) && (
                    <ProductBar.Item
                        className="mds-prodBar_item sps2-pdp_blank-item"
                        tabIndex="-1"
                    ></ProductBar.Item>
                )}
                {requiredProductsMore && (
                    <ProductBar.LoadMore>
                        <button
                            onClick={onClickLoadMoreButton}
                            className="mds-button mds-button_tertiary mds-button_tertiary--large "
                        >
                            {showMore}
                        </button>
                    </ProductBar.LoadMore>
                )}
                {errors?.requiredProductsAjaxError && (
                    <div className="sps2-pdp_ajaxError--centered mds-margin_medium--top">
                        <Alert.InContext variant="error">{ajaxError}</Alert.InContext>
                    </div>
                )}
            </ProductBar.Container>
        </div>
    )
}

export default RequiredProducts
