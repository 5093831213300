export { default as CHAT_ICON } from '../../../partials/assets/ChatIcon.svg'
export { default as PHONE_ICON } from '../../../partials/assets/PhoneIcon.svg'
export { default as EMAIL_ICON } from '../../../partials/assets/EmailIcon.svg'

export const NO_IMAGE =
    'https://www.3m.com.lv/3m_theme_assets/themes/3MTheme/assets/images/unicorn/NoImage.svg'
export const LOADER_GIF =
    'https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/lazy-preloader-clear.gif'
export const MOBILE = 767
export const DESKTOP = 960
export const USER_COOKIE = 'AMCV_FEE4BDD95841FCAE0A495C3D%40AdobeOrg'
export const MAX_REQUEST_SIZE = 500
export const FACETS_OPTIONS_SHOWN = 10 // Initial number of filter options to show before user clicks '+Show all'
export const DATA_SHEET_EXCLUDED_DIVISION_CODE = ['EP']
