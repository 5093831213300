import React, { useContext } from 'react'
import BreadCrumb from '../components/BreadCrumb.jsx'
import { v4 as uuidv4 } from 'uuid'
import { StateContext } from '../services/StateService.jsx'
import '../css/BreadCrumbs.scss'

/**
 * This is the list of Breadcrumbs for SNAPS 2
 * uuidv4 package used to create random keys for list component
 * @param {array} breadcrumbsList The array of breadcrumb details
 */
const BreadCrumbList = ({ breadcrumbsList }) => {
    const { fuzeRequest } = useContext(StateContext)
    // Return null when rendering in a FUZE enviroment. FUZE has its own breadcrumbs and we want to avoid duplication.
    if (fuzeRequest) {
        return null
    }

    // This component only render in development environments e.i Localhost & Openshitf
    return (
        <div className="sps2-breadcrumbs">
            <ol className="sps2-breadcrumbs_list">
                {breadcrumbsList?.map((crumb) => (
                    <BreadCrumb
                        key={uuidv4()}
                        value={crumb.value}
                        removeUrl={crumb.removeUrl}
                    />
                ))}
            </ol>
        </div>
    )
}

export default BreadCrumbList
