import React, { useContext } from 'react'
import '../css/JumpLinkSection.scss'
import { Translations } from '../services/TranslationService.jsx'
/* istanbul ignore next */
/**
 * Component that renders a link to the products sections.
 * @param {number} total number of products below, just prodcuts, no results.
 * @param {ref} galleryRef this brings a reference of the Gallery Div.
 * @returns a link with an arrow that points to the products section
 */
const JumpLinkSection = ({ total, galleryRef }) => {
    const { products, product } = useContext(Translations)

    const checkJumpLinkTotal = (total) => {
        var labelOptions = ''
        if (total === 1) {
            labelOptions = product
        } else {
            labelOptions = products
        }
        return labelOptions
    }

    const showGallery = () => {
        galleryRef.current.scrollIntoView(true)
    }

    return (
        <div className="sps2-jumpLinkContainer">
            {
                <div className="js-cp-jumpLink">
                    <button
                        onClick={showGallery}
                        className="mds-button mds-button_tertiary mds-button_tertiary--large"
                    >
                        <i className="MMM--icn MMM--icn_arrowJump"></i>
                        {total} {checkJumpLinkTotal(total)}
                    </button>
                </div>
            }
        </div>
    )
}

export default JumpLinkSection
