// Class used to "Hydrate" the react APP after the server rendering
import React from 'react'
import { render, hydrate } from 'react-dom'
import App from './client/app.jsx'

import mockedData from './e2e/mockData/SERPProductSiteData.json'

/* istanbul ignore next */

// This "if" decides how to initialize React. SSR or "Client Only"
/* istanbul ignore next */
if (!window.__INITIAL_DATA) {
    // Client Only React
    render(
        <React.StrictMode>
            {/* We need to send the same data that the server would like to keep in sync */}
            <App {...mockedData} />
        </React.StrictMode>,
        document.getElementById('SNAPS2_root')
    )
} else {
    // SSR React
    hydrate(
        <React.StrictMode>
            <App {...window.__INITIAL_DATA} />
        </React.StrictMode>,
        document.getElementById('SNAPS2_root')
    )
}
