import React, { useContext } from 'react'
import RichContent from '../../components/RichContent'
import { StateContext } from '../../services/StateService'

const RecommendedIndustries = (props) => {
    const {
        richContent: { recommendedIndustries }
    } = useContext(StateContext)

    return (
        <div
            className="sps2-pdp_details--white_container sps2-pdp_details--recommendedIndustries"
            {...props}
        >
            <div className="sps2-pdp_details--section">
                <RichContent richContentObj={recommendedIndustries}></RichContent>
            </div>
        </div>
    )
}

export default RecommendedIndustries
