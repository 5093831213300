import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Translations } from '../../services/TranslationService'
import Accordion from '@3mcom/mds-library/dist/node/Accordion'
import DataTable from '@3mcom/mds-library/dist/node/DataTable'
import { useIsDesktop } from '../../services/UtilityService.jsx'
import { col1Mobile, col1Desktop, col2Desktop } from './SpecificationsTableCells'
import '../../css/Specifications.scss'
import { TECHSPEC_DIMENSIONS } from '../../constants/PDPConstants'

const Specifications = ({
    specificationsRef,
    classificationAttributes,
    specificationsLabel
}) => {
    const isDesktop = useIsDesktop()

    const translations = useContext(Translations)

    const { details, dimensionsClassifications } = translations

    const [detailsDesktopData, setDetailsDesktopData] = useState([])
    const [detailsMobileData, setDetailsMobileData] = useState([])
    const [dimClassDesktopData, setDimClassDesktopData] = useState([])
    const [dimClassMobileData, setDimClassMobileData] = useState([])

    const mobileColumns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'column1',
                Cell: col1Mobile
            }
        ],
        [isDesktop]
    )

    const desktopColumns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'column1',
                Cell: col1Desktop,
                style: {
                    width: '25%',
                    maxWidth: '350px'
                }
            },
            {
                Header: '',
                accessor: 'column2',
                Cell: col2Desktop
            }
        ],
        [isDesktop]
    )

    useEffect(() => {
        const tempdetailsMobileData = []
        const tempdetailsDesktopData = []
        const tempdimClassMobileData = []
        const tempdimClassDesktopData = []

        classificationAttributes?.forEach((attribute) => {
            if (attribute?.assignment === TECHSPEC_DIMENSIONS) {
                tempdimClassDesktopData.push({
                    column1: attribute?.label,
                    column2: attribute?.values.join(', ')
                })
                tempdimClassMobileData.push({
                    column1: {
                        label: attribute?.label,
                        values: attribute?.values.join(', ')
                    }
                })
            } else {
                tempdetailsDesktopData.push({
                    column1: attribute?.label,
                    column2: attribute?.values.join(', ')
                })
                tempdetailsMobileData.push({
                    column1: {
                        label: attribute?.label,
                        values: attribute?.values.join(', ')
                    }
                })
            }
        })
        setDetailsDesktopData(tempdetailsDesktopData)
        setDetailsMobileData(tempdetailsMobileData)
        setDimClassDesktopData(tempdimClassDesktopData)
        setDimClassMobileData(tempdimClassMobileData)
    }, [classificationAttributes])

    const dimensionsClassificationsAccordion =
        dimClassDesktopData?.length > 0 || dimClassMobileData?.length > 0 ? (
            <Accordion.Accordion
                show={true}
                tealiumObj={{
                    event_name:
                        'PDP Specifications Dimensions and Classifications Accordion Click',
                    event_type: 'Engagement Event',
                    experience_type: 'SNAPS2'
                }}
            >
                <Accordion.Heading title={dimensionsClassifications}></Accordion.Heading>
                <Accordion.Content tag="div">
                    {isDesktop && dimClassDesktopData.length > 0 ? (
                        <DataTable.Table
                            columns={desktopColumns}
                            data={dimClassDesktopData}
                            percentageBase={true}
                            sticky={false}
                            disableHeader={true}
                            pageLength={dimClassDesktopData.length}
                        ></DataTable.Table>
                    ) : !isDesktop && dimClassMobileData.length > 0 ? (
                        <DataTable.Table
                            columns={mobileColumns}
                            data={dimClassMobileData}
                            percentageBase={true}
                            sticky={false}
                            disableHeader={true}
                            pageLength={dimClassMobileData.length}
                        ></DataTable.Table>
                    ) : null}
                </Accordion.Content>
            </Accordion.Accordion>
        ) : null

    if (classificationAttributes?.length === 0) return null

    return (
        <div ref={specificationsRef} className="sps2-pdp_section sps2-pdp_specifications">
            <h3 className="mds-font_header--3" tabIndex="-1">{specificationsLabel}</h3>
            <Accordion.Accordion
                show={true}
                tealiumObj={{
                    event_name: 'PDP Specifications Details Accordion Click',
                    event_type: 'Engagement Event',
                    experience_type: 'SNAPS2'
                }}
            >
                <Accordion.Heading title={details}></Accordion.Heading>
                <Accordion.Content tag="div">
                    {isDesktop && detailsDesktopData.length > 0 ? (
                        <DataTable.Table
                            columns={desktopColumns}
                            data={detailsDesktopData}
                            percentageBase={true}
                            sticky={false}
                            disableHeader={true}
                            pageLength={detailsDesktopData.length}
                        ></DataTable.Table>
                    ) : !isDesktop && detailsMobileData.length > 0 ? (
                        <DataTable.Table
                            columns={mobileColumns}
                            data={detailsMobileData}
                            percentageBase={true}
                            sticky={false}
                            disableHeader={true}
                            pageLength={detailsMobileData.length}
                        ></DataTable.Table>
                    ) : null}
                </Accordion.Content>
            </Accordion.Accordion>
            {dimensionsClassificationsAccordion}
        </div>
    )
}

export default Specifications
