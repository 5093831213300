import React, { useEffect, useRef } from 'react'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import MultipleSelection from './selectionTypes/MultipleSelection.jsx'
import SingleSelection from './selectionTypes/SingleSelection.jsx'
import { useIsDesktop } from '../../services/UtilityService.jsx'
import { toRelativeURL } from '../../services/URLService.jsx'
import { getTealiumObj } from '../../services/TealiumService.jsx'

/**
 * Selection is the object that returns a selection option on the LHN filter group
 * @param {string} name Name of the filter type (Ex. 'Color')
 * @param {object} facets Selection object that has these values: 'value', 'count', 'url'
 * @param {string} selectType The selection type of the filter - can be 'single', 'multi' or undefined which gives the 'default' link
 * @param {boolean} autoFocus True | false value on when to focus on the selection (used for accessibility purposes)
 * @returns a filter selection object (thin-schema | single-select)
 */
const Selection = ({ name, facets, selectType, autoFocus = false }) => {
    const { value, count, url, api, params, selected, uom } = facets
    const thisElement = useRef()
    let urlParams = ''
    if (params) urlParams = '?' + params
    const fullURL = url + urlParams
    const fullAPI = api + urlParams
    useEffect(() => {
        if (autoFocus && thisElement?.current) {
            thisElement.current.focus()
        }
    }, [])

    // Add '#filter' to URL if mobile for LHN to re-open up upon page load
    const isMobile = !useIsDesktop()

    const selectOptions = (type) => {
        switch (type) {
            case 'single':
                return (
                    <SingleSelection
                        url={toRelativeURL(fullURL) + (isMobile ? '#filter' : '')}
                        ref={thisElement}
                        onClick={() => {
                            TealiumTag(
                                getTealiumObj('snaps-filter_singleSelect Click: ' + value)
                            )
                        }}
                        name={`${value} (${count})`}
                        api={fullAPI}
                    />
                )
            case 'multi':
                return (
                    <MultipleSelection
                        name={name}
                        value={value}
                        count={count}
                        api={fullAPI}
                        url={fullURL}
                        uom={uom}
                        selected={selected}
                        autoFocus={autoFocus}
                        onClick={() => {
                            TealiumTag(
                                getTealiumObj(
                                    'snaps-filter_thinSchema Click: ' +
                                        name +
                                        ': ' +
                                        value
                                )
                            )
                        }}
                    />
                )
            default:
                return (
                    <a
                        href={toRelativeURL(url)}
                        className="sps2-lhn_filters--regular"
                        ref={thisElement}
                        onClick={() => {
                            TealiumTag(getTealiumObj('snaps-filter Click: ' + value))
                        }}
                    >
                        {value} ({count})
                    </a>
                )
        }
    }

    return <li>{selectOptions(selectType)}</li>
}

export default Selection
