import { useState, useEffect } from 'react'
import {
    MOBILE,
    DESKTOP,
    FACETS_OPTIONS_SHOWN,
    NOT_VISIBLE_OFFSET,
    SENTENCE_CASE_LOCALES
} from '../constants/constants.js'
import { Buffer } from 'buffer'

/**
 * getLocale is a function that checks the window URL for a locale and returns it
 * @returns the current locale
 */
const getLocale = () => {
    return global.window?.SETTINGS?.LOCALE ?? 'en_US'
}

/**
 * Method to sort the facets of the sticky values from the aggregations.
 * If the length of the facets is less than facets_options_shown,
 * If the length is greather than, sort the first facets_options_shown,
 * If the showAllFlag truns into true, the complete list needs to be sorted.
 * @param {*} facets the values for each sticky aggregations.
 * @param {*} showAllFlag the flag when the +more btn is pressed
 * in the lhn.
 * @returns returns the facets order.
 */
const sortFacets = (facets, showAllFlag) => {
    // function to sort the values of a list ascendant
    const sortListAlpha = (a, b) => (a.value > b.value ? 1 : -1)
    // less than 10 facets
    // or if more than 10 and +more btn has been activated
    if (facets.length <= FACETS_OPTIONS_SHOWN || showAllFlag) {
        return [...facets.sort(sortListAlpha)]
    }
    // if more than 10 and +more btn hasn't been activated
    const tempFacets = facets.slice(0, FACETS_OPTIONS_SHOWN).sort(sortListAlpha)
    return [...tempFacets, ...facets.slice(FACETS_OPTIONS_SHOWN, facets.length)]
}

/**
 * useIsMobile is a hook for the current screensize being mobile
 * @param {boolean} initialValue The default value of the window screen being a desktop screen
 */
const useIsMobile = (initialValue = false) => {
    const [isMobile, setIsMobile] = useState(initialValue)

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setIsMobile(window.innerWidth <= MOBILE)
        }

        // Add event listener
        window.addEventListener('resize', handleResize)

        // Call handler right away so state gets updated with initial window size
        handleResize()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount

    return isMobile
}

/**
 * useIsDesktop is a hook for the current screensize being desktop or larger
 * @param {boolean} initialValue The default value of the window screen being a desktop screen
 */
const useIsDesktop = (initialValue = true) => {
    const [isDesktop, setIsDesktop] = useState(initialValue)

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setIsDesktop(window.innerWidth >= DESKTOP)
        }

        // Add event listener
        window.addEventListener('resize', handleResize)

        // Call handler right away so state gets updated with initial window size
        handleResize()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount

    return isDesktop
}

/**
 * Function that changes a string to sentence case
 * @param {string} str String to change to sentence case
 * @example 'Make Me Into Sentence Case' would change to: 'Make me into sentence case'
 */
const toSentenceCase = (str) => {
    return str
        ?.toLowerCase()
        .replace(/[a-z]/i, function (letter) {
            return letter.toUpperCase()
        })
        .trim()
}

/**
 * debounce forces a function to wait a certain amount of time before running again.
 * The function is built to limit the number of times a function is called.
 * Taken from https://davidwalsh.name/javascript-debounce-function
 * @param {function} func Function to run after certain wait times
 * @param {number} wait The amount of time in ms to wait before a function is called again
 * @param {bool} immediate True | false value for if the function should run immediately
 */
const debounce = (func, wait, immediate) => {
    var timeout
    return function () {
        var context = this
        var args = arguments
        var later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

/**
 * Removes any <em> tag from the provided string
 * @param {string} string Text snippet provided
 * @returns Returns the string without <em> </em> tags
 */
const removeEmphasis = (string) => {
    if (!string) return
    return string.replace(/<em>|<\/em>/g, '')
}

/**
 * "Cuts" the provided string on the limit specified
 * @param {string} string Provided text
 * @param {Number} limit Last index
 * @returns A substring of the original string with a number of chars equal to limit
 */
const truncateString = (string, limit) => {
    return string.substring(0, limit)
}
/**
 * This function ensures that the provided text is shortened but the emphasized pieces are always visible.
 * @param {string} text Any text that contains HTML emphasis tags "<em> </em>"
 * @returns A version of the text of length equal to MAX_CHAR_LENGTH
 */
/* istanbul ignore next */
const fancyText = (text = '', MAX_CHAR_LENGTH) => {
    /**
     * text example: 3M™ Scotchrap™ Vinyl Corrosion Protection <em>Tape</em> 50, Unprinted, 50.8 mm x 30.48 m, <em>Black</em>, 1 Roll/Carton
     * ... where Tape & Black are matched terms resulting from the search query
     */
    if (!text || !MAX_CHAR_LENGTH) return text // Early exit
    const ELLIPSIS_LENGTH = 4 // Plus a space
    const notFound = -1
    const cleanName = removeEmphasis(text)
    const emLength = 9 // Length of <em> </em> tags
    const actualLength = cleanName.length // Length without <em> </em>

    if (actualLength <= MAX_CHAR_LENGTH) return text // Case 1: Name shorter than char limit
    if (text.indexOf('<em>') === notFound) {
        // Case 2: No match found in name
        return `${text.substring(0, MAX_CHAR_LENGTH - ELLIPSIS_LENGTH + 1)}...`
    }

    let fancyName = ''
    const regExp = /<em>(.*?)<\/em>/g
    const processedMatches = []
    const matches = text.matchAll(regExp)
    const matchCount = text.match(regExp)?.length
    let previousMatch = {}

    for (const match of matches) {
        const emLengthPerMatch = emLength * processedMatches.length
        const matchIndex = match.index - emLengthPerMatch
        const matchLength = match[1].length // Length without <em> </em>
        const matchLastIndex = matchIndex + matchLength // Where does the matched word ends?
        const isRepeatedMatch = processedMatches.includes(match[1])
        let truncateLimit = MAX_CHAR_LENGTH - matchLength - ELLIPSIS_LENGTH

        if (isRepeatedMatch && matchLastIndex >= MAX_CHAR_LENGTH) {
            // Case 3: A term that was already matched appears again after char limit, we ignore it
            truncateLimit = MAX_CHAR_LENGTH - ELLIPSIS_LENGTH + emLengthPerMatch
            fancyName = truncateString(text, truncateLimit) + ' ...'
            break
        }

        if (matchLastIndex >= MAX_CHAR_LENGTH) {
            // Case 4: Match found after char limit
            if (truncateLimit <= previousMatch?.matchLastIndex) {
                // Case 4.1: Accommodating match cuts off another match
                truncateLimit =
                    previousMatch.matchIndex + emLength * (processedMatches.length - 1)
                fancyName =
                    truncateString(text, truncateLimit) + ' ...' + previousMatch.match
            } else {
                // Case 4.2: Accommodating match after trimming and adding elipsis
                truncateLimit = truncateLimit + emLengthPerMatch
                fancyName = truncateString(text, truncateLimit) + ' ...' + match[0]
            }
            break
        }

        processedMatches.push(match[1])
        previousMatch = { match: match[0], matchIndex, matchLastIndex, matchLength }

        if (processedMatches.length === matchCount) {
            // Case 5: All matches are in the visisble portion
            truncateLimit = MAX_CHAR_LENGTH - ELLIPSIS_LENGTH
            if (truncateLimit <= previousMatch?.matchLastIndex) {
                // Case 5.1: Accommodating elipsis cuts off a match
                truncateLimit =
                    previousMatch.matchIndex + emLength * (processedMatches.length - 1)
                fancyName =
                    truncateString(text, truncateLimit) + ' ...' + previousMatch.match
            } else {
                // Case 5.2: Accommodating elipsis
                truncateLimit = truncateLimit + emLength * processedMatches.length
                fancyName = truncateString(text, truncateLimit) + ' ...'
            }
        }
    }

    return fancyName
}

/**
 * formatMIME takes and returns a clean UI friendly data type string
 * @param {string} type The type of the data (ex. 'application/x-photoshop')
 */
const formatMIME = (type = '') => {
    return (type.split('/')[1] ?? type).toUpperCase()
}

// It takes a string value and wraps it around Parenthesis
const wrapInParenthesis = (string) => {
    return string ? '(' + string + ')' : ''
}

/**
 * formatBytes takes the size of a file and returns the size in bytes
 * @param {number} bytes The size in bytes
 * @param {number} decimals The number of decimals it should be formatted to
 */
const formatBytes = (bytes, decimals = 0) => {
    if (bytes === 0) return '0B'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

/**
 * Removes the underscores from all inputted text
 * @param {string} string required for the Fancy text
 */
const removeUnderscore = (string) => {
    var newFancyText = string
    if (!newFancyText) return
    newFancyText = newFancyText.replace(/_/g, '')
    return newFancyText
}

/**
 * Returns just the numbers and the plus sign from the string PhoneNumber
 * @param {string} string the phone number value, by locale.
 */
const phoneNumberChecker = (string) => {
    const phoneRegEx = string.match(/[0-9\\+]/g)
    const cleanNumber = phoneRegEx.join('')

    return cleanNumber
}

/**
 * Mapping because gpim stores old video data so this function will return the current value
 * map get from SNAPS1
 * @param {string} oldPlayerId id coming from GPIM
 * @returns
 */
const getBrightcovePlayerInfo = (oldPlayerId) => {
    if (!oldPlayerId) return null
    return {
        1300793176001: { dataAccountId: 782847723001, dataPlayerId: 'Bkh3xE37x' },
        1363991536001: { dataAccountId: 1231248590001, dataPlayerId: 'HkONzVnme' },
        1429744018001: { dataAccountId: 1226740749001, dataPlayerId: 'BJEzbH3Qx' },
        1493748004001: { dataAccountId: 1231248591001, dataPlayerId: 'rJ83GNh7l' },
        1494079984001: { dataAccountId: 958462695001, dataPlayerId: 'rkeWX7Vnml' },
        1300495914001: { dataAccountId: 900160696001, dataPlayerId: 'r1yj74hXe' },
        1490404457001: { dataAccountId: 1231248592001, dataPlayerId: 'SkxfVNnXl' },
        3234636295001: { dataAccountId: 1231248593001, dataPlayerId: 'SkwmgVnQg' },
        1273986095001: { dataAccountId: 1265527901001, dataPlayerId: 'ryw20m2ml' },
        3259523391001: { dataAccountId: 2635130879001, dataPlayerId: 'HyfM0801x' },
        3255438718001: { dataAccountId: 3251589553001, dataPlayerId: 'Sklbdy4hmg' }
    }[oldPlayerId]
}

const getDecodedHTML = (encodedHTML) => {
    if (typeof encodedHTML === 'string') {
        return Buffer.from(encodedHTML, 'base64').toString('utf8')
    } else return undefined
}

const encodeHTML2Base64 = (decodedHTML) => {
    if (typeof decodedHTML === 'string') {
        return Buffer.from(decodedHTML, 'utf8').toString('base64')
    } else return undefined
}

/**
 * Function that capitalize each first letter of a string
 * @param {string} str String to change
 * @example 'This is an example' would change to: 'This Is An Example'
 */
const capitalizeEachFirstLetter = (str = '', locale) => {
    if (!SENTENCE_CASE_LOCALES.includes(locale)) {
        return str
            .toLowerCase()
            .split(' ')
            .map((s) => {
                if (s.charCodeAt(0) !== 91) {
                    return s.charAt(0).toUpperCase() + s.substring(1)
                } else {
                    return s.charAt(0) + s.charAt(1).toUpperCase() + s.substring(2)
                }
            })
            .join(' ')
    }
    return str
}

/**
 * Function that transforms HTML entities (&trade, &rsquo) into readable text
 * @param {string} str String to be transformed
 * @example I&#39;' would change to: I'm
 */

const decodeHTMLEntities = (str) => {
    if (typeof document !== 'undefined') {
        const txt = document.createElement('textarea')
        txt.innerHTML = str
        return txt.value
    }
    return str
}

/**
 * Scroll to section, it will be change after the sticky nav on PDP gets improved
 * @param {*} sectionRef PDP Ref of the section
 */
const scrollToSection = (sectionRef, offset) => {
    scrollTo(0, sectionRef?.current.offsetTop - offset || NOT_VISIBLE_OFFSET)
}

/**
 * Scroll to section for Sticky, add focus to the ref section
 * @param {*} sectionRef PDP Ref of the section
 */
const scrollToSectionAndFocus = (sectionRef, offset) => {
    scrollTo(0, sectionRef?.current.offsetTop - offset || NOT_VISIBLE_OFFSET)
    sectionRef.current
        .querySelectorAll(
            '.mds-font_header--2, .mds-font_header--3, .mds-font_header--4'
        )[0]
        .focus()
}

/**
 * useWindowDimensions is a hook to detect the current window dimensions
 */
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState({
        windowWidth: 0,
        windowHeight: 0
    })

    const handleResize = () =>
        setWindowDimensions({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        })

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        // Call handler right away so state gets updated with initial window size
        handleResize()
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, [window.innerWidth, window.innerHeight])

    return windowDimensions
}

/**
 * A function that allows categorizing the elements of an array of objects by the name of a property
 * of those objects, instead of having to filter all the time
 * @param {array} array array of objects
 * @param {string} property name of a property of the objects, Eg: 'label'
 * @returns A categorized object, Eg:
 * {
 *    'tapes': [
 *        {label:'tapes', value: ''},
 *        {label:'tapes', value: ''}
 *    ],
 *    'abrasives': [
 *        {label:'abrasives', value: ''}
 *    ]
 * }
 */
const groupArrayBy = (array, property) =>
    array.reduce(
        (grouped, element) => ({
            ...grouped,
            [element[property]]: [...(grouped[element[property]] || []), element]
        }),
        {}
    )

export {
    debounce,
    getLocale,
    useIsMobile,
    useIsDesktop,
    toSentenceCase,
    fancyText,
    formatMIME,
    formatBytes,
    removeEmphasis,
    wrapInParenthesis,
    removeUnderscore,
    phoneNumberChecker,
    getBrightcovePlayerInfo,
    sortFacets,
    getDecodedHTML,
    capitalizeEachFirstLetter,
    decodeHTMLEntities,
    scrollToSection,
    scrollToSectionAndFocus,
    useWindowDimensions,
    encodeHTML2Base64,
    groupArrayBy
}
