import React, { useState } from 'react'
import ContactUs from '../../components/ContactUs'
import RichContent from '../../components/RichContent'
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions'
import '../../css/Support.scss'

const formObj = (lightbox, id, title, label, richContentObj, formRef, description) => {
    if (!richContentObj?.html || lightbox) return undefined
    return {
        id: id,
        title: title,
        label: label,
        richContentObj: richContentObj,
        formRef: formRef,
        description: description
    }
}
const initialState = (richContent, ctaInfo, supportSecondaryRef, supportTertiaryRef) => {
    const { leadForm, leadForm2, contactAcquisition } = richContent || {}
    const {
        leadFormTitle,
        leadFormTitle2,
        leadFormLabel,
        leadFormLabel2,
        leadFormDescription,
        leadFormDescription2,
        contactAcquisitionLabel,
        contactAcquisitionTitle,
        contactAcquisitionFormDescription
    } = ctaInfo || {}

    return [
        formObj(
            leadForm?.isLeadLightbox,
            'lead',
            leadFormTitle,
            leadFormLabel,
            leadForm,
            null,
            leadFormDescription
        ),
        formObj(
            leadForm2?.isLead2Lightbox,
            'lead2',
            leadFormTitle2,
            leadFormLabel2,
            leadForm2,
            supportTertiaryRef,
            leadFormDescription2
        ),
        formObj(
            contactAcquisition?.isContactLightbox,
            'content',
            contactAcquisitionTitle,
            contactAcquisitionLabel,
            contactAcquisition,
            supportSecondaryRef,
            contactAcquisitionFormDescription
        )
    ].filter((form) => form !== undefined)
}
const Support = ({
    supportRef,
    supportSecondaryRef,
    supportTertiaryRef,
    richContent,
    displayController,
    ctaInfo
}) => {
    const [richContentForms] = useState(
        initialState(richContent, ctaInfo, supportSecondaryRef, supportTertiaryRef)
    )

    return (
        <div ref={supportRef} className={'sps2-pdp_support'}>
            <ContactUs />
            {richContent?.faqs && <FrequentlyAskedQuestions />}
            {richContentForms.length > 0 &&
                richContentForms.map((form, i) => (
                    <FormSection
                        id={form.id}
                        key={`${i}_richContentForm`}
                        title={form.title}
                        label={form.label}
                        richContentObj={form.richContentObj}
                        formRef={form.formRef}
                        description={form.description}
                    />
                ))}
        </div>
    )
}
const FormSection = ({ id, title, label, richContentObj, formRef, description }) => {
    const displayTitle = title || label
    return (
        <div
            ref={formRef}
            id={id}
            className="sps2-pdp_richContent--form sps2-pdp_wrap--800"
        >
            {displayTitle && (
                <h3
                    className="mds-font_header--3 sps2-pdp_richContent--form-title"
                    tabIndex="-1"
                >
                    {displayTitle}
                </h3>
            )}
            {description && (
                <p className="mds-font_paragraph sps2-pdp_richContent--form-description">
                    {description}
                </p>
            )}
            <RichContent richContentObj={richContentObj} />
        </div>
    )
}

export default Support
