import React, { useState } from 'react'
import RegulatoryBlock from '../../components/RegulatoryBlock.jsx'
import Gallery from '../Gallery.jsx'
import Navigation from '../Navigation/Navigation.jsx'
import BreadBoxList from '../BreadBoxList.jsx'
import FilterBar from '../FilterBar.jsx'
import { FilterToggle, FooterCTAs } from '../Navigation/MobileNavComponents.jsx'
import { getLoadMoreURL, getClearBreadboxLink } from '../../services/URLService.jsx'
import { getRequest, postSignalData } from '../../services/AxiosService.jsx'
import { updateWindowState } from '../../services/BrowserService.jsx'
import { REGULATORY_SELECT } from '../../constants/constants.js'
import DesktopOverlay from '../../components/DesktopOverlay.jsx'

/**
 * get the data from the BE to set up the context with the Regulatory Data.
 * @param {string} endpoint has the correct address where the data is going to be
 * @param {function} dispatch method to modify the data
 * @param {object} callback action after this is done.
 */
const getRegulatoryData = (endpoint, dispatch, callback) => {
    getRequest(endpoint)
        .then((results) => {
            if (results.data) {
                dispatch({
                    type: 'update_regulatory_data',
                    payload: results.data
                })

                // using setTimeout allows the callback to be called after the dispatch (otherwise is called before dispatch is reflected)
                if (callback) {
                    setTimeout(callback, 0)
                }

                // Update local window state with number of items loaded
                updateWindowState({
                    itemsLoaded: results.data.items.length
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: 'show_error_message',
                payload: `Error on loading regulatory items: ${error}`
            })
        })
}

/**
 * Displays the regulatory view when the tab is selected
 * @param {string} items content to be displayed
 * @param {string} total content to be displayed
 * @param {string} aggregations these contain the data for the LHN
 * @param {string} canonicalUrl  content to be displayed
 * @param {string} breadBox content to be displayed
 * @param {object} dispatch content to be displayed
 * @param {string} loading content to be displayed
 * @param {string} endpoints content to be displayed
 * @returns {object} this will be the react component already set up.
 */
const RegulatoryView = ({
    items,
    total,
    aggregations,
    canonicalUrl,
    breadBox,
    dispatch,
    loading,
    endpoints,
    allEndpoints,
    currentTab,
    queryId
}) => {
    const { analyticsUrl, regulatoryApi } = endpoints
    const regulatoryList = items?.map((item) => {
        return (
            <RegulatoryBlock
                key={item.position}
                {...item}
                onClickTracker={() => {
                    postSignalData(
                        analyticsUrl,
                        item.position,
                        REGULATORY_SELECT,
                        undefined,
                        queryId
                    )
                }}
            />
        )
    })

    // Create mobile left hand navigation state
    const [showMobileLHN, setShowMobileLHN] = useState(false)

    const clearLink = getClearBreadboxLink(canonicalUrl)

    const loadMore = () => {
        const endpoint = getLoadMoreURL(regulatoryApi, items.length)
        getRequest(endpoint)
            .then(({ data }) => {
                // Update the state with the new items
                dispatch({
                    type: 'load_more_regulatory',
                    payload: data.items
                })

                // Update local window state with number of items loaded
                updateWindowState({
                    itemsLoaded: items?.length + data.items.length
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'show_error_message',
                    payload: `Error on loading more regulatory items: ${error}`
                })
            })
    }

    return (
        <>
            <FilterBar
                total={total}
                filterToggle={
                    aggregations && (
                        <FilterToggle
                            showMobileLHN={showMobileLHN}
                            setShowMobileLHN={setShowMobileLHN}
                        />
                    )
                }
                breadboxList={
                    <BreadBoxList
                        clearLink={clearLink}
                        breadboxList={breadBox}
                        dynamicLoad={true}
                    />
                }
                resultsTitle={true}
            />
            <div className="sps2-lhngallery">
                <DesktopOverlay loading={loading} />
                <Navigation
                    aggregations={aggregations}
                    breadBox={breadBox}
                    loading={loading}
                    showMobileLHN={showMobileLHN}
                    setShowMobileLHN={setShowMobileLHN}
                    footerCTAs={
                        <FooterCTAs
                            breadBox={breadBox}
                            clearLink={clearLink}
                            setShowMobileLHN={setShowMobileLHN}
                            dynamicLoad={true}
                            dispatch={dispatch}
                            endpoints={allEndpoints}
                            currentTab={currentTab}
                        />
                    }
                />
                <Gallery gallerySize={items?.length} loadMore={loadMore} total={total}>
                    {regulatoryList}
                </Gallery>
            </div>
        </>
    )
}

export { RegulatoryView as default, getRegulatoryData }
