import React, { useMemo, useContext, useEffect, useState, useRef } from 'react'
import DataTable from '@3mcom/mds-library/dist/node/DataTable'
import Link from '@3mcom/mds-library/dist/node/Link'
import NameAndIDsCell from '../../components/NameAndIDsCell'
import Spinner from '../../components/Spinner'
import {
    scrollToSection,
    useIsDesktop,
    useWindowDimensions
} from '../../services/UtilityService'
import { Translations } from '../../services/TranslationService'
import { StateContext } from '../../services/StateService'
import Select from '@3mcom/mds-library/dist/node/Select'
import { trackPDPData } from '../../services/TealiumService'
import '../../css/ProductOptions.scss'

const CTAOrder = [
    'ccSubId',
    'leadFormId',
    'contactAcquisitionId',
    'callToActionLabel',
    'WTBUnavailable'
]

const ProductOptions = ({ buyingZoneRef, productOptionsRef }) => {
    const isDesktop = useIsDesktop()
    const { windowWidth } = useWindowDimensions()
    const [desktopData, setDesktopData] = useState([])
    const [columnIdentifiers, setColumnIdentifiers] = useState([])
    const [desktopCols, setDesktopCols] = useState([])
    const translations = useContext(Translations)
    const [tableHeight, setTableHeight] = useState(0)
    let availabilityOptions = []
    const ref = useRef()

    useEffect(() => {
        setTableHeight(ref.current?.clientHeight)
    }, [ref?.current?.clientHeight])

    const {
        moreOptions: { options, totalSelected },
        productOptions,
        loadingProductOptions: { loading, isUpdate },
        matchingProductOptions,
        dispatch,
        marketplaceTableInfo,
        endpoints,
        pdpPageOffset,
        selectedOptions
    } = useContext(StateContext)

    const mobileColumns = useMemo(
        () => [
            {
                id: 'column1',
                accessor: 'column1',
                Header: '',
                style: {
                    color: 'black'
                }
            },
            {
                id: 'column2',
                accessor: 'column2',
                Header: ''
            }
        ],
        []
    )

    const CTAButton = ({ ctaUrl, ctaLabel }) => (
        <div className="sps2-pdp_product-options--cta">
            <Link
                className={`mds-button mds-button_secondary ${
                    !isDesktop ? 'mds-button_secondary--small' : ''
                }`}
                href={ctaUrl}
                tealiumObj={{
                    event_name: `PDP Buying Zone Product Options Table CTA Button Click: ${ctaLabel}`,
                    event_type: 'Engagement Event',
                    experience_type: 'SNAPS2'
                }}
            >
                {ctaLabel}
            </Link>
        </div>
    )

    const handleChangeOtions = () => {
        scrollToSection(buyingZoneRef, pdpPageOffset)
        trackPDPData(
            `PDP Buying Zone Product Options Link Click: ${translations.changeOptions}`,
            'Engagement Event'
        )
    }

    const getFirstCTA = (product) => {
        const ctaInfoOrdered = Object.keys({ ...product.ctaInfo })
            .filter((cta) => CTAOrder.includes(cta))
            .sort((first, second) => CTAOrder.indexOf(first) - CTAOrder.indexOf(second))
        const isMarketplace = marketplaceTableInfo?.mkplProductsData
            ? marketplaceTableInfo?.mkplProductsData[product.position]?.id
            : false
        const WTBUnavailable =
            ctaInfoOrdered[0] === 'ccSubId' && product.ctaInfo?.showCC === false

        if (isMarketplace) {
            return 'viewBuyingOptions'
        }
        // if Commerce Connector do not have distribuitors available include next btn available
        // if there are no cta available, then "WTBUnavailable message will be shown"
        if (WTBUnavailable) {
            return ctaInfoOrdered[1] || 'wtbUnavailable'
        } else {
            return ctaInfoOrdered[0]
        }
    }

    const PrimaryCTA = ({ value }) => {
        if (value?.ctaInfo) {
            const firstCTA = getFirstCTA(value)
            const ctasComponents = {
                ccSubId: (
                    <CTAButton
                        ctaUrl={value.productDetails?.url}
                        ctaLabel={translations.whereToBuy}
                    />
                ),
                leadFormId: (
                    <CTAButton
                        ctaUrl={`${value.productDetails?.url}#lead`}
                        ctaLabel={value.ctaInfo?.leadFormLabel}
                    />
                ),
                contactAcquisitionId: (
                    <CTAButton
                        ctaUrl={`${value.productDetails?.url}#content`}
                        ctaLabel={value.ctaInfo?.contactAcquisitionLabel}
                    />
                ),
                callToActionLabel: (
                    <CTAButton
                        ctaUrl={value.ctaInfo?.callToActionUrl}
                        ctaLabel={value.ctaInfo?.callToActionLabel}
                    />
                ),
                wtbUnavailable: <p> {translations.wtbUnavailable} </p>
            }
            return ctasComponents[firstCTA] || null
        }
        return null
    }

    const getIdentifierValue = (product, identifier) =>
        product?.classificationAttributes?.find(
            (attribute) => attribute.identifier === identifier
        )

    const getMobileData = (product, index) => {
        if (product && columnIdentifiers.length > 0) {
            const tempData = []
            columnIdentifiers.forEach((col) => {
                const tempObj = {}
                tempObj.column1 = col.label
                const c2 = getIdentifierValue(product, col.identifier)
                tempObj.column2 = c2?.values ? c2.values[0] : ''
                tempData.push(tempObj)
            })

            return tempData
        }
    }

    const CustomHeader = ({ column, index }) => {
        const selector = selectedOptions?.options[column.identifier]
        const onChange = (target) => {
            dispatch({
                type: 'pdp_on_change_selected_options',
                payload: {
                    selector: selector,
                    target: target,
                    allLabel: translations.all
                }
            })
            trackPDPData(
                `PDP Product Options Selector ${selector.label} Click: ${selector.selectedValue}`,
                'Engagement Event'
            )
        }

        return (
            <>
                <div>{column.label}</div>
                <div className="sps2-pdp_pSelector--selectors mds-margin_small--top">
                    <Select
                        id={`${index}_pSelector`}
                        key={`${index}_pSelector`}
                        showFieldName={false}
                        selectLabel={translations.all}
                        disabled={selector.disabled}
                        value={selector?.selectedValue || 'all'}
                        onChange={(e) => onChange(e.target)}
                    >
                        {column?.colSelectors.map((value, i) => (
                            <option
                                disabled={value?.disabled}
                                className={
                                    value?.disabled
                                        ? 'sps2-pdp_pSelector--selectors_disabled'
                                        : ''
                                }
                                key={`${i}_p-value`}
                                value={value?.api}
                                optionlabel={`${value?.value} ${
                                    value?.uom ? value.uom : ''
                                }`}
                            >
                                {`${value?.value} ${value?.uom ? value.uom : ''}${
                                    value?.disabled
                                        ? ` - ${translations.notApplicable}`
                                        : ''
                                }`}
                            </option>
                        ))}
                    </Select>
                </div>
            </>
        )
    }

    /* istanbul ignore next */
    const AvailabilityFilter = ({ column }) => {
        const { filterValue, setFilter } = column
        return availabilityOptions.length > 1 ? (
            <div className="sps2-pdp_pSelector--selectors mds-margin_small--top">
                <Select
                    id="availability_pSelector"
                    key="availability_pSelector"
                    showFieldName={true}
                    selectLabel="All"
                    className="sps2-pdp_pSelector--selectors_thick-black-ring"
                    value={filterValue || translations.all}
                    onChange={(e) =>
                        setFilter(
                            e.target.value === translations.all ? '' : e.target.value
                        )
                    }
                >
                    {availabilityOptions.map((opt) => (
                        <option key={`${opt}-key}`} value={opt} optionlabel={opt}>
                            {opt}
                        </option>
                    ))}
                </Select>
            </div>
        ) : null
    }

    useEffect(() => {
        setColumnIdentifiers(
            options.map((option) => {
                return {
                    label: option.label,
                    identifier: option.identifier
                }
            })
        )
    }, [])

    useEffect(() => {
        if (isDesktop && columnIdentifiers.length > 0) {
            const cols = [
                {
                    id: 'column1',
                    accessor: 'column1',
                    Header: translations.productNameID,
                    Cell: NameAndIDsCell,
                    style: {
                        width: '350px'
                    },
                    Filter: '',
                    sticky: 'left'
                }
            ]
            columnIdentifiers.forEach((column, index) => {
                const selector = selectedOptions?.options[column.identifier]
                const defaultOpt = {
                    api: 'all',
                    value: translations.all,
                    selected: !selector?.selectedValue
                }
                const colSelectors = [defaultOpt].concat(selector?.values)

                cols.push({
                    id: `column${index + 2}`,
                    accessor: `column${index + 2}`,
                    style: {
                        width: '200px'
                    },
                    Header:
                        !selector?.values || selector?.values.length === 1 ? (
                            column?.label
                        ) : (
                            <CustomHeader
                                column={{ ...column, colSelectors }}
                                index={index}
                            />
                        ),
                    Filter: ''
                })
            })

            if (checkCTAColumnData()) {
                cols.push({
                    id: `column${columnIdentifiers.length + 2}`,
                    accessor: `column${columnIdentifiers.length + 2}`,
                    Header: '',
                    className: 'test',
                    Cell: PrimaryCTA,
                    Filter: '',
                    style: {
                        width: '200px'
                    },
                    sticky: 'right',
                    disableSortBy: true
                })
            }

            setDesktopCols(cols)
        }
    }, [columnIdentifiers, selectedOptions, productOptions])

    useEffect(() => {
        if (isDesktop && productOptions?.length > 0 && columnIdentifiers.length > 0) {
            const tempData = []
            productOptions.forEach((product, index) => {
                const tempObj = {}
                tempObj.column1 = product.productDetails
                columnIdentifiers.forEach((col, i) => {
                    const column = getIdentifierValue(product, col.identifier)
                    let dataValue = ''
                    if (Array.isArray(column?.values)) {
                        dataValue = column.values.join(', ')
                    }
                    tempObj[`column${i + 2}`] = dataValue
                })
                if (marketplaceTableInfo?.mkplProductsData?.length > 0) {
                    if (marketplaceTableInfo?.displayAvailavilityColumn) {
                        const availabilityMessage =
                            marketplaceTableInfo?.mkplProductsData[index]
                                ?.availabilityMessage || ''
                        tempObj.availability = availabilityMessage
                    }
                    if (marketplaceTableInfo?.displayPriceColumn) {
                        tempObj.price = !marketplaceTableInfo?.mkplProductsData[index]
                            ?.hidePrice
                            ? marketplaceTableInfo?.mkplProductsData[index]
                            : undefined
                    }
                }
                tempObj[`column${columnIdentifiers.length + 2}`] = product
                tempData.push(tempObj)
            })
            setDesktopData(tempData)
        }
    }, [productOptions, columnIdentifiers])

    const tableContainerStyle = desktopData.length > 6 ? { height: '750px' } : undefined

    const freezeColumns = !!(
        desktopCols.length > 6 ||
        (windowWidth < 1440 && (desktopCols.length - 1) * 200 + 350 > windowWidth - 40)
    )

    const matchingText = `${selectedOptions?.totalSelected} ${translations.matching} ${translations.of} ${totalSelected} ${translations.productOptions}`

    /**
     * check if there is at least one product with CTA Data available!
     */
    const checkCTAColumnData = () => {
        const productWithCTAInfo = productOptions?.find((product) => {
            if (product?.ctaInfo) {
                const cta = getFirstCTA(product)
                if (cta) {
                    return product
                }
            }
        })

        if (productWithCTAInfo) {
            return true
        }
        return false
    }

    const closeProductOptions = () => {
        dispatch({
            type: 'pdp_set_product_options',
            payload: {
                productOptions: [],
                moreOptions: { totalSelected: 0 },
                endpoint: ''
            }
        })
        dispatch({
            type: 'set_AJAX_errors',
            payload: {
                productOptionsAjaxError: false
            }
        })
        buyingZoneRef?.current?.scrollIntoView()
        trackPDPData(
            `PDP Buying Zone Product Options Link Click: ${translations.closeSection}`,
            'Engagement Event'
        )
    }

    return (
        <div className="sps2-pdp_product-options" ref={productOptionsRef} tabIndex="-1">
            {loading && !isUpdate && (
                <div
                    className="sps2-pdp_product-options--loader"
                    style={{ height: tableHeight }}
                >
                    <Spinner />
                </div>
            )}
            {isDesktop && desktopData.length > 0 && desktopCols.length > 0 && (
                <>
                    {loading && isUpdate && (
                        <div
                            className="of sps2-pdp_product-options--loader sps2-pdp_product-options--loader_overlay"
                            style={{ height: tableHeight }}
                        >
                            <Spinner />
                        </div>
                    )}
                    {productOptions?.length > 0 && (
                        <div ref={ref}>
                            <DataTable.Table
                                columns={desktopCols}
                                data={desktopData}
                                disableHeader={false}
                                sort={true}
                                sticky={true}
                                stickyColumns={freezeColumns}
                                percentageBase={false}
                                containerStyle={tableContainerStyle}
                                buttonType="tertiary"
                                pageLength={matchingProductOptions}
                                pagTotalItems={matchingProductOptions}
                                // pagIntermediateText={`${translations.matching} ${translations.of}`}
                                // pagSufixText={translations.products}
                                allowFilters={true}
                            ></DataTable.Table>
                        </div>
                    )}
                    <div className="sps2-pdp_product-options--actions">
                        <button
                            className="sps2-pdp_no-style-btn"
                            onClick={closeProductOptions}
                        >
                            <span className="mds-link_primary">
                                {translations.closeSection}
                            </span>{' '}
                            -
                        </button>
                        <p>{matchingText}</p>
                    </div>
                </>
            )}
            {!isDesktop > 0 && columnIdentifiers.length && productOptions?.length > 0 && (
                <div className="sps2-pdp_product-options--container">
                    <h5 className="mds-font_header--5">{translations.allResults}</h5>
                    <p className="mds-font_body sps2-pdp_product-options--container_counter">{`${productOptions.length} ${translations.of} ${matchingProductOptions} ${translations.products}`}</p>
                    {productOptions.map((product, i) => (
                        <div
                            className="sps2-pdp_product-options--container_option"
                            key={`${i}-${product.productDetails.stockNumber}`}
                        >
                            <NameAndIDsCell value={product.productDetails} />
                            <DataTable.Table
                                columns={mobileColumns}
                                data={getMobileData(product, i)}
                                disableHeader={true}
                            ></DataTable.Table>
                            <div
                                className="sps2-pdp_product-options--container_btn-container"
                                tabIndex={-1}
                            >
                                <PrimaryCTA value={product} />
                            </div>
                        </div>
                    ))}
                    <button
                        className="mds-link_primary sps2-pdp_no-style-btn sps2-pdp_product-options--container_change-options"
                        onClick={handleChangeOtions}
                    >
                        {translations.changeOptions}
                    </button>
                </div>
            )}
        </div>
    )
}

export default ProductOptions
