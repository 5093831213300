/* istanbul ignore file */
import { useState, useEffect } from 'react'
import { showMoreSize } from './BrowserService.jsx'

const regexRemoveUrl = /\/[a-zA-Z]*3M\//

/**
 * getAllProductsURL generates the URL for the 'View all products' link associated with the SERP - No Results Page
 * @param {string} url The canonicalUrl used to get the root before any other filters
 */
const getAllProductsURL = (url) => {
    if (!url) return null
    const categoryBaseURL = new URL(url)
    categoryBaseURL.pathname =
        categoryBaseURL.pathname.split('/').splice(0, 3).join('/') + '/p/'
    return categoryBaseURL.href
}

/**
 * getClientFromEndpoint Returns the ?clien=3mcom from url
 * @param {string} url The endpoint used for sending signals requests
 */
const getClientFromEndpoint = (url) => {
    if (!url) return null
    const endpoint = new URL(url)
    const client = endpoint.searchParams.get('client')
    return client
}

/**
 * getProductsBaseURL generates the URL for the 'Featured Categories' section associated with the SERP - No Results Page
 * This funcion modifies the URL to simulate an empty search that by default return data that represents the top of the categories hierarchy
 * @param {string} url The serp products api
 * @example In openshift -> url = https://development-snaps2.apps.osrosanp.fdc8.p1.openshiftapps.com/api/serp/products/https/www.3m.com/3M/en_US/p/?Ntt=gardfield&client=3mcom
 * @returns https://development-snaps2.apps.osrosanp.fdc8.p1.openshiftapps.com/api/serp/products/https/www.3m.com/3M/en_US/p/?Ntt=&client=3mcom
 * @example In fuze -> url = https://development-snaps2.apps.osrosanp.fdc8.p1.openshiftapps.com/api/serp/products/https/fuzedev.3m.com/3M/en_US/company-us/search/~/?Ntt=gardfield&client=3mcom
 * @returns https://development-snaps2.apps.osrosanp.fdc8.p1.openshiftapps.com/api/serp/products/https/fuzedev.3m.com/3M/en_US/company-us/search/~/?Ntt=&client=3mcom
 *
 */
const getCategoriesBaseURL = (url) => {
    if (!url) return null
    const categoryBaseURL = new URL(url)
    categoryBaseURL.searchParams.set('Ntt', '')
    return categoryBaseURL.href
}

/**
 * getClearBreadboxLink generates the URL for the 'Clear all' button associated with the breadboxes and navigation menu
 * @param {string} url The canonicalUrl used to get the root before any other filters
 */
const getClearBreadboxLink = (url) => {
    if (!url) return

    let initialLink = toRelativeURL(url).split('/i/')[0].split('/b/')[0].split('/bc/')[0]
    initialLink += initialLink[initialLink.length - 1] === '/' ? '' : '/'

    // Remove trailing 'c/' if exists and no categories are present
    if (initialLink.slice(-3) === '/c/') {
        initialLink = initialLink.slice(0, -2)
    }
    // Remove trailing 'pc/' if exists and no categories are present
    if (initialLink.slice(-4) === '/pc/') {
        initialLink = initialLink.slice(0, -3)
    }

    // use react hooks to eliminate server vs client side errors from differences
    const [clearBreadboxLink, setClearBreadboxLink] = useState(initialLink)

    useEffect(() => {
        // Add query to clearBreadboxLink if parameter 'Ntt' exists
        const windowUrl = global.window?.location?.href
        const paramsURL = windowUrl.split('/?')
        // Supports and returns current version of Ntt ('Ntt', 'ntt', 'NTT', etc.)
        const matchedNtt = paramsURL[1]?.match(/Ntt|NTT|ntt/g)
        if (matchedNtt) {
            const formattedUrl = new URL(windowUrl)
            const query = formattedUrl.searchParams.get(matchedNtt)
            setClearBreadboxLink(clearBreadboxLink + '?' + matchedNtt + '=' + query)
        }
    }, [])

    return clearBreadboxLink
}

/**
 * getLoadMoreURL generates the endpoint to fetch more items based on given parameters
 * @param {string} endpoint The show_next endpoint
 * @param {number} itemsLoaded The number of items already loaded
 * @param {number} size (Optional) The number of additional items to fetch
 */
const getLoadMoreURL = (endpoint, itemsLoaded = 0, size) => {
    if (!endpoint) return ''

    const loadMoreURL = new URL(endpoint)

    // Replace current size of load more or append if it doesn't exist
    if (loadMoreURL.href.indexOf('size') > -1) {
        const prevSize = loadMoreURL.searchParams.get('size')
        loadMoreURL.href = loadMoreURL.href.replace(
            'size=' + prevSize,
            'size=' + (size ?? showMoreSize())
        )
    } else {
        loadMoreURL.href +=
            (loadMoreURL.href?.indexOf('?') > 0 ? '&' : '?') +
            'size=' +
            (size ?? showMoreSize())
    }

    // Replace current start of load more or append if it doesn't exist
    if (loadMoreURL.href.indexOf('start') > -1) {
        const prevStart = loadMoreURL.searchParams.get('start')
        loadMoreURL.href = loadMoreURL.href.replace(
            'start=' + prevStart,
            'start=' + itemsLoaded
        )
    } else {
        loadMoreURL.href += '&start=' + itemsLoaded
    }

    return loadMoreURL.href
}

/**
 * joinUrlBaseAndParams returns a new url that concatenates the first passed parameter's base with the second url's parameters
 * @param {string} urlBase The URL that the base will come from
 * @param {string} urlWithParams The URL that the parameters will come from (if any)
 */
const joinUrlBaseAndParams = (urlBase, urlWithParams) => {
    // Get the parameters from urlWithParams
    const parameters = urlWithParams.split('/?')[1]
        ? '/?' + urlWithParams.split('/?')[1]
        : '/'

    // Create and return new endpoint with the endpoint origin and pathname concatenated with the parameters of the urlWithParams
    return urlBase.split('/?')[0] + parameters
}

/**
 * createDynamicRemoveURL returns a new url that concatenates the filterApi URL with the remove url to create a api remove URL
 * @param {string} removeUrl The remove URL that have the previous selections
 * @param {string} apiUrl api URL to filter on the current tab
 */
const createDynamicRemoveURL = (removeUrl, apiUrl) => {
    const filterApi = new URL(apiUrl)
    const removeURL = new URL(removeUrl)
    let newURL = ''

    if (filterApi.href.includes('localhost')) {
        // Localhost fix to make localhost URLs to behave like a real URL
        newURL =
            'localhost:' +
            filterApi.pathname.split(regexRemoveUrl)[0] +
            removeURL.pathname +
            filterApi.search
    } else {
        newURL =
            filterApi.origin +
            filterApi.pathname.split(regexRemoveUrl)[0] +
            removeURL.pathname +
            filterApi.search
    }

    return newURL
}

/**
 * createBreadboxDynamicRemoveURL returns a new url that concatenates the filterApi URL with the remove url to create a api remove URL for breadboxes
 * @param {string} removeUrl The remove URL that have the previous selections
 * @param {string} apiUrl api URL to filter on the current tab
 */
const createBreadboxDynamicRemoveURL = (removeUrl, apiUrl) => {
    const removeURL = new URL(removeUrl)
    const filterApi = new URL(apiUrl)

    let newURL = ''

    if (filterApi.href.includes('localhost')) {
        // Localhost fix to make localhost URLs to behave like a real URL
        newURL =
            'localhost:' +
            filterApi.pathname.split(regexRemoveUrl)[0] +
            removeURL.pathname +
            removeURL.search
    } else {
        newURL =
            filterApi.origin +
            filterApi.pathname.split(regexRemoveUrl)[0] +
            removeURL.pathname +
            removeURL.search
    }

    return newURL
}

/**
 * toRelativeURL takes a absolute url and returns the relative url
 * @param {string} str Url to be made into a relative URL using a regex pattern
 */
const toRelativeURL = (str) => {
    return str.replace(/.*\/\/[^\/]*/, '')
}

const localHostCORSFix = (url) => {
    // Ensures no CORS are thrown for localhost
    if (url.includes('localhost:')) {
        url = 'http://' + url
    }

    return url
}

/**
 * isImageURL takes a URL and uses a regex to verify if it's an image link
 * @param {string} url Url to be verify if it's an image
 */
const isImageURL = (url) => {
    return url?.match(/\.(jpeg|jpg|gif|png)$/) != null
}
/**
 * clearAllLinkProdTab returns a new endpoint url that concatenates the filterApi URL with the remove url to create clear All link for breadboxes
 * @param {string} removeUrl The remove URL that have the previous selections
 * @param {string} endpoints obj that contains api URL to filter
 */

const clearAllLinkProdTab = (removeURL, endpoints) => {
    const filterApi = new URL(endpoints.filterApi)
    let newURL = ''

    if (filterApi.href.includes('localhost')) {
        // Localhost fix to make localhost URLs to behave like a real URL
        newURL =
            'localhost:' +
            filterApi.pathname.split(regexRemoveUrl)[0] +
            removeURL.split('?')[0] +
            '?Ntt=' +
            filterApi.searchParams.get('Ntt')
    } else {
        newURL =
            filterApi.origin +
            filterApi.pathname.split(regexRemoveUrl)[0] +
            removeURL.split('?')[0] +
            '?Ntt=' +
            filterApi.searchParams.get('Ntt')
    }

    return newURL
}

// This is a temporary fix!
const hideContactUsBasedOnLocale = () => {
    const localesToHide = 'en_PR,ja_JP,en_TT,en_LB,en_QA,en_SG,es_ES'
    const url = new URL(global.window.location)
    let hide = false
    let locale = ''

    for (locale of localesToHide.split(',')) {
        if (url.pathname.includes(locale)) {
            hide = true
            break
        }
    }

    return hide
}

export {
    getAllProductsURL,
    getCategoriesBaseURL,
    getClearBreadboxLink,
    getLoadMoreURL,
    joinUrlBaseAndParams,
    toRelativeURL,
    localHostCORSFix,
    isImageURL,
    createDynamicRemoveURL,
    createBreadboxDynamicRemoveURL,
    clearAllLinkProdTab,
    hideContactUsBasedOnLocale,
    getClientFromEndpoint
}
