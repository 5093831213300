import React, { useContext, useState, useEffect } from 'react'
import { Translations } from '../../services/TranslationService.jsx'
import Icons from '@3mcom/mds-library/dist/node/Icons'
import {
    SPECIFICATIONS,
    DETAILS,
    RESOURCES,
    REVIEWS,
    RELATED_PRODUCTS,
    SUPPORT,
    BOUGHT_TOGETHER,
    SCROLL_OFFSET,
    NOT_VISIBLE_OFFSET,
    WINDOW_TOP
} from '../../constants/constants.js'
import {
    useIsDesktop,
    debounce,
    capitalizeEachFirstLetter,
    scrollToSectionAndFocus
} from '../../services/UtilityService.jsx'
import { trackPDPData } from '../../services/TealiumService.jsx'
import { StateContext } from '../../services/StateService.jsx'
import '../../css/StickyNav.scss'

/**
 * All ignoring of lines is due to the need of more testing on the
 * functionality of active buttons. This can only be completed by
 * using the Cypress testing library. We determined it's not worth the
 * effort to add a whole new testing library for one small part of
 * a component.
 */
const StickyNavigation = ({
    buyingZoneRef,
    specificationsRef,
    specificationsLabel,
    detailsRef,
    resourcesRef,
    reviewsRef,
    relatedProductsRef,
    supportRef,
    boughtTogetherRef
}) => {
    const translations = useContext(Translations)
    const { productDetails, dispatch, pdpPageOffset, displayController, locale } =
        useContext(StateContext)

    const {
        details,
        resources,
        reviews,
        relatedProducts,
        support,
        pageTop,
        frequentBoughtTogether
    } = translations

    const [isVisible, setIsVisible] = useState(false)
    const [activeButton, setActiveButton] = useState()
    const [scrollPosition, setScrollPosition] = useState()
    const isDesktop = useIsDesktop()

    useEffect(() => {
        if (!isDesktop) return
        window.addEventListener('scroll', handleScrollPosition)

        return () => {
            window.removeEventListener('scroll', handleScrollPosition)
        }
    }, [isDesktop])

    /* istanbul ignore next */
    useEffect(() => {
        const buyingZoneTop = buyingZoneRef?.current?.offsetTop
        if (scrollPosition >= buyingZoneTop) {
            if (!isVisible) {
                dispatch({
                    type: 'pdp_set_page_offset',
                    payload: { isNavbarSticky: true }
                })
                setIsVisible(true)
            }
            return debounce(handleActiveButton, 200, false)
        } else if (scrollPosition <= buyingZoneTop && isVisible) {
            dispatch({
                type: 'pdp_set_page_offset',
                payload: { isNavbarSticky: false }
            })
            setIsVisible(false)
        }
    }, [scrollPosition])

    const handleScrollPosition = () => {
        const position = window.pageYOffset + SCROLL_OFFSET
        setScrollPosition(position)
    }

    /* istanbul ignore next */
    const handleActiveButton = () => {
        // Temporary way to set the Support button to active until PDP has more content
        const pageBottom =
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight - NOT_VISIBLE_OFFSET
        const specificationsTop = specificationsRef?.current?.offsetTop
        const detailsTop = detailsRef?.current?.offsetTop
        const resourcesTop = resourcesRef?.current?.offsetTop
        const reviewsTop = reviewsRef?.current?.offsetTop
        const relatedProductsTop = relatedProductsRef?.current?.offsetTop
        const supportTop = supportRef?.current?.offsetTop
        const boughtTogetherTop = boughtTogetherRef?.current?.offsetTop

        if (pageBottom) {
            return setActiveButton(SUPPORT)
        } else if (scrollPosition >= specificationsTop && scrollPosition < detailsTop) {
            return setActiveButton(SPECIFICATIONS)
        } else if (scrollPosition >= detailsTop && scrollPosition < resourcesTop) {
            return setActiveButton(DETAILS)
        } else if (scrollPosition >= resourcesTop && scrollPosition < reviewsTop) {
            return setActiveButton(RESOURCES)
        } else if (scrollPosition >= reviewsTop && scrollPosition < relatedProductsTop) {
            return setActiveButton(REVIEWS)
        } else if (
            (scrollPosition >= relatedProductsTop &&
                scrollPosition < boughtTogetherTop) ||
            (scrollPosition >= relatedProductsTop && scrollPosition < supportTop)
        ) {
            return setActiveButton(RELATED_PRODUCTS)
        } else if (scrollPosition >= boughtTogetherTop && scrollPosition < supportTop) {
            return setActiveButton(BOUGHT_TOGETHER)
        } else if (scrollPosition >= supportTop) {
            return setActiveButton(SUPPORT)
        } else return setActiveButton()
    }

    const handleScroll = (refName) => {
        const whereToScroll = {
            [WINDOW_TOP]: () => scrollTo(0, 0),
            [SPECIFICATIONS]: () => scrollToSectionAndFocus(specificationsRef, pdpPageOffset),
            [DETAILS]: () => scrollToSectionAndFocus(detailsRef, pdpPageOffset),
            [RESOURCES]: () => scrollToSectionAndFocus(resourcesRef, pdpPageOffset),
            [REVIEWS]: () => scrollToSectionAndFocus(reviewsRef, pdpPageOffset),
            [RELATED_PRODUCTS]: () => scrollToSectionAndFocus(relatedProductsRef, pdpPageOffset),
            [SUPPORT]: () => scrollToSectionAndFocus(supportRef, pdpPageOffset),
            [BOUGHT_TOGETHER]: () => scrollToSectionAndFocus(boughtTogetherRef, pdpPageOffset)
        }

        return whereToScroll[refName]()
    }

    return (
        <nav
            className={
                isVisible
                    ? 'sps2-pdp_stickyNav--container_sticky'
                    : 'sps2-pdp_stickyNav--container_fixed'
            }
            aria-label="Main"
        >
            {isVisible && (
                <h3 className="mds-font_body sps2-pdp_stickyNav--header">
                    {productDetails?.name}
                </h3>
            )}
            <ul className="sps2-pdp_stickyNav--button_container">
                {displayController?.showDetails && (
                    <li>
                        <button
                            className={
                                activeButton === DETAILS
                                    ? /* istanbul ignore next */
                                      'mds-font_paragraph sps2-pdp_stickyNav--button_nav-active'
                                    : 'mds-font_paragraph sps2-pdp_stickyNav--button_nav'
                            }
                            onClick={() => {
                                handleScroll(DETAILS)
                                trackPDPData(
                                    `PDP Sticky Navigation Button Click: ${details}`,
                                    'Engagement Event'
                                )
                            }}
                        >
                            {capitalizeEachFirstLetter(details, locale)}
                        </button>
                    </li>
                )}
                {displayController?.classificationAttributes && (
                    <li>
                        <button
                            className={
                                activeButton === SPECIFICATIONS
                                    ? /* istanbul ignore next */
                                      'mds-font_paragraph sps2-pdp_stickyNav--button_nav-active'
                                    : 'mds-font_paragraph sps2-pdp_stickyNav--button_nav'
                            }
                            onClick={() => {
                                handleScroll(SPECIFICATIONS)
                                trackPDPData(
                                    `PDP Sticky Navigation Button Click: ${specificationsLabel}`,
                                    'Engagement Event'
                                )
                            }}
                        >
                            {capitalizeEachFirstLetter(specificationsLabel, locale)}
                        </button>
                    </li>
                )}
                {displayController?.showResources && (
                    <li>
                        <button
                            className={
                                activeButton === RESOURCES
                                    ? /* istanbul ignore next */
                                      'mds-font_paragraph sps2-pdp_stickyNav--button_nav-active'
                                    : 'mds-font_paragraph sps2-pdp_stickyNav--button_nav'
                            }
                            onClick={() => {
                                handleScroll(RESOURCES)
                                trackPDPData(
                                    `PDP Sticky Navigation Button Click: ${resources}`,
                                    'Engagement Event'
                                )
                            }}
                        >
                            {capitalizeEachFirstLetter(resources, locale)}
                        </button>
                    </li>
                )}
                {displayController?.showReviews && (
                    <li>
                        <button
                            className={
                                activeButton === REVIEWS
                                    ? /* istanbul ignore next */
                                      'mds-font_paragraph sps2-pdp_stickyNav--button_nav-active'
                                    : 'mds-font_paragraph sps2-pdp_stickyNav--button_nav'
                            }
                            onClick={() => {
                                handleScroll(REVIEWS)
                                trackPDPData(
                                    `PDP Sticky Navigation Button Click: ${reviews}`,
                                    'Engagement Event'
                                )
                            }}
                        >
                            {capitalizeEachFirstLetter(reviews, locale)}
                        </button>
                    </li>
                )}
                {displayController?.showSupport && (
                    <li>
                        <button
                            className={
                                activeButton === SUPPORT
                                    ? /* istanbul ignore next */
                                      'mds-font_paragraph sps2-pdp_stickyNav--button_nav-active'
                                    : 'mds-font_paragraph sps2-pdp_stickyNav--button_nav'
                            }
                            onClick={() => {
                                handleScroll(SUPPORT)
                                trackPDPData(
                                    `PDP Sticky Navigation Button Click: ${support}`,
                                    'Engagement Event'
                                )
                            }}
                        >
                            {capitalizeEachFirstLetter(support, locale)}
                        </button>
                    </li>
                )}
                {displayController?.relatedProducts && (
                    <li>
                        <button
                            className={
                                activeButton === RELATED_PRODUCTS
                                    ? /* istanbul ignore next */
                                      'mds-font_paragraph sps2-pdp_stickyNav--button_nav-active'
                                    : 'mds-font_paragraph sps2-pdp_stickyNav--button_nav'
                            }
                            onClick={() => {
                                handleScroll(RELATED_PRODUCTS)
                                trackPDPData(
                                    `PDP Sticky Navigation Button Click: ${relatedProducts}`,
                                    'Engagement Event'
                                )
                            }}
                        >
                            {capitalizeEachFirstLetter(relatedProducts, locale)}
                        </button>
                    </li>
                )}
                {displayController?.frequentlyBoughtTogether && (
                    <li>
                        <button
                            className={
                                activeButton === BOUGHT_TOGETHER
                                    ? 'mds-font_paragraph sps2-pdp_stickyNav--button_nav-active'
                                    : 'mds-font_paragraph sps2-pdp_stickyNav--button_nav'
                            }
                            onClick={() => {
                                handleScroll(BOUGHT_TOGETHER)
                                trackPDPData(
                                    `PDP Sticky Navigation Button Click: ${frequentBoughtTogether}`,
                                    'Engagement Event'
                                )
                            }}
                        >
                            {capitalizeEachFirstLetter(frequentBoughtTogether, locale)}
                        </button>
                    </li>
                )}
            </ul>
            {isVisible && (
                <button
                    className="sps2-pdp_stickyNav--button_top"
                    onClick={() => {
                        handleScroll(WINDOW_TOP)
                        trackPDPData(
                            `PDP Sticky Navigation Button Click: ${pageTop}`,
                            'Engagement Event'
                        )
                    }}
                >
                    <Icons.icon_chevron_up_black
                        fill="white"
                        className="sps2-pdp_stickyNav--button_top_icon"
                    />
                    <span className="mds-font_header--6 sps2-pdp_stickyNav--button_top_label">
                        {pageTop}
                    </span>
                </button>
            )}
        </nav>
    )
}

export default StickyNavigation
