import React from 'react'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import * as ContentBlock from '../fragments/ContentBlock.jsx'
import {
    fancyText,
    formatMIME,
    formatBytes,
    removeEmphasis,
} from '../services/UtilityService.jsx'
import { isImageURL } from '../services/URLService.jsx'
import { RESOURCE_TITLE_LENGTH, NO_IMAGE } from '../constants/constants.js'
import { getTealiumObj } from '../services/TealiumService.jsx'

/**
 * ResourceBlock component takes resource parameters and returns a customized ContentBox component
 */

/**
 * ResourceBlock component takes resources parameters and returns a customized ContentBox component
 * @param {string} item name of the product
 * @param {string} url string with the location of the image file
 * @param {string} originalUrl alternative text for Accesibility reasons
 * @param {string} title name of the product
 * @param {string} focusOn where to link the click action
 * @param {string} contentType 
 * @param {string} mimeType
 * @param {string} fileSize 
 * @param {string} className for styling purposes
 * @param {string} onClickTracker
 * @returns {component} returns the BazaarVoice set up according the locale.
 */
const ResourceBlock = ({
    item,
    url,
    originalUrl,
    title,
    focusOn,
    contentType,
    mimeType,
    fileSize,
    className,
    onClickTracker = () => null,
}) => {
    const imageURL = isImageURL(url) ? url : NO_IMAGE

    const TealiumEvent = () => {
        TealiumTag(getTealiumObj('snaps-resources Click: ' + removeEmphasis(title)))
    }

    return (
        <ContentBlock.ContentBlock {...item} className={className ?? ''}>
            <ContentBlock.Link
                url={originalUrl}
                className={'mds-link'}
                name={fancyText(title, RESOURCE_TITLE_LENGTH)}
                focusOn={focusOn}
                onClick={(e) => {
                    onClickTracker(e)
                    TealiumEvent()
                }}
                title={removeEmphasis(title)}
            >
                <ContentBlock.Image src={imageURL} altText={removeEmphasis(title)} />
                <ContentBlock.Name
                    name={fancyText(title, RESOURCE_TITLE_LENGTH)}
                    title={removeEmphasis(title)}
                />
            </ContentBlock.Link>
            <div className="sps2-content_data">
                <ContentBlock.DataType
                    name={contentType}
                    type={formatMIME(mimeType)}
                    size={formatBytes(fileSize)}
                />
            </div>
        </ContentBlock.ContentBlock>
    )
}

export default ResourceBlock
