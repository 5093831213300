import React from 'react'
import '../css/ContactBar.scss'
import { phoneNumberChecker } from '../services/UtilityService.jsx'

/**
 * ContactBar.Container
 * @param {string} background Background to apply to the contact bar - supports 'grey'
 * @param {string} className Optional class names to add to the container
 * @param {array} children Children to render inside the container
 * @returns The container of the ContactBar
 */
const Container = ({ background = 'white', className = '', children }) => {
    return (
        <div className={`sps2-contactBar sps2-contactBar--${background} ${className}`}>
            {children}
        </div>
    )
}

/**
 * ContactBar.Heading
 * @param {array} children Content to render as the heading
 * @returns The <h3> wrapper with the appropriate styling
 */
const Heading = ({ children }) => {
    return <h3 className="mds--centered mds-font_header--3">{children}</h3>
}

/**
 * ContactBar.Bar
 * @param {ContactBar.Item} children The bar of items to be rendered; pass ContactBar.Item as children
 * @returns A list of ContactBar.Items
 */
const Bar = ({ children }) => {
    return <ul className="sps2-contactBar_bar mds-margin_xLarge">{children}</ul>
}

/**
 * ContactBar Item
 * @param {string} alt Alternative text to the image / icon
 * @param {html} icon (Optional) HTML or SVG containing the icon to display
 * @param {string} imageUrl (Optional) URL containing the image source
 * @param {string} url The URL / href value for the item
 * @param {string} name The name of the Item
 * @param {string} phone The label for the Phone
 * @param {string} description (Optional) Additional context to provide for the name
 * @returns An item in the ContactBar
 */
const Item = ({
    alt,
    icon,
    imageUrl,
    url,
    name,
    phone,
    phoneNumber,
    description,
    children
}) => {
    return (
        <li className="sps2-contactBar_item">
            <div className="mds--centered">
                {imageUrl && (
                    <img alt={alt} src={imageUrl} className="sps2-contactBar_image" />
                )}
                {icon && <div className="sps2-contactBar_image">{icon()}</div>}
                {url && (
                    <a
                        href={url}
                        className="mds-link_header--secondary sps2-contactBar_link"
                    >
                        {name}
                    </a>
                )}
                {phone && (
                    <a
                        href={'tel:' + phoneNumberChecker(phoneNumber)}
                        className="mds-link_header--secondary sps2-contactBar_link"
                    >
                        {phone}
                    </a>
                )}
                {description && <p>{description}</p>}
                {children}
            </div>
        </li>
    )
}

export { Container, Heading, Bar, Item }
