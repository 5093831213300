import React, { useContext } from 'react'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { Translations } from '../services/TranslationService.jsx'
import * as ContentBlock from '../fragments/ContentBlock.jsx'
import BazaarVoice from './BazaarVoice.jsx'
import { fancyText, removeEmphasis } from '../services/UtilityService.jsx'
import { PRODUCT_TITLE_LENGTH } from '../constants/constants.js'
import { getTealiumObj } from '../services/TealiumService.jsx'

/**
 * ProductBlock component takes product parameters and returns a customized ContentBox component
 * @param {string} product name of the product
 * @param {string} imageUrl string with the location of the image file
 * @param {string} altText alternative text for Accesibility reasons
 * @param {string} name name of the product
 * @param {string} url where to link the click action
 * @param {string} hasMoreOptions information of the product
 * @param {string} stockNumber information of the product
 * @param {string} previousStockNumber information of the product
 * @param {string} upc information of the product
 * @param {string} focusOn information of the product
 * @param {string} altId alternatived id used as the value
 * @param {string} className for styling purposes
 * @returns {component}  returns a customized ContentBox component.
 */

const ProductBlock = ({
    product,
    imageUrl,
    altText,
    name,
    url,
    hasMoreOptions,
    stockNumber,
    previousStockNumber,
    upc,
    focusOn,
    altId,
    className,
    onClickTracker = () => null
}) => {
    const translations = useContext(Translations) // Did not decompose the translations obj because the upc translation collides with the upc value.
    const TealiumEvent = () => {
        TealiumTag(getTealiumObj('snaps-product Click: ' + removeEmphasis(name)))
    }
    // let's try to split the previous stock number
    const splitPreviousStockNumber = (prevStockNumb) => {
        if (prevStockNumb) {
            // we only need the first previous stock number
            return prevStockNumb.split(',')[0]
        }
    }

    return (
        <ContentBlock.ContentBlock className={className ?? ''}>
            <ContentBlock.Link
                className={'mds-link'}
                url={url}
                name={fancyText(name, PRODUCT_TITLE_LENGTH)}
                focusOn={focusOn}
                onClick={(e) => {
                    onClickTracker(e)
                    TealiumEvent()
                }}
                title={removeEmphasis(name)}
            >
                <ContentBlock.Image src={imageUrl} altText={altText} />
                <ContentBlock.Name
                    name={fancyText(name, PRODUCT_TITLE_LENGTH)}
                    title={removeEmphasis(name)}
                />
            </ContentBlock.Link>
            <BazaarVoice
                stockNumber={product.stockNumber}
                bazaarVoiceClientName={product.bazaarVoiceClientName}
                bazaarVoiceDisplayZone={product.bazaarVoiceDisplayZone}
                bazaarVoiceProductID={product.bazaarVoiceProductID}
            />
            <div className="sps2-content_data">
                {hasMoreOptions ? (
                    <ContentBlock.OptionsAvailable
                        label={translations.optionsAvailable}
                    />
                ) : (
                    <>
                        <ContentBlock.InlineData
                            name={translations.stockNumber}
                            value={stockNumber}
                        />
                        <ContentBlock.InlineData
                            name={translations.previousStockNumber}
                            value={splitPreviousStockNumber(previousStockNumber)}
                        />
                        <ContentBlock.InlineData name={translations.upc} value={upc} />
                        {altId && (
                            <ContentBlock.InlineData
                                name={translations.alternativeId}
                                value={altId}
                            />
                        )}
                    </>
                )}
            </div>
        </ContentBlock.ContentBlock>
    )
}

export default ProductBlock
