import React from 'react'
import TealiumTag from '@3mcom/mds-library/dist/node/TealiumTag'
import { fancyText, removeEmphasis } from '../services/UtilityService.jsx'
import { INFO_TITLE_LENGTH, INFO_CONTENT_LENGTH } from '../constants/constants.js'
import { getTealiumObj } from '../services/TealiumService.jsx'
import '../css/InformationBlock.scss'

/**
 * InformationBlock returns a link and description of informational content
 * @param {string} title The title / link name of the information block
 * @param {string} url The link associated with the title
 * @param {string} contentSnippet The description of the component
 */
const InformationBlock = ({ title, url, contentSnippet, onClickTracker }) => {
    const TealiumEvent = () => {
        TealiumTag(getTealiumObj('snaps-information Click: ' + removeEmphasis(title)))
    }

    return (
        <div className="sps2-informationBlock">
            <a
                className="mds-link mds-link_category"
                title={removeEmphasis(title)}
                href={url}
                onClick={() => {
                    TealiumEvent()
                    onClickTracker()
                }}
                dangerouslySetInnerHTML={{
                    __html: fancyText(title, INFO_TITLE_LENGTH),
                }}
            />
            <p
                className="mds-font_intro--2"
                dangerouslySetInnerHTML={{
                    __html: fancyText(contentSnippet, INFO_CONTENT_LENGTH),
                }}
            />
        </div>
    )
}

export default InformationBlock
