import React from 'react'
import ProductCards from '@3mcom/mds-library/dist/node/ProductCards'
import { toRelativeURL } from '../services/URLService.jsx'
import { getTealiumObj } from '../services/TealiumService.jsx'
import { NO_IMAGE } from '../constants/constants.js'
import '../css/CategorySection.scss'

/**
 * This is the list of categories available for the specific SNAPS2 page we are rendering.
 * @param {array} categoriesList array with all the categories available (taken from aggregations.categories.facets)
 */

const CategorySection = ({ categoriesList, locale }) => {
    if (!categoriesList || categoriesList.length === 0) return null
    const lang = locale?.split('_')[0]
    return (
        <div className="sps2-categories">
            <ProductCards.Container arrangementCount={12}>
                {categoriesList.map((category, index) => (
                    <ProductCards.Card
                        key={index}
                        alt_text={category.altText}
                        href={toRelativeURL(category.url)}
                        name={category.value}
                        lang={lang}
                        image={
                            category.imageUrl !== 'No image'
                                ? category.imageUrl
                                : NO_IMAGE
                        }
                        title={category.value}
                        tealiumObj={getTealiumObj(
                            'snaps-category Click: ' + category.value
                        )}
                    />
                ))}
            </ProductCards.Container>
        </div>
    )
}

export default CategorySection
