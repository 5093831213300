import React from 'react'
import PCP from '../pages/PCP.jsx'
import SERP from '../pages/SERP.jsx'
import PDP from '../pages/PDP.jsx'
import PageEnum from '../../server/constants/PageEnum.js'
import '../css/Global.scss'
/* istanbul ignore next */
/**
 * PageRouter tells the SNAPS which page to dislay
 * @param {string} pageKey this is the value that defines what page it is coming as a response.
 * @returns the proper tag to set up the rest of the page.
 */
const PageRouter = ({ pageKey }) => {
    switch (pageKey) {
        case PageEnum.PRODUCT_GROUP:
        case PageEnum.CATALOG:
            return <PCP />

        case PageEnum.SERP:
            return <SERP />

        case PageEnum.PRODUCT_DETAILS:
            return <PDP />

        case PageEnum.PRODUCT_DETAILS_CHILDREN:
            return <PDP />

        default:
            return <h1>Error in the page router component</h1>
    }
}

export default PageRouter
