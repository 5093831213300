import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie'
import { CAMPAIGN_TRACKING_CODE, UTM_TERM } from '../../constants/PDPConstants'
import { trackPDPData, trackDataOnLoad } from '../../services/TealiumService'
import { StateContext } from '../../services/StateService'
import '../../css/WhereToBuy.scss'

const WhereToBuy = ({ ccTemplatedID, ccSubId, mmmId, locale, widgetType }) => {
    if (!ccSubId) return null
    const convertedLocale = locale.replace(/_/, '-')
    const language = locale.split('_')
    const templateID = ccTemplatedID?.charAt(0).toUpperCase() + ccTemplatedID?.slice(1)
    const cookies = new Cookies()
    const { dispatch } = useContext(StateContext)
    useEffect(() => {
        loadCCWTB()
    }, [])

    /**
     * Function that looks for the campaign_tracking_code and adds it to the WTB widget
     * if it's available. If not it will return the WTB widgets with the dataTrackingId
     * as undefined.
     */
    /* istanbul ignore next */
    const loadCCWTB = () => {
        // Creating the script
        console.log(`CCI: ${widgetType}`)
        if (document.querySelector('#cci-widget')) {
            document.querySelector('#cci-widget').remove()
        }
        const CCWTBScript = document.createElement('script')
        CCWTBScript.type = 'text/javascript'
        CCWTBScript.src = 'https://fi-v2.global.commerce-connector.com/cc.js'
        CCWTBScript.id = 'cci-widget'
        CCWTBScript.setAttribute('data-token', '99c976f1d1aab575ceaba5749f1c60fa4df039d9')
        CCWTBScript.setAttribute('data-locale', convertedLocale)
        CCWTBScript.setAttribute('data-displaylanguage', language[0])
        CCWTBScript.setAttribute('data-widgetid', templateID)
        CCWTBScript.setAttribute('data-man', mmmId)
        CCWTBScript.setAttribute('data-subid', ccSubId)
        CCWTBScript.addEventListener('load', () => {
            tealiumTracking()
            dispatch({ type: 'pdp_set_loading_CCWTBScript', payload: false })
        })
        CCWTBScript.addEventListener('error', () => {
            dispatch({ type: 'pdp_set_loading_CCWTBScript', payload: false })
        })
        const urlParams = new URLSearchParams(document.location.search)
        const trackingCode = urlParams.get(UTM_TERM)
        // Check to see if the cookie campaign_tracking_code is available or utm_term is in the url
        if (cookies.get(CAMPAIGN_TRACKING_CODE) || trackingCode) {
            // campaign_tracking_code is available or utm_term found in url, add it to WTB widget
            CCWTBScript.setAttribute(
                'data-trackingid',
                trackingCode || cookies.get(CAMPAIGN_TRACKING_CODE)
            )
            document
                .querySelector('.sps2-pdp_whereToBuy--container')
                .appendChild(CCWTBScript)
        } else {
            // No campaign_tracking_code found or utm_term in the url, init the CC WTB widget without it
            CCWTBScript.setAttribute('data-trackingid', 'undefined')
            document
                .querySelector('.sps2-pdp_whereToBuy--container')
                .appendChild(CCWTBScript)
        }
    }

    /* istanbul ignore next */
    const tealiumTracking = () => {
        window.CCEventTrackingCallback = (eventType, eventObj) => {
            console.log(eventType, eventObj)
            switch (eventType) {
                case 'widget_open_impression':
                    trackDataOnLoad(
                        `PDP Commerce Connector: on load`,
                        'Conversion Event',
                        {
                            data_ean: mmmId,
                            data_displaylanguage: eventObj?.data_displaylanguage,
                            data_inline_widget: eventObj?.inline_widget,
                            data_token: eventObj?.api_token,
                            data_man: mmmId,
                            data_subid: eventObj?.data_subid,
                            data_widgetid: eventObj?.data_widgetid,
                            data_trackingid: eventObj?.data_trackingid
                        }
                    )
                    break
                case 'widget_other_impression':
                    trackPDPData(
                        `PDP Commerce Connector: store and shop list`,
                        'Conversion Event',
                        {
                            data_local_store_name_array: getArrayOfAttributes(
                                eventObj?.local_store_list,
                                'local_store_name'
                            ),
                            data_local_store_position_array: getArrayOfAttributes(
                                eventObj?.local_store_list,
                                'local_store_position'
                            ),
                            data_local_store_address_array: getArrayOfAttributes(
                                eventObj?.local_store_list,
                                'local_store_address'
                            ),
                            data_distance_to_store_array: getArrayOfAttributes(
                                eventObj?.local_store_list,
                                'distance_to_store'
                            ),
                            data_shop_name_array: getArrayOfAttributes(
                                eventObj?.shop_list,
                                'shop_name'
                            ),
                            data_shop_position_array: getArrayOfAttributes(
                                eventObj?.shop_list,
                                'shop_position'
                            ),
                            data_shop_delivery_time: getArrayOfAttributes(
                                eventObj?.shop_list,
                                'delivery_time'
                            )
                        }
                    )
                    break
                case 'store_clickout':
                    trackPDPData(
                        `PDP Commerce Connector: store list item click`,
                        'Conversion Event',
                        {
                            data_local_store_position: eventObj?.local_store_position,
                            data_clickout_type: eventObj?.clickout_type,
                            data_event_type: eventObj?.event_type,
                            data_inline_widget: eventObj?.inline_widget,
                            data_local_store_name: eventObj?.local_store_name,
                            data_local_store_address: eventObj?.local_store_address,
                            data_local_store_id: eventObj?.local_store_id
                        }
                    )
                    break
                case 'shop_clickout':
                    trackPDPData(
                        'PDP Commerce Connector: buy now click',
                        'Conversion Event',
                        {
                            data_event_type: eventObj?.event_type,
                            data_shop_name: eventObj?.shop_name,
                            data_delivery_time: eventObj?.delivery_time,
                            data_shop_position: eventObj?.shop_position,
                            data_shop_id: eventObj?.shop_id,
                            data_clickout_type: eventObj?.clickout_type
                        }
                    )
                    break
                default:
                    trackPDPData(
                        `PDP Commerce Connector: default event`,
                        'Conversion Event',
                        {
                            data_ean: eventObj?.data_ean,
                            data_displaylanguage: eventObj?.data_displaylanguage,
                            data_inline_widget: eventObj?.inline_widget,
                            data_token: eventObj?.api_token,
                            data_man: mmmId,
                            data_subid: eventObj?.data_subid,
                            data_widgetid: eventObj?.data_widgetid,
                            data_trackingid: eventObj?.data_trackingid,
                            data_event_type: eventObj?.event_type
                        }
                    )
                    break
            }
        }
    }

    /* istanbul ignore next */
    /**
     * Converts an object array in a attribute array
     * @param {*} array to get the attribute from
     * @param {*} attributeName attribute to be converted to an array
     * @returns Return array of attributes from an specific array
     */
    const getArrayOfAttributes = (array, attributeName) => {
        return array.map((elem) => elem[attributeName])
    }
    return <div className={`sps2-pdp_whereToBuy--container`}></div>
}

WhereToBuy.defaultProps = {
    ccTemplatedID: 'Emjo9gg796_7541ec30-33d4-11eb-987a-25087ad49e2c103'
}

WhereToBuy.propTypes = {
    ccTemplatedID: PropTypes.string
}

export default WhereToBuy
