import {
    filterItems,
    loadMoreProducts,
    loadMoreResources,
    loadMoreRegulatory,
    loadMoreWebContent,
    updateTotals,
    pdpLoadMoreResources,
    pdpLoadMoreProductOptions,
    pdpLoadMoreMedia,
    pdpSetProductOptions,
    pdpLoadMoreBoughtTogether,
    pdpRelatedProductsCompare,
    pdpSetSelectedOptions,
    showErrorMessage,
    pdpOnChangeSelectedOptions,
    pdpUpdateUIOptions,
    pdpSetLoadingProductOptions,
    pdpSetLoadingCCWTBScript,
    pdpSetOneProductSelection,
    pdpSetActiveMedia,
    setAjaxArrors,
    pdpLoadMoreRequiredProducts
} from './ActionService.jsx'
import { PAGE_OFFSET, NOT_VISIBLE_OFFSET } from '../constants/PDPConstants.js'

// T
/**
 *Reducer is to avoid issues, the state object is inmutable, to alter the state use the functional approach: (state, action) => newState
 * @param {state} state carries the payload with the data to be updated.
 * @param {string} action tells the tool what action to execute
 * @returns {context} an updated contect of the app.
 */
const Reducer = (state, action) => {
    switch (action.type) {
        case 'filter_items':
            return filterItems(state, action)
        case 'set_filter_endpoint':
            return {
                ...state,
                endpoints: { ...state.endpoints, filterApi: action.payload }
            }
        case 'load_more_regulatory':
            return loadMoreRegulatory(state, action)
        case 'load_more_products':
            return loadMoreProducts(state, action)
        case 'load_more_resources':
            return loadMoreResources(state, action)
        case 'load_more_webContent':
            return loadMoreWebContent(state, action)
        case 'update_webContent_data':
            return {
                ...state,
                webContentData: { ...state.webContentData, ...action.payload }
            }
        case 'update_regulatory_data':
            return { ...state, regulatoryData: action.payload }
        case 'update_resources_data':
            return { ...state, resourcesData: action.payload }
        case 'set_loading_status':
            return { ...state, loading: action.payload }
        case 'pdp_set_loading_CCWTBScript':
            return pdpSetLoadingCCWTBScript(state, action)
        case 'update_tabs_totals':
            return updateTotals(state, action)
        case 'pdp_set_product_options':
            return pdpSetProductOptions(state, action)
        case 'pdp_set_loading_product_options':
            return pdpSetLoadingProductOptions(state, action)
        case 'pdp_load_more_resources':
            return pdpLoadMoreResources(state, action)
        case 'pdp_load_more_product_options':
            return pdpLoadMoreProductOptions(state, action)
        case 'pdp_load_more_media':
            return pdpLoadMoreMedia(state, action)
        case 'pdp_load_more_bought_together':
            return pdpLoadMoreBoughtTogether(state, action)
        case 'pdp_related_products_compare':
            return pdpRelatedProductsCompare(state, action)
        case 'pdp_set_selected_options':
            return pdpSetSelectedOptions(state, action)
        case 'pdp_on_change_selected_options':
            return pdpOnChangeSelectedOptions(state, action)
        case 'pdp_update_UI_options':
            return pdpUpdateUIOptions(state, action)
        case 'pdp_set_one_product_selection':
            return pdpSetOneProductSelection(state, action)
        case 'show_error_message':
            return showErrorMessage(state, action)
        case 'pdp_set_page_offset':
            return {
                ...state,
                pdpPageOffset: action.payload.isNavbarSticky
                    ? PAGE_OFFSET
                    : NOT_VISIBLE_OFFSET
            }
        case 'pdp_set_active_media':
            return pdpSetActiveMedia(state, action)
        case 'set_AJAX_errors':
            return setAjaxArrors(state, action)
        case 'pdp_load_more_required_products':
            return pdpLoadMoreRequiredProducts(state, action)
    }
}

export default Reducer
