import React, { Fragment } from 'react'
import { TranslationsProvider } from '../client/services/TranslationService.jsx'
import PageRouter from '../client/services/PageRouterService.jsx'
import { StateProvider } from './services/StateService.jsx'
import Error from './components/Error.jsx'

/**
 * By destructuring the object we dont pass arround props that we dont need to lower/deeper components
 * @param {array} translations the translation from the page we are about to load.
 * @param {array} pageKey this is to tell SNAPS which page to open
 * @param {array} pageProps the content of the page about to be loaded.
 * @returns the structure for the provider with the data.
 */
/* istanbul ignore next */
const App = ({ translations, pageKey, ...pageProps }) => {
    return (
        <TranslationsProvider translations={translations}>
            <StateProvider {...pageProps}>
                <Fragment>
                    <Error />
                    <PageRouter pageKey={pageKey} />
                </Fragment>
            </StateProvider>
        </TranslationsProvider>
    )
}

export default App
