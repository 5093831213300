import React, { useState, useContext, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import ContentCTA from './ContentCTA'
import Button from '@3mcom/mds-library/dist/node/Button'
import EmailUsIcon from '../../../partials/assets/EmailUsIcon.svg'
import ChatLiveIcon from '../../../partials/assets/ChatLiveIcon.svg'
import { StateContext } from '../services/StateService'
import { Translations } from '../services/TranslationService'
import { SF_CONFIG, SF_CONFIG_HC } from '../constants/PDPConstants'
import ContactUsForm from './ContactUsForm'
import { getSalesforceConfig } from '../services/PDPService'

const ContactUs = () => {
    const { richContent, contactUs, productDetails } = useContext(StateContext)
    const {
        chatPortalId,
        chatQuestionaireId,
        messageMailBinId,
        productSupportNumber,
        productSalesSupportNumber
    } = contactUs || {}
    const { supportForm } = richContent || {}
    const translations = useContext(Translations)
    const {
        messageUsHeader,
        supportTitle,
        supportDescription,
        chatLive,
        chatUnavailable
    } = translations
    const sfConfig = getSalesforceConfig(
        productDetails?.divisionCode,
        SF_CONFIG,
        SF_CONFIG_HC
    )

    // needs to be change to TMT Keys
    const formContainerRef = useRef(null)
    const [showForm, setShowForm] = useState(false)

    useEffect(() => {
        if (showForm) {
            formContainerRef?.current?.scrollIntoView()
        }
    }, [showForm])

    const phoneLink = (phone) => {
        return ReactDOMServer.renderToStaticMarkup(<a href={`tel:${phone}`}>{phone}</a>)
    }

    const phoneNumbers = `${
        productSupportNumber ? phoneLink(productSupportNumber) : ''
    } ${productSalesSupportNumber ? phoneLink(productSalesSupportNumber) : ''}`

    const supportDescriptionContent = supportDescription?.replace('{#}', phoneNumbers)

    if (
        !productSupportNumber &&
        !productSalesSupportNumber &&
        !messageMailBinId &&
        !supportForm
    ) {
        return null
    }
    return (
        <div className="mds-margin_xLarge--bottom">
            <ContentCTA.Container grayBack={true}>
                <ContentCTA.Header
                    title={supportTitle}
                    content={supportDescriptionContent}
                ></ContentCTA.Header>
                <ContentCTA.Footer>
                    {(messageMailBinId || supportForm) && (
                        <Button
                            buttonType="secondary"
                            className="mds-button--withIcon"
                            onClick={() => setShowForm((prevState) => !prevState)}
                        >
                            <EmailUsIcon />
                            {messageUsHeader}
                        </Button>
                    )}
                    {chatPortalId && chatQuestionaireId && (
                        <div>
                            <div
                                className="sps2-pdp_support--chat_button--online sps2-pdp_support--chat_button"
                                style={{ display: 'none' }}
                            >
                                <a
                                    className="mds-button mds-button_secondary mds-button_secondary--large mds-button--withIcon"
                                    href="###ChatButtonSnaps###"
                                >
                                    <ChatLiveIcon />
                                    {chatLive}
                                </a>
                            </div>
                            <div
                                className="sps2-pdp_support--chat_button--offline sps2-pdp_support--chat_button"
                                style={{ display: 'none' }}
                            >
                                <a className="mds-button mds-button_secondary mds-button_secondary--large mds-button--withIcon">
                                    <ChatLiveIcon />
                                    {chatUnavailable}
                                </a>
                            </div>
                            <script
                                type="text/javascript"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                            var mmmChatButtonSettings = {};//should be defined in mmmSettings.js as placeholder variable

                                            mmmChatButtonSettings.snaps = {
                                                orgID: '${sfConfig.ORG_ID}',
                                                chatButtonID: '${chatPortalId}',
                                                deploymentID: '${chatQuestionaireId}',
                                                liveAgentJS: '${sfConfig.LIVE_AGENT_JS}',
                                                onlineSelector: '.sps2-pdp_support--chat_button--online',
                                                offlineSelector: '.sps2-pdp_support--chat_button--offline',
                                                linkSelector: '[href=###ChatButtonSnaps###]'
                                            }
                                            ${sfConfig.DEPLOYMENT_JS_URL ? `mmmChatButtonSettings.snaps.deploymentJSURL = '${sfConfig.DEPLOYMENT_JS_URL}'` : ''}
                                            loadPageModule('kungfu/chatButtons/initChat');
                                        `
                                }}
                            ></script>
                        </div>
                    )}
                </ContentCTA.Footer>
            </ContentCTA.Container>
            {(messageMailBinId || supportForm) && (
                <ContactUsForm
                    formContainerRef={formContainerRef}
                    setShowForm={setShowForm}
                    showForm={showForm}
                />
            )}
        </div>
    )
}

export default ContactUs
