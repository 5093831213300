import React, { useContext } from 'react'
import { Translations } from '../../services/TranslationService'
import { decodeHTMLEntities, toSentenceCase } from '../../services/UtilityService'
import { StateContext } from '../../services/StateService'
import Accordion from '@3mcom/mds-library/dist/node/Accordion'
import '../../css/FrequentlyAskedQuestions.scss'

const FrequentlyAskedQuestions = () => {
    const translations = useContext(Translations)

    const {
        highlightRichContent,
        richContent: { faqs }
    } = useContext(StateContext)

    return (
        <div className="sps2-pdp_FAQs" data-oid={faqs?.oid || ''}>
            {highlightRichContent && (
                <h1 className="sps2-richContent_highlight--oid">{faqs?.oid}</h1>
            )}
            <h4 className="mds-font_header--4" tabIndex="-1">
                {toSentenceCase(translations.faq)}
            </h4>
            {faqs.html.map((faq, index) => (
                <Accordion.Accordion show={false} key={index}>
                    <Accordion.Heading title={decodeHTMLEntities(faq.question)} />
                    <Accordion.Content tag="div">
                        <div
                            className="mds-accordion_content--text"
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                    </Accordion.Content>
                </Accordion.Accordion>
            ))}
        </div>
    )
}
export default FrequentlyAskedQuestions
