export const primaryButtonStyle = {
    backgroundColor: '#ff0000',
    color: '#ffffff',
    border: 'none'
}

export const primaryButtonStyleHover = {
    backgroundColor: '#c80000',
    color: '#ffffff',
    border: 'none'
}

export const secondaryButtonStyle = {
    backgroundColor: '#4b4b4b',
    color: '#ffffff',
    border: 'none'
}

export const secondaryButtonStyleHover = {
    backgroundColor: '#000000',
    color: '#ffffff',
    border: 'none'
}

export const tertiaryButtonStyle = {
    backgroundColor: '#ffffff',
    color: '#4b4b4b',
    border: '1px solid #333333'
}

export const tertiaryButtonStyleHover = {
    backgroundColor: '#f2f2f2',
    color: '#4b4b4b',
    border: '1px solid #333333'
}
