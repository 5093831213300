import React, { useContext } from 'react'
import { StateContext } from '../../services/StateService'
import RichContent from '../../components/RichContent'

/* istanbul ignore next */
const PostPurchaseZone = () => {
    const { postPurchaseZone, richContent } = useContext(StateContext)
    const { postPurchaseFormLabel, postPruchaseFormTitle, postPruchaseFormDescription } =
        postPurchaseZone
    const formTitle = postPruchaseFormTitle || postPurchaseFormLabel
    return (
        <div
            id="sps2_post--purchase_zone"
            className="sps2-pdp_richContent--form sps2-pdp_wrap--800"
        >
            {formTitle && (
                <h3
                    className="mds-font_header--3 sps2-pdp_richContent--form-title"
                    tabIndex="-1"
                >
                    {formTitle}
                </h3>
            )}
            {postPruchaseFormDescription && (
                <h3
                    className="mds-font_paragraph sps2-pdp_richContent--form-description"
                    tabIndex="-1"
                >
                    {postPruchaseFormDescription}
                </h3>
            )}
            {richContent?.postPurchaseForm && (
                <RichContent richContentObj={richContent.postPurchaseForm} />
            )}
        </div>
    )
}

export default PostPurchaseZone
