import React, { useContext, Fragment } from 'react'
import BrightcoveVideo from '../../components/BrightcoveVideo'
import { VIDEO_PLAYER_ID } from '../../constants/PDPConstants'
import { StateContext } from '../../services/StateService'

const AlternateVideo = () => {
    const { detailsZone } = useContext(StateContext)

    return (
        <div className="sps2-pdp_details--alternateVideo">
            <Fragment>
                <BrightcoveVideo
                    videoPlayerId={VIDEO_PLAYER_ID}
                    videoPlayerListId={detailsZone?.alternateVideo}
                    videoPlayerType={detailsZone?.alternateVideoPlayerType}
                    modePlaylist={detailsZone?.alternateVideoPlayerType === 'Playlist'}
                    disablePlayButton={false}
                />
            </Fragment>
        </div>
    )
}

export default AlternateVideo
